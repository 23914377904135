import { useState } from "react";

/**
 * Hook to manage form states.
 * @param {Object} initialState Initial state of the form. Object consisting of {expected (string), actual (string), context (string), symbol (string)}
 * @returns {Object} An object containing form states and functions to manipulate it.
 */
const useForm = initialState => {
    // State for playground inputs. Object consisting of {expected (string), actual (string), context (string), symbol (string)}
    const [inputData, setInputData] = useState(initialState);

    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState(null);

    const [errorMessage, setErrorMessage] = useState("");

    const resetForm = () => {
        setInputData(initialState);
        setShowResult(false);
        setResult(null);
        setErrorMessage("");
    };

    return {
        inputData,
        setInputData,
        showResult,
        setShowResult,
        result,
        setResult,
        errorMessage,
        setErrorMessage,
        resetForm,
    };
};

export default useForm;
