export function formatDateForBackend(date) {
    if (!date) return null;

    return date.toISOString().split("T")[0];
}

export function formatDateToDDMMYYYY(date) {
    const tempDate = new Date(date);
    const day = String(tempDate.getDate()).padStart(2, "0");
    const month = String(tempDate.getMonth() + 1).padStart(2, "0");
    const year = tempDate.getFullYear();

    return `${day}.${month}.${year}`;
}

export function formatTimeStampToDDMMYYY_HHMMSS(timestamp) {
    const tempDate = new Date(timestamp);

    const date = formatDateToDDMMYYYY(tempDate);

    const hh = String(tempDate.getHours()).padStart(2, "0");
    const min = String(tempDate.getMinutes()).padStart(2, "0");
    const ss = String(tempDate.getSeconds()).padStart(2, "0");

    const time = `${hh}:${min}:${ss}`;

    return `${date} - ${time}`;
}
