import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import { ReactComponent as FilterActiveIcon } from "assets/icons/filter-active.svg";
import { ReactComponent as FilterSmallIcon } from "assets/icons/filter-small.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as CalendarActiveIcon } from "assets/icons/calendar-active.svg";
import { ReactComponent as CalendarSmallIcon } from "assets/icons/calendar-small.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import { ReactComponent as RechargePlusIcon } from "assets/icons/plus.svg";
import GraphTypeEnum from "frontend/pages/Profile/components/Overview/enums/GraphTypeEnum";
import DateEnum from "frontend/pages/Profile/components/Overview/enums/DateEnum";
import ErrorMessage from "frontend/components/ErrorMessage";
import { axiosInstance } from "frontend/context/AuthContext";
import {
    formatDateForBackend,
    formatDateToDDMMYYYY,
    formatTimeStampToDDMMYYY_HHMMSS,
} from "frontend/shared/DateFormatHelper";
import Graph from "frontend/pages/Profile/components/Overview/components/Graph";
import { formatTokens } from "frontend/shared/TokenFormatHelper";
import BillingAndUsageList from "frontend/pages/Profile/components/Overview/components/BillingAndUsageList";
import AnimateSpin from "frontend/shared/AnimateSpin";

function createEmptyGraphData(dateFrom, dateTo) {
    const graphData = [];
    let currentDate = new Date(dateFrom);

    while (currentDate <= dateTo) {
        graphData.push({
            date: formatDateToDDMMYYYY(currentDate),
            token_count: null,
        });
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return graphData;
}

function getColorByGraphType(type) {
    switch (type) {
        case GraphTypeEnum.RUNNING_BALANCE:
            return "#465A9B";
        case GraphTypeEnum.USAGE:
            return "#E63C41";
        case GraphTypeEnum.RECHARGE:
            return "#33935F";
        default:
            return "#465A9B";
    }
}

async function getData(filterState) {
    const formattedFilterState = {
        data_kind: filterState.data_kind,
        date_range: {
            date_from: formatDateForBackend(filterState.date_range.date_from),
            date_to: formatDateForBackend(filterState.date_range.date_to),
        },
    };
    const response = axiosInstance.post(`/web/users/my/overview`, formattedFilterState);

    try {
        const { data } = await response;
        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

const Overview = ({ balance, toggleRecharge }) => {
    const fromDate = new Date();
    const toDate = new Date();
    fromDate.setDate(toDate.getDate() - 30);

    const [filterState, setFilterState] = useState({
        data_kind: GraphTypeEnum.RUNNING_BALANCE,
        date_range: {
            date_from: fromDate,
            date_to: toDate,
        },
    });

    const [graphData, setGraphData] = useState([]);
    const [listData, setListData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateCheck, setDateCheck] = useState(DateEnum.LAST_30);
    const [isCustomDate, setIsCustomDate] = useState(false);

    useEffect(() => {
        if (
            filterState.date_range.date_from &&
            filterState.date_range.date_to &&
            filterState.data_kind &&
            !isCustomDate
        ) {
            setIsLoading(true);
            getData(filterState).then(result => {
                if (result !== null) {
                    const { graph_data, list_data } = result;
                    const filledGraphData = updateGraphData(
                        createEmptyGraphData(
                            filterState.date_range.date_from,
                            filterState.date_range.date_to,
                        ),
                        graph_data.data,
                        filterState.data_kind,
                    );
                    setGraphData(filledGraphData);
                    setListData(list_data.data);
                    setIsLoading(false);
                }
            });
        }
    }, [filterState, isCustomDate]);

    const updateGraphData = (emptyGraphData, graphData, dataKind) => {
        const data = [...emptyGraphData];

        graphData.forEach(backendEntry => {
            const index = data.findIndex(item => item.date === backendEntry.date);

            if (index !== -1) {
                data[index].token_count = backendEntry.token_count;
            }
        });

        if (dataKind === GraphTypeEnum.RUNNING_BALANCE) {
            let running_balance = graphData?.length > 0 ? data[0] : 0;
            data.forEach(item => {
                if (item.token_count !== null) {
                    running_balance = item.token_count;
                } else {
                    item.token_count = running_balance;
                }
            });
        }

        return data;
    };

    const handleFilterChange = (field, value) => {
        setFilterState(prevState => {
            const newState = {
                ...prevState,
                [field]: value,
            };

            return newState;
        });
    };

    const handleBasicDateFilterChange = days => {
        setFilterState(prevState => {
            const today = new Date();
            const newState = {
                ...prevState,
                date_range: {
                    date_from: new Date(new Date().setDate(today.getDate() - days + 1)),
                    date_to: today,
                },
            };

            return newState;
        });
    };

    const handleDateFilterChange = (dateField, newDate) => {
        setFilterState(prevState => {
            const newState = {
                ...prevState,
                date_range: {
                    ...prevState.date_range,
                    [dateField]: newDate,
                },
            };

            return newState;
        });
    };

    const handleFilterSubmit = e => {
        e.preventDefault();

        if (!filterState.date_range.date_from || !filterState.date_range.date_to) {
            setErrorMessage("Please choose dates.");
            return;
        }

        if (filterState.date_range.date_from > filterState.date_range.date_to) {
            setErrorMessage('The "To" date cannot be earlier than the "From" date.');
            return;
        }

        const customSelects = document.querySelectorAll(".custom-select");
        customSelects.forEach(selectBtn => {
            selectBtn.classList.remove("active");
        });

        setIsCustomDate(false);
        setErrorMessage("");
    };

    return (
        <>
            <>
                <form className="flex w-full flex-wrap justify-start">
                    <div className="max-md:sticky max-md:top-[60px] max-md:bg-white max-md:z-20 md:relative flex h-fit w-full items-center justify-start after:absolute after:rounded-full after:bottom-0 after:left-0 after:h-px after:w-full after:bg-blue-40 max-md:px-6 max-md:py-3.5 mb-2 md:pt-0 md:pb-5 lg:mb-6">
                        <div className="flex flex-wrap">
                            <div className="relative max-lg:pl-4 max-lg:ml-4 lg:pl-[30px] lg:ml-[30px] after:absolute after:rounded-full after:left-0 after:top-0 after:h-full after:w-px after:bg-blue-40 first-of-type:max-lg:pl-0 first-of-type:max-lg:ml-0 first-of-type:lg:pl-0 first-of-type:lg:ml-0 first-of-type:after:hidden transition-all duration-250 motion-reduce:transition-none">
                                <div className="text-gray-80 text-[18px]">
                                    <span className="hidden md:inline">Paid: </span>
                                    <span className="text-profinit-darkblue font-bold text-[16px] md:text-[18px] xl:text-[22px]">
                                        {formatTokens(balance?.paid_amount)}
                                    </span>
                                    <span className="text-[14px] uppercase hidden md:inline">
                                        {" "}
                                        USD
                                    </span>
                                    <span className="text-[14px] uppercase md:hidden">$</span>
                                </div>
                            </div>
                            <div className="relative px-1 md:pt-0 xl:pt-0.5 xl:text-xl text-profinit-gray">
                                /
                            </div>
                            <div className="relative first-of-type:max-lg:pl-0 first-of-type:max-lg:ml-0 first-of-type:lg:pl-0 first-of-type:lg:ml-0 first-of-type:after:hidden transition-all duration-250 motion-reduce:transition-none">
                                <div className="text-gray-80 text-[18px]">
                                    <span className="text-profinit-darkblue font-bold text-[16px] md:text-[18px] xl:text-[22px]">
                                        {formatTokens(balance?.paid_tokens)}
                                    </span>
                                    <span className="text-[14px] uppercase hidden md:inline">
                                        {" "}
                                        tokens
                                    </span>
                                    <span className="text-[14px] uppercase md:hidden">$</span>
                                </div>
                            </div>
                            <div className="relative max-lg:pl-4 max-lg:ml-4 lg:pl-[30px] lg:ml-[30px] after:absolute after:rounded-full after:left-0 after:top-0 after:h-full after:w-px after:bg-blue-40 first-of-type:max-lg:pl-0 first-of-type:max-lg:ml-0 first-of-type:lg:pl-0 first-of-type:lg:ml-0 first-of-type:after:hidden transition-all duration-250 motion-reduce:transition-none">
                                <div className="text-gray-80 text-[18px]">
                                    <span className="hidden md:inline">Used: </span>
                                    <span className="text-profinit-darkblue font-bold text-[16px] md:text-[18px] xl:text-[22px]">
                                        {formatTokens(balance?.used_tokens)}
                                    </span>
                                    <span className="text-[14px] uppercase hidden md:inline">
                                        {" "}
                                        tokens
                                    </span>
                                    <span className="text-[14px] uppercase md:hidden">$</span>
                                </div>
                            </div>
                            <div className="relative max-lg:pl-4 max-lg:ml-4 lg:pl-[30px] lg:ml-[30px] after:absolute after:rounded-full after:left-0 after:top-0 after:h-full after:w-px after:bg-blue-40 first-of-type:max-lg:pl-0 first-of-type:max-lg:ml-0 first-of-type:lg:pl-0 first-of-type:lg:ml-0 first-of-type:after:hidden transition-all duration-250 motion-reduce:transition-none">
                                <div className="text-gray-80 text-[18px]">
                                    <span className="hidden md:inline">Balance: </span>
                                    <span className="text-profinit-darkblue font-bold text-[16px] md:text-[18px] xl:text-[22px]">
                                        {formatTokens(balance?.balance_tokens)}
                                    </span>
                                    <span className="text-[14px] uppercase hidden md:inline">
                                        {" "}
                                        tokens
                                    </span>
                                    <span className="text-[14px] uppercase md:hidden">$</span>
                                </div>
                            </div>
                        </div>
                        <div className="ml-auto hidden">
                            <div className="hidden lg:block">
                                <a
                                    onClick={toggleRecharge}
                                    className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-full md:w-auto text-white bg-profinit-red hover:bg-profinit-darkred text-[16px] py-4 px-4 md:py-3 hover:cursor-pointer"
                                >
                                    Recharge
                                </a>
                            </div>
                            <a
                                onClick={toggleRecharge}
                                className="relative block lg:hidden size-[28px] cursor-pointer before:absolute before:left-1/2 before:top-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:size-[28px] before:rounded-full before:bg-darkblue-10 hover:before:bg-blue-40 before:transition-colors before:duration-250 before:motion-reduce:transition-none"
                            >
                                <svg
                                    className="block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 size-10 text-profinit-red cursor-pointer transition-colors duration-250 motion-reduce:transition-none"
                                    title="Recharge"
                                >
                                    <RechargePlusIcon />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="w-full max-md:px-6 md:pr-8 lg:w-2/5 xl:w-1/3"></div>
                    <div className="w-full">
                        <div className="flex flex-wrap w-full items-center max-md:mt-15">
                            <div className="relative flex flex-wrap w-full items-center mb-2 pb-2 max-lg:gap-x-2 max-lg:gap-y-6 max-lg:after:z-[5] max-lg:after:absolute max-lg:after:bottom-[-10px] max-lg:after:left-0 max-lg:after:h-[10px] max-lg:after:w-full max-lg:after:bg-gradient-to-b max-lg:after:from-black/10 max-lg:after:to-transparent">
                                <h2
                                    className="js-filter-heading text-[14px] sm:text-[18px] font-normal text-darkblue-100 max-md:pl-6 max-lg:max-w-[50%] max-lg:mr-auto lg:order-7 lg:w-full lg:mt-9"
                                    data-text-original="Tokens usage in time"
                                    data-text-calendar="Set time range"
                                >
                                    Tokens usage in time
                                </h2>
                                <div
                                    className="lg:order-1 icon-filter lg:hidden"
                                    data-target="js-filter-filter"
                                >
                                    <svg className="icon-filter--normal size-10 cursor-pointer transition-colors duration-250 motion-reduce:transition-none">
                                        <FilterIcon />
                                    </svg>{" "}
                                    <svg className="icon-filter--active size-10 cursor-pointer transition-colors duration-250 motion-reduce:transition-none">
                                        <FilterActiveIcon />
                                    </svg>
                                </div>
                                <div
                                    className="lg:order-2 max-md:pr-6 icon-filter lg:hidden"
                                    data-target="js-filter-calendar"
                                >
                                    <svg className="icon-filter--normal size-10 cursor-pointer transition-colors duration-250 motion-reduce:transition-none">
                                        <CalendarIcon />
                                    </svg>{" "}
                                    <svg className="icon-filter--active size-10 cursor-pointer transition-colors duration-250 motion-reduce:transition-none">
                                        <CalendarActiveIcon />
                                    </svg>
                                </div>
                                <div
                                    className="js-filter-box w-full lg:w-[186px] xl:w-[206px] text-profinit-gray lg:order-5 max-md:mx-6 max-lg:hidden flex flex-nowrap justify-between gap-x-1.5 items-center"
                                    data-box="js-filter-filter"
                                >
                                    <div className="group custom-select relative w-full">
                                        <span
                                            role="combobox"
                                            aria-label="select button"
                                            aria-haspopup="listbox"
                                            aria-expanded="false"
                                            aria-controls="select-dropdown"
                                            data-default="Usage"
                                            className="select-button cursor-pointer form-input w-full flex justify-between items-center text-[14px] py-[11px] ring-0 border-[1.5px] border-solid placeholder:text-darkblue-60 focus:ring-0 focus:border-darkblue-40 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none group-[.active]:shadow-dropdown group-[.active.custom-select--reverted]:relative group-[.active.custom-select--reverted]:z-10 px-3"
                                        >
                                            <span className="selected-value text-left flex items-center">
                                                <svg className="size-[18px] mr-3">
                                                    <FilterSmallIcon />
                                                </svg>
                                                <span className="js-selected-value mr-3 whitespace-nowrap">
                                                    {filterState.data_kind}
                                                </span>
                                            </span>
                                            <svg className="arrow size-[12px] duration-250 transition-all motion-reduce:transition-none">
                                                <ArrowIcon />
                                            </svg>
                                        </span>
                                        <div
                                            className="select-dropdown absolute z-10 w-full bg-darkblue-10 border border-solid border-darkblue-60 max-h-[200px] overflow-y-auto duration-250 transition-all motion-reduce:transition-none translate-y-0 opacity-0 invisible group-[.active]:shadow-dropdown group-[.active.custom-select--reverted]:z-0"
                                            role="listbox"
                                        >
                                            <div
                                                role="option"
                                                className="select-dropdown-item relative cursor-pointer flex items-center border-t border-t-solid border-t-blue-40 first-of-type:border-t-0"
                                            >
                                                <input
                                                    type="radio"
                                                    id="item-filter-type-option-01"
                                                    name="itemsFilterType"
                                                    className="peer absolute left-0 opacity-0"
                                                    checked={
                                                        filterState.type ===
                                                        GraphTypeEnum.RUNNING_BALANCE
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() =>
                                                        handleFilterChange(
                                                            "data_kind",
                                                            GraphTypeEnum.RUNNING_BALANCE,
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor="item-filter-type-option-01"
                                                    className="w-full px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                                >
                                                    {GraphTypeEnum.RUNNING_BALANCE}
                                                </label>
                                            </div>
                                            <div
                                                role="option"
                                                className="select-dropdown-item relative cursor-pointer flex items-center border-t border-t-solid border-t-blue-40 first-of-type:border-t-0"
                                            >
                                                <input
                                                    type="radio"
                                                    id="item-filter-type-option-02"
                                                    name="itemsFilterType"
                                                    className="peer absolute left-0 opacity-0"
                                                    checked={
                                                        filterState.type === GraphTypeEnum.USAGE
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() =>
                                                        handleFilterChange(
                                                            "data_kind",
                                                            GraphTypeEnum.USAGE,
                                                        )
                                                    }
                                                />{" "}
                                                <label
                                                    htmlFor="item-filter-type-option-02"
                                                    className="w-full px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                                >
                                                    {GraphTypeEnum.USAGE}
                                                </label>
                                            </div>
                                            <div
                                                role="option"
                                                className="select-dropdown-item relative cursor-pointer flex items-center border-t border-t-solid border-t-blue-40 first-of-type:border-t-0"
                                            >
                                                <input
                                                    type="radio"
                                                    id="item-filter-type-option-03"
                                                    name="itemsFilterType"
                                                    className="peer absolute left-0 opacity-0"
                                                    checked={
                                                        filterState.type === GraphTypeEnum.RECHARGE
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() =>
                                                        handleFilterChange(
                                                            "data_kind",
                                                            GraphTypeEnum.RECHARGE,
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor="item-filter-type-option-03"
                                                    className="w-full px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                                >
                                                    {GraphTypeEnum.RECHARGE}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="js-filter-box w-full lg:w-[206px] text-profinit-gray lg:order-6 lg:ml-5 max-lg:hidden flex flex-wrap gap-x-1.5 items-center"
                                    data-box="js-filter-calendar"
                                >
                                    <div className="group custom-select relative w-full">
                                        <span
                                            role="combobox"
                                            aria-label="select button"
                                            aria-haspopup="listbox"
                                            aria-expanded="false"
                                            aria-controls="select-dropdown"
                                            data-default="Last 30 days"
                                            className="select-button max-lg:hidden cursor-pointer form-input w-full flex justify-between items-center bg-darkblue-10 text-[14px] py-[11px] ring-0 border-[1.5px] border-solid placeholder:text-darkblue-60 focus:ring-0 focus:border-darkblue-40 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none group-[.active]:shadow-dropdown px-3"
                                        >
                                            <span className="selected-value text-left flex items-center">
                                                <svg className="size-[22px] mr-3">
                                                    <CalendarSmallIcon />
                                                </svg>
                                                <span className="js-selected-value mr-3 whitespace-nowrap">
                                                    {dateCheck}
                                                </span>
                                            </span>
                                            <svg className="arrow size-[12px] duration-250 transition-all motion-reduce:transition-none">
                                                <ArrowIcon />
                                            </svg>
                                        </span>
                                        <div
                                            className="select-dropdown lg:absolute lg:z-10 w-full bg-white max-md:px-6 pb-2 lg:p-[14px] lg:border lg:border-solid lg:border-darkblue-60 duration-250 transition-all motion-reduce:transition-none translate-y-0 lg:opacity-0 lg:invisible group-[.active]:shadow-dropdown"
                                            role="listbox"
                                        >
                                            <div
                                                role="option"
                                                className="select-dropdown-item flex flex-wrap relative cursor-pointer items-center mt-5 first-of-type:mt-0"
                                            >
                                                <input
                                                    type="radio"
                                                    id="item-filter-calendar-option-01"
                                                    name="itemsFilterCalendar"
                                                    className="peer absolute left-0 opacity-0"
                                                    checked={
                                                        dateCheck === DateEnum.LAST_1
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() => {
                                                        setDateCheck(DateEnum.LAST_1);
                                                        handleBasicDateFilterChange(1);
                                                        setIsCustomDate(false);
                                                        setErrorMessage("");
                                                    }}
                                                />{" "}
                                                <label
                                                    htmlFor="item-filter-calendar-option-01"
                                                    className="block relative w-full pl-[30px] cursor-pointer text-gray-80 text-[14px] transition-colors duration-250 motion-reduce:transition-none hover:text-profinit-gray before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:size-4 before:ring-1 before:ring-blue-60 before:rounded-full before:transition-all before:duration-250 before:motion-reduce:transition-none after:absolute after:left-[5px] after:top-1/2 after:-translate-y-1/2 after:size-1.5 after:bg-blue-60 after:rounded-full after:transition-all after:duration-250 after:motion-reduce:transition-none hover:before:ring-darkblue-100 hover:after:translate-x-[-1px] hover:after:size-2 hover:after:bg-darkblue-100 peer-checked:text-profinit-gray peer-focus:text-profinit-gray peer-checked:before:ring-darkblue-100 peer-focus:before:ring-darkblue-100 peer-checked:after:translate-x-[-1px] peer-checked:after:size-2 peer-checked:after:bg-darkblue-100 peer-focus:after:translate-x-[-1px] peer-focus:after:size-2 peer-focus:after:bg-darkblue-100"
                                                >
                                                    {DateEnum.LAST_1}
                                                </label>
                                            </div>
                                            <div
                                                role="option"
                                                className="select-dropdown-item flex flex-wrap relative cursor-pointer items-center mt-5 first-of-type:mt-0"
                                            >
                                                <input
                                                    type="radio"
                                                    id="item-filter-calendar-option-02"
                                                    name="itemsFilterCalendar"
                                                    className="peer absolute left-0 opacity-0"
                                                    checked={
                                                        dateCheck === DateEnum.LAST_7
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() => {
                                                        setDateCheck(DateEnum.LAST_7);
                                                        handleBasicDateFilterChange(7);
                                                        setIsCustomDate(false);
                                                        setErrorMessage("");
                                                    }}
                                                />{" "}
                                                <label
                                                    htmlFor="item-filter-calendar-option-02"
                                                    className="block relative w-full pl-[30px] cursor-pointer text-gray-80 text-[14px] transition-colors duration-250 motion-reduce:transition-none hover:text-profinit-gray before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:size-4 before:ring-1 before:ring-blue-60 before:rounded-full before:transition-all before:duration-250 before:motion-reduce:transition-none after:absolute after:left-[5px] after:top-1/2 after:-translate-y-1/2 after:size-1.5 after:bg-blue-60 after:rounded-full after:transition-all after:duration-250 after:motion-reduce:transition-none hover:before:ring-darkblue-100 hover:after:translate-x-[-1px] hover:after:size-2 hover:after:bg-darkblue-100 peer-checked:text-profinit-gray peer-focus:text-profinit-gray peer-checked:before:ring-darkblue-100 peer-focus:before:ring-darkblue-100 peer-checked:after:translate-x-[-1px] peer-checked:after:size-2 peer-checked:after:bg-darkblue-100 peer-focus:after:translate-x-[-1px] peer-focus:after:size-2 peer-focus:after:bg-darkblue-100"
                                                >
                                                    {DateEnum.LAST_7}
                                                </label>
                                            </div>
                                            <div
                                                role="option"
                                                className="select-dropdown-item flex flex-wrap relative cursor-pointer items-center mt-5 first-of-type:mt-0"
                                            >
                                                <input
                                                    type="radio"
                                                    id="item-filter-calendar-option-03"
                                                    name="itemsFilterCalendar"
                                                    className="peer absolute left-0 opacity-0"
                                                    checked={
                                                        dateCheck === DateEnum.LAST_30
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() => {
                                                        setDateCheck(DateEnum.LAST_30);
                                                        handleBasicDateFilterChange(30);
                                                        setIsCustomDate(false);
                                                        setErrorMessage("");
                                                    }}
                                                />{" "}
                                                <label
                                                    htmlFor="item-filter-calendar-option-03"
                                                    className="block relative w-full pl-[30px] cursor-pointer text-gray-80 text-[14px] transition-colors duration-250 motion-reduce:transition-none hover:text-profinit-gray before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:size-4 before:ring-1 before:ring-blue-60 before:rounded-full before:transition-all before:duration-250 before:motion-reduce:transition-none after:absolute after:left-[5px] after:top-1/2 after:-translate-y-1/2 after:size-1.5 after:bg-blue-60 after:rounded-full after:transition-all after:duration-250 after:motion-reduce:transition-none hover:before:ring-darkblue-100 hover:after:translate-x-[-1px] hover:after:size-2 hover:after:bg-darkblue-100 peer-checked:text-profinit-gray peer-focus:text-profinit-gray peer-checked:before:ring-darkblue-100 peer-focus:before:ring-darkblue-100 peer-checked:after:translate-x-[-1px] peer-checked:after:size-2 peer-checked:after:bg-darkblue-100 peer-focus:after:translate-x-[-1px] peer-focus:after:size-2 peer-focus:after:bg-darkblue-100"
                                                >
                                                    {DateEnum.LAST_30}
                                                </label>
                                            </div>
                                            <div
                                                role="option"
                                                className="select-dropdown-item flex flex-wrap relative cursor-pointer items-center mt-5 first-of-type:mt-0 js-custom"
                                            >
                                                <input
                                                    type="radio"
                                                    id="item-filter-calendar-option-04"
                                                    name="itemsFilterCalendar"
                                                    className="peer absolute left-0 opacity-0"
                                                    checked={
                                                        dateCheck === DateEnum.CUSTOM
                                                            ? "checked"
                                                            : ""
                                                    }
                                                    onChange={() => {
                                                        setDateCheck(DateEnum.CUSTOM);
                                                        setFilterState(prevState => {
                                                            const newState = {
                                                                ...prevState,
                                                                date_range: {
                                                                    date_from: null,
                                                                    date_to: null,
                                                                },
                                                            };
                                                            return newState;
                                                        });
                                                        setIsCustomDate(true);
                                                    }}
                                                />{" "}
                                                <label
                                                    htmlFor="item-filter-calendar-option-04"
                                                    className="block relative w-full pl-[30px] cursor-pointer text-gray-80 text-[14px] transition-colors duration-250 motion-reduce:transition-none hover:text-profinit-gray before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:size-4 before:ring-1 before:ring-blue-60 before:rounded-full before:transition-all before:duration-250 before:motion-reduce:transition-none after:absolute after:left-[5px] after:top-1/2 after:-translate-y-1/2 after:size-1.5 after:bg-blue-60 after:rounded-full after:transition-all after:duration-250 after:motion-reduce:transition-none hover:before:ring-darkblue-100 hover:after:translate-x-[-1px] hover:after:size-2 hover:after:bg-darkblue-100 peer-checked:text-profinit-gray peer-focus:text-profinit-gray peer-checked:before:ring-darkblue-100 peer-focus:before:ring-darkblue-100 peer-checked:after:translate-x-[-1px] peer-checked:after:size-2 peer-checked:after:bg-darkblue-100 peer-focus:after:translate-x-[-1px] peer-focus:after:size-2 peer-focus:after:bg-darkblue-100"
                                                >
                                                    Custom date range
                                                </label>
                                                <div className="select-dropdown-item-custom-list w-full">
                                                    <div className="gap-y-2 mt-6 flex flex-wrap justify-between">
                                                        <div className="max-lg:w-[calc((100%-24px)/2)] max-lg:max-w-[50%] lg:w-full max-lg:even:ml-6">
                                                            From
                                                            <input
                                                                type="date"
                                                                className="form-input w-full rounded-md px-[14px] py-[11px] ring-0 border-[1.5px] border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none bg-white"
                                                                value={
                                                                    formatDateForBackend(
                                                                        filterState.date_range
                                                                            .date_from,
                                                                    ) ?? ""
                                                                }
                                                                onChange={e => {
                                                                    handleDateFilterChange(
                                                                        "date_from",
                                                                        new Date(e.target.value),
                                                                    );
                                                                    setIsCustomDate(true);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="max-lg:w-[calc((100%-24px)/2)] max-lg:max-w-[50%] lg:w-full max-lg:even:ml-6">
                                                            To
                                                            <input
                                                                type="date"
                                                                className="form-input w-full rounded-md px-[14px] py-[11px] ring-0 border-[1.5px] border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none bg-white"
                                                                value={
                                                                    formatDateForBackend(
                                                                        filterState.date_range
                                                                            .date_to,
                                                                    ) ?? ""
                                                                }
                                                                onChange={e => {
                                                                    handleDateFilterChange(
                                                                        "date_to",
                                                                        new Date(e.target.value),
                                                                    );
                                                                    setIsCustomDate(true);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorMessage message={errorMessage} />
                                            <div className="select-dropdown-item-custom-button w-full mt-6">
                                                <button
                                                    className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-full text-white bg-profinit-red hover:bg-profinit-darkred text-[16px] py-4 px-4 md:py-3"
                                                    onClick={handleFilterSubmit}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                            <div
                                                className={`${isCustomDate ? "visible" : "hidden"} w-full lg:hidden mt-6`}
                                            >
                                                <button
                                                    className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-full text-white bg-profinit-red hover:bg-profinit-darkred text-[16px] py-4 px-4 md:py-3"
                                                    onClick={handleFilterSubmit}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex justify-center pb-5">
                                {isLoading && <AnimateSpin />}
                            </div>
                            {graphData && (
                                <Graph
                                    graphData={graphData}
                                    color={[getColorByGraphType(filterState.data_kind)]}
                                />
                            )}
                        </div>
                        {listData && (
                            <BillingAndUsageList
                                listData={listData}
                                dateRange={filterState.date_range}
                                isCustomDate={isCustomDate}
                            />
                        )}
                    </div>
                </form>
            </>
        </>
    );
};

export default Overview;
