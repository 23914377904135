import React, { useState } from "react";

import { axiosInstance, AuthConsumer } from "frontend/context/AuthContext";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "frontend/components/ErrorMessage";

import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import { ReactComponent as PasswordConfirmIcon } from "assets/icons/password-confirm.svg";
import AnimateSpin from "frontend/shared/AnimateSpin";

/**
 * Attempts to register a user using a specified email and password.
 * @param {string} email Email of the new user
 * @param {string} password Password of the new user
 * @returns True, if succeeded. False otherwise.
 */
async function tryRegister(fullName, email, password) {
    const response = axiosInstance.post(
        `/web/users/create`,
        {
            full_name: fullName,
            email: email,
            password: password,
        },
        {
            headers: { "Content-Type": "application/json" },
        },
    );

    try {
        await response;
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

/**
 * Page for registration of new users.
 * @returns Register page.
 */
const Register = () => {
    const [registerState, setRegisterState] = useState({
        fullName: "",
        email: "",
        password: "",
        confirmationPassword: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { login } = AuthConsumer();
    const navigate = useNavigate();

    const handleInputChange = e => {
        const { name, value } = e.target;
        setRegisterState({
            ...registerState,
            [name]: value,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const { fullName, email, password, confirmationPassword } = registerState;

        if (password !== confirmationPassword) {
            setErrorMessage("The passwords do not match.");
            return;
        }

        if (password.length < 6 || confirmationPassword.length < 6) {
            setErrorMessage("The password is too short.");
            return;
        }

        if (password.length > 255 || confirmationPassword.length > 255) {
            setErrorMessage("The password is too long.");
            return;
        }

        setIsLoading(true);
        const didRegister = await tryRegister(fullName, email, password);
        if (!didRegister) {
            setIsLoading(false);
            setErrorMessage("Registration was unsuccessful.");
            return;
        }

        const didLogin = await login(email, password);
        if (!didLogin) {
            setIsLoading(false);
            setErrorMessage("Login was not successful. This is an error on the server side.");
            return;
        }

        setIsLoading(false);
        navigate("/profile/overview");
    };

    return (
        <div id="tab-item-sign-up-form" className="js-tab-item tab-item flex flex-wrap active">
            <form className="flex w-full flex-wrap items-center" onSubmit={handleSubmit}>
                <div className="w-full">
                    <h3 className="text-center text-xl font-bold text-darkblue-100">
                        Welcome to EvalMy.AI
                    </h3>
                    <p className="relative mb-10 mt-2 text-center text-profinit-gray md:col-span-6">
                        Create your account
                    </p>
                    <div className="relative text-lg mt-4 first-of-type:mt-0">
                        <svg className="absolute left-[12px] top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                            <UserIcon />
                        </svg>{" "}
                        <input
                            id="signup-name"
                            name="fullName"
                            placeholder="Full name"
                            className="w-full rounded-lg bg-white pr-4 py-3 ring-0 ring-blue-40 hover:ring-1 hover:ring-white focus:ring-1 focus:ring-white placeholder:text-darkblue-60 pl-[44px]"
                            maxLength="255"
                            autoComplete="name"
                            required=""
                            value={registerState.fullName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="relative text-lg mt-4 first-of-type:mt-0">
                        <svg className="absolute left-[12px] top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                            <EmailIcon />
                        </svg>{" "}
                        <input
                            id="signup-email"
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="w-full rounded-lg bg-white pr-4 py-3 ring-0 ring-blue-40 hover:ring-1 hover:ring-white focus:ring-1 focus:ring-white placeholder:text-darkblue-60 pl-[44px]"
                            maxLength="255"
                            autoComplete="email"
                            required=""
                            value={registerState.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="relative text-lg mt-4 first-of-type:mt-0">
                        <svg className="absolute left-[12px] top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                            <PasswordIcon />
                        </svg>{" "}
                        <input
                            type="password"
                            id="signup-password"
                            name="password"
                            placeholder="Password"
                            className="w-full rounded-lg bg-white pr-4 py-3 ring-0 ring-blue-40 hover:ring-1 hover:ring-white focus:ring-1 focus:ring-white placeholder:text-darkblue-60 pl-[44px]"
                            maxLength="255"
                            minLength="6"
                            autoComplete="new-password"
                            required=""
                            value={registerState.password}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="relative text-lg mt-4 first-of-type:mt-0">
                        <svg className="absolute left-[12px] top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                            <PasswordConfirmIcon />
                        </svg>{" "}
                        <input
                            type="password"
                            id="signup-confirm-password"
                            name="confirmationPassword"
                            placeholder="Confirm password"
                            className="w-full rounded-lg bg-white pr-4 py-3 ring-0 ring-blue-40 hover:ring-1 hover:ring-white focus:ring-1 focus:ring-white placeholder:text-darkblue-60 pl-[44px]"
                            maxLength="255"
                            minLength="6"
                            autoComplete="new-password"
                            required=""
                            value={registerState.confirmationPassword}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mt-6 w-full">
                        <button
                            type="submit"
                            className="text-center w-full leading-none rounded-md font-bold inline-block text-white bg-profinit-red hover:bg-profinit-darkred text-lg py-4 px-5"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <div className="w-full flex justify-center">
                                    <AnimateSpin />
                                </div>
                            ) : (
                                <div className="py-[1px]">Register</div>
                            )}
                        </button>
                        <ErrorMessage message={errorMessage} />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Register;
