import Description from "frontend/pages/Home/components/Description";
import Evaluation from "frontend/pages/Home/components/Evaluation";
import HowItWorks from "frontend/pages/Home/components/HowItWorks";
import Pricing from "frontend/pages/Home/components/Pricing";
import Tutorial from "frontend/pages/Home/components/Tutorial";
import Playground from "frontend/pages/Playground/Index";
import { useEffect } from "react";
import ContactForm from "frontend/pages/Home/components/ContactForm";

const SplitLine = () => {
    return (
        <>
            <hr className="my-14 border-blue-40 md:my-[4.5rem] lg:my-[5.5rem]" />
        </>
    );
};

const Home = () => {
    return (
        <>
            <Evaluation />
            <div className="container text-xl font-proxima">
                <Description />
                <SplitLine />
                <HowItWorks />
                <SplitLine />
                <Tutorial />
                <SplitLine />
                <Pricing />
            </div>
            <ContactForm />
        </>
    );
};

export default Home;
