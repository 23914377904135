export const formatTokens = num => {
    if (num !== null && num !== undefined) {
        if (num >= 1_000_000) {
            return `${(num / 1_000_000).toFixed(2)}M`;
        } else if (num >= 1_000) {
            return `${Math.floor(num / 1_000)}k`;
        } else {
            return num.toString();
        }
    }
};
