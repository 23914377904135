import { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import { axiosInstance } from "frontend/context/AuthContext";
import ErrorMessage from "frontend/components/ErrorMessage";
import { isAxiosError } from "axios";
import { formatTimeStampToDDMMYYY_HHMMSS } from "frontend/shared/DateFormatHelper";
import AnimateSpin from "frontend/shared/AnimateSpin";

async function getLastPasswordChange() {
    const response = axiosInstance.get(`/web/users/my/password`);

    try {
        const { data } = await response;
        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

const AccountSettings = ({ curr_api_token, isEditMode, getUserInfo }) => {
    const [isEdit, setIsEdit] = useState(isEditMode);
    const [isPasswordEdit, setIsPasswordEdit] = useState(false);
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        lowBalance: false, // TODO CURR_USER_PREFERENCES FROM DB
        futureUpdates: false,
    });
    const [passwordsFormData, setPasswordsFormData] = useState({
        old_password: "",
        new_password: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [passwordLastChange, setPasswordLastChange] = useState("");
    const [passwordChangeError, setPasswordChangeError] = useState("");

    useEffect(() => {
        getUserInfo().then(result => {
            if (result !== null) {
                const { full_name, email } = result;
                setFormData(prevState => ({
                    ...prevState,
                    full_name: full_name,
                    email: email,
                }));
            }
        });

        getLastPasswordChange().then(result => {
            if (result === null) {
                return;
            }

            setPasswordLastChange(result);
        });
    }, [isEdit]);

    const toggleEdit = () => {
        setIsEdit(!isEdit);
    };

    const togglePasswordEdit = () => {
        setIsPasswordEdit(!isPasswordEdit);
        setPasswordChangeError("");
    };

    const handleInputChange = e => {
        if (isEdit) {
            const { name, value } = e.target;
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handlePasswordInputChange = e => {
        if (isPasswordEdit) {
            const { name, value } = e.target;
            setPasswordsFormData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSaveChanges = async e => {
        e.preventDefault();

        const newBasicInfo = {
            full_name: formData.full_name,
            email: formData.email,
        };

        setIsLoading(true);

        await axiosInstance
            .post(`/web/users/my/basic_info/set`, newBasicInfo, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(error => {
                console.error(error);
            });

        setIsEdit(false);
        setIsLoading(false);
    };

    const handleSavePasswordChanges = async e => {
        e.preventDefault();

        if (passwordsFormData.new_password.length < 6) {
            setPasswordChangeError("The new password is too short.");
            return;
        }

        if (passwordsFormData.new_password.length > 255) {
            setPasswordChangeError("The new password is too long.");
            return;
        }

        setIsLoading(true);
        const response = await axiosInstance
            .post(`/web/users/my/password/set`, passwordsFormData, {
                headers: { "Content-Type": "application/json" },
            })
            .then(result => {
                setPasswordLastChange(result.data);
            })
            .catch(error => {
                if (isAxiosError(error)) {
                    console.log(error.response.data.detail);
                    setPasswordChangeError(error.response.data.detail);
                    return;
                }
            });

        setIsPasswordEdit(false);
        setIsLoading(false);
        setPasswordsFormData({
            old_password: "",
            new_password: "",
        });
        setPasswordChangeError(null);
    };

    return (
        <>
            <form
                className={`flex w-full flex-wrap justify-start ${isEdit || isPasswordEdit ? "mb-[100px]" : ""}`}
                onSubmit={handleSaveChanges}
            >
                <div className="max-md:sticky max-md:top-[60px] max-md:bg-white max-md:z-20 md:relative flex h-fit w-full items-center justify-start after:absolute after:rounded-full after:bottom-0 after:left-0 after:h-px after:w-full after:bg-blue-40 max-md:px-6 max-md:py-3 mb-5 pb-5 md:mb-8 md:min-h-[60px] md:pb-[28px]">
                    <h1 className="text-[20px] font-bold text-darkblue-100 md:text-[22px]">
                        Account settings
                    </h1>
                    {isEdit && (
                        <>
                            <div className="max-md:fixed max-md:bottom-0 max-md:left-0 max-md:z-10 max-md:w-full max-md:bg-gradient-to-r max-md:from-profinit-blue max-md:to-darkblue-100 max-md:p-6 md:ml-auto">
                                <div className="flex flex-wrap max-md:w-full max-md:justify-between gap-x-4 max-md:gap-y-6">
                                    <button
                                        type="submit"
                                        className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-[135px] max-md:w-[calc(100%/3*2-16px)] text-white bg-profinit-red hover:bg-profinit-darkred text-[16px] py-4 px-4 md:py-3"
                                    >
                                        {isLoading ? (
                                            <div className="w-full flex justify-center">
                                                <AnimateSpin />
                                            </div>
                                        ) : (
                                            <div className="py-[2px]">Save changes</div>
                                        )}
                                    </button>
                                    <button
                                        onClick={toggleEdit}
                                        className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-auto max-md:w-1/3 max-md:min-w-fit text-darkblue-100 bg-darkblue-10 hover:bg-darkblue-20 text-[16px] py-4 px-4 md:py-3"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="w-full max-md:px-6 md:pr-8 lg:w-2/5 xl:w-1/3">
                    <div className="flex items-center">
                        <h2 className="text-[18px] font-normal text-darkblue-100">Personal info</h2>
                        {!isEdit && (
                            <a
                                className="relative ml-auto block cursor-pointer pr-6 text-[14px] text-gray-80 hover:underline"
                                onClick={toggleEdit}
                            >
                                Edit
                                <svg className="absolute right-0 top-1/2 size-[14px] -translate-y-1/2 text-gray-80">
                                    <EditIcon />
                                </svg>
                            </a>
                        )}
                    </div>
                    {!isEdit && (
                        <>
                            <div className="mt-6 md:mt-5">
                                <h3 className="mb-1.5 text-[16px] font-normal text-gray-80">
                                    Full name
                                </h3>
                                <p className="mb-1.5 text-[16px] font-normal text-darkblue-100">
                                    {formData.full_name}
                                </p>
                            </div>
                            <div className="mt-6 md:mt-5">
                                <h3 className="mb-1.5 text-[16px] font-normal text-gray-80">
                                    Account email
                                </h3>
                                <p className="mb-1.5 text-[16px] font-normal text-darkblue-100">
                                    {formData.email}
                                </p>
                            </div>
                        </>
                    )}
                    {isEdit && (
                        <>
                            <div className="flex flex-wrap justify-between">
                                <div className="w-full mt-5">
                                    <label
                                        htmlFor="Full name"
                                        className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                    >
                                        Full name
                                    </label>
                                    <input
                                        id="full_name"
                                        name="full_name"
                                        placeholder="Full name"
                                        className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                        required
                                        value={formData.full_name}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between">
                                <div className="w-full mt-5">
                                    <label
                                        htmlFor="Account email"
                                        className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                    >
                                        Account email
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        placeholder="Account email"
                                        className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                        required
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {!isPasswordEdit && (
                        <>
                            <div className="relative mt-7 pt-7 after:absolute after:rounded-full after:left-0 after:top-0 after:h-px after:w-full after:bg-blue-20 md:mt-8 md:pt-8">
                                <a
                                    className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-auto text-white bg-darkblue-100 hover:bg-profinit-darkblue text-[16px] py-4 px-4 md:py-3 hover:cursor-pointer"
                                    onClick={togglePasswordEdit}
                                >
                                    Change password
                                </a>
                            </div>
                            <ErrorMessage message={passwordChangeError} />
                            <div className="mt-6 md:mt-5">
                                <p className="mb-1.5 text-[16px] font-normal text-gray-80">
                                    Last changed:{" "}
                                    {formatTimeStampToDDMMYYY_HHMMSS(passwordLastChange)}
                                </p>
                            </div>
                        </>
                    )}
                    {isPasswordEdit && (
                        <>
                            <div className="relative mt-7 pt-7 after:absolute after:rounded-full after:left-0 after:top-0 after:h-px after:w-full after:bg-blue-20 md:mt-8 md:pt-8">
                                <div className="flex flex-wrap justify-between">
                                    <div className="w-full mt-5">
                                        <input
                                            type="password"
                                            id="old_password"
                                            name="old_password"
                                            placeholder="Old password"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            required
                                            onChange={handlePasswordInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-between">
                                    <div className="w-full mt-5">
                                        <input
                                            type="password"
                                            id="new_password"
                                            name="new_password"
                                            placeholder="New password"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            required
                                            onChange={handlePasswordInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="pt-7 max-md:fixed max-md:bottom-0 max-md:left-0 max-md:z-10 max-md:w-full max-md:bg-gradient-to-r max-md:from-profinit-blue max-md:to-darkblue-100 max-md:p-6 md:ml-auto">
                                    <div className="flex max-md:w-full max-md:justify-between gap-x-4 max-md:gap-y-6">
                                        <button
                                            type="submit"
                                            className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-full max-md:w-[calc(100%/3*2-16px)] text-white bg-profinit-red hover:bg-profinit-darkred text-[16px] py-4 px-4 md:py-3"
                                            onClick={handleSavePasswordChanges}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? (
                                                <div className="w-full flex justify-center">
                                                    <AnimateSpin />
                                                </div>
                                            ) : (
                                                <div className="py-[2px]">Save new password</div>
                                            )}
                                        </button>
                                        <button
                                            onClick={togglePasswordEdit}
                                            className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-auto max-md:w-1/3 max-md:min-w-fit text-darkblue-100 bg-darkblue-10 hover:bg-darkblue-20 text-[16px] py-4 px-4 md:py-3"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                <ErrorMessage message={passwordChangeError} />
                            </div>
                        </>
                    )}
                </div>
                <div className="relative w-full after:absolute after:rounded-full after:left-0 after:top-2 after:h-px after:w-full after:bg-blue-20 max-md:px-6 lg:w-3/5 lg:pl-8 lg:after:absolute lg:after:rounded-full lg:after:left-0 lg:after:top-0 lg:after:h-full lg:after:w-px lg:after:bg-blue-20 xl:w-2/3">
                    <div className="w-full pt-4">
                        <h2 className="text-[18px] font-normal text-darkblue-100">API Token</h2>
                        <div className="pt-5 mb-1.5 text-[12px] md:text-[16px] font-normal text-gray-80 w-full">
                            {curr_api_token && <ApiToken token={curr_api_token} />}
                        </div>
                    </div>
                    <div className="hidden relative mt-7 pt-7 after:absolute after:rounded-full after:left-0 after:top-0 after:h-px after:w-full after:bg-blue-20 md:mt-8 md:pt-8 max-lg:mt-5 max-lg:pt-6 max-lg:after:absolute max-lg:after:rounded-full max-lg:after:left-0 max-lg:after:top-0 max-lg:after:h-px max-lg:after:w-full max-lg:after:bg-blue-20 max-md:mb-[122px]">
                        <h2 className="text-[18px] font-normal text-darkblue-100">Notifications</h2>
                        <p className="mb-1.5 mt-3 text-[16px] font-normal text-gray-80">
                            We can notify you when your balance is getting low (bellow 500 000
                            tokens). If you prefer, you can turn off notifications for a silent
                            mode.
                        </p>
                        <div className="mt-7">
                            <label
                                htmlFor="toggle1"
                                className="flex cursor-pointer select-none items-center"
                            >
                                <div className="relative flex items-center">
                                    <input
                                        type="checkbox"
                                        id="lowBalance"
                                        name="lowBalance"
                                        className="peer sr-only"
                                        checked={formData.lowBalance}
                                        onChange={handleInputChange}
                                    />
                                    <div
                                        onClick={() =>
                                            handleInputChange({
                                                target: {
                                                    name: "lowBalance",
                                                    value: !formData.lowBalance,
                                                },
                                            })
                                        }
                                        className="mr-4 block h-4 w-6 rounded-full border-2 border-solid border-blue-40 bg-white peer-checked:border-0 peer-checked:bg-gradient-to-b peer-checked:from-[#41C34B] peer-checked:to-darkgreen-100"
                                    ></div>
                                    <div
                                        onClick={() =>
                                            handleInputChange({
                                                target: {
                                                    name: "lowBalance",
                                                    value: !formData.lowBalance,
                                                },
                                            })
                                        }
                                        className="absolute left-1 top-1/2 size-2 -translate-y-1/2 rounded-full bg-blue-60 transition peer-checked:translate-x-full peer-checked:bg-white"
                                    ></div>
                                    <span className="max-w-[calc(100%-40px)] text-[16px] font-normal text-gray-80 transition-colors duration-250 peer-checked:text-profinit-gray motion-reduce:transition-none">
                                        Notify me on low balance
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="mb-1 mt-3">
                            <label
                                htmlFor="toggle2"
                                className="flex cursor-pointer select-none items-center"
                            >
                                <div className="relative flex items-center">
                                    <input
                                        type="checkbox"
                                        id="futureUpdates"
                                        name="futureUpdates"
                                        className="peer sr-only"
                                        checked={formData.futureUpdates}
                                        onChange={handleInputChange}
                                    />
                                    <div
                                        onClick={() =>
                                            handleInputChange({
                                                target: {
                                                    name: "futureUpdates",
                                                    value: !formData.futureUpdates,
                                                },
                                            })
                                        }
                                        className="mr-4 block h-4 w-6 rounded-full border-2 border-solid border-blue-40 bg-white peer-checked:border-0 peer-checked:bg-gradient-to-b peer-checked:from-[#41C34B] peer-checked:to-darkgreen-100"
                                    ></div>
                                    <div
                                        onClick={() =>
                                            handleInputChange({
                                                target: {
                                                    name: "futureUpdates",
                                                    value: !formData.futureUpdates,
                                                },
                                            })
                                        }
                                        className="absolute left-1 top-1/2 size-2 -translate-y-1/2 rounded-full bg-blue-60 transition peer-checked:translate-x-full peer-checked:bg-white"
                                    ></div>
                                    <span className="max-w-[calc(100%-40px)] text-[16px] font-normal text-gray-80 transition-colors duration-250 peer-checked:text-profinit-gray motion-reduce:transition-none">
                                        Notify me on future updates
                                    </span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

const ApiToken = ({ token }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        if (!copied) {
            navigator.clipboard.writeText(token);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }
    };
    return (
        <div className="copy-input-container w-full">
            <input type="text" value={token} readOnly className="token-input" />
            <span className={`${copied ? "tick-icon" : "copy-icon"}`} onClick={copyToClipboard}>
                <abbr title={`${copied ? "Copied!" : "Copy to clipboard"}`}>
                    {copied ? <TickIcon /> : <CopyIcon />}{" "}
                </abbr>
            </span>
        </div>
    );
};

export default AccountSettings;
