import React, { createContext, useContext, useState, useEffect } from "react";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
    const [scrollId, setScrollId] = useState(null);

    useEffect(() => {
        if (scrollId) {
            const element = document.querySelector(`#${scrollId}`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
            setScrollId(null);
        }
    }, [scrollId]);

    const scrollToId = id => {
        setScrollId(id);
    };

    return <ScrollContext.Provider value={{ scrollToId }}>{children}</ScrollContext.Provider>;
};

export const useScroll = () => {
    const context = useContext(ScrollContext);
    return context;
};
