import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthConsumer } from "frontend/context/AuthContext";
import logo from "assets/images/logo.svg";
import logo_inverse from "assets/images/logo-inverse.svg";
import pattern_mobile from "assets/images/pattern-4-min-mobile.png";
import pattern from "assets/images/pattern-4-min.png";
import { useScroll } from "frontend/context/ScrollContext";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";

const NavBar = () => {
    const { authed, logout, username } = AuthConsumer();
    const navigate = useNavigate();
    const [isActiveBurger, setIsActiveBurger] = useState(false);

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    const { scrollToId } = useScroll();

    const handleNavigation = (e, id) => {
        e.preventDefault();
        if (window.location.pathname !== "/") {
            navigate("/");
        }
        scrollToId(id);
    };

    const isProfile = () => {
        return location.pathname.startsWith("/profile");
    };

    useEffect(() => {
        const defaultActiveTab = document.querySelector(".js-tab-nav-item");

        if (defaultActiveTab) {
            defaultActiveTab.click();
        }

        const navigationHamburger = document.getElementById("main-navigation-hamburger");

        if (navigationHamburger) {
            const SCROLL_THRESHOLD = 70;
            const navigationLinks = document.querySelectorAll("#navigation .js-navigation-item");
            let navigationSection = document.getElementById("navigation-section");
            let navigation = document.getElementById("navigation");
            let html = document.documentElement;

            const initAnim = document.getElementById("hamburgerInitAnimation");
            const reverseAnim = document.getElementById("hamburgerReverseAnimation");

            function toggleNavigation(event) {
                navigationSection.classList.toggle("active");
                navigation.classList.toggle("active");
                navigationHamburger.classList.toggle("active");

                if (navigationSection.classList.contains("active")) {
                    window.removeEventListener("scroll", scrollHandler);
                    html.classList.add("no-scroll");
                    if (initAnim) {
                        initAnim.beginElement();
                    }
                } else {
                    html.classList.remove("no-scroll");
                    window.addEventListener("scroll", scrollHandler, { passive: true });
                    if (reverseAnim) {
                        reverseAnim.beginElement();
                    }
                }
            }

            function scrollHandler() {
                let currentScrollPos = window.scrollY;

                if (prevScrollPos < currentScrollPos && currentScrollPos > SCROLL_THRESHOLD) {
                    navigationSection.classList.remove("navigation-section__fixed");
                } else if (
                    prevScrollPos > currentScrollPos ||
                    currentScrollPos <= SCROLL_THRESHOLD
                ) {
                    navigationSection.classList.add("navigation-section__fixed");
                }
                prevScrollPos = currentScrollPos;
            }

            let prevScrollPos = window.scrollY;

            navigationHamburger.addEventListener("click", toggleNavigation, { passive: true });
            navigationLinks.forEach(link => {
                link.addEventListener("click", toggleNavigation, { passive: true });
            });

            window.addEventListener("scroll", scrollHandler, { passive: true });

            function notificationHandler() {
                const notificationLink = document.querySelector(".js-notification-link");
                const notificationModal = document.querySelector(".notification-modal");

                if (notificationLink && notificationModal) {
                    notificationLink.addEventListener("click", function () {
                        notificationLink.classList.toggle("active");
                        notificationModal.classList.toggle("active");
                    });
                }
            }

            document.addEventListener("DOMContentLoaded", notificationHandler);

            return () => {
                navigationHamburger.removeEventListener("click", toggleNavigation);
                navigationLinks.forEach(link => {
                    link.removeEventListener("click", toggleNavigation);
                });
                window.removeEventListener("scroll", scrollHandler);
                document.removeEventListener("DOMContentLoaded", notificationHandler);
            };
        }
    }, [isActiveBurger]);

    return (
        <>
            {authed && (
                <>
                    <header
                        className="navigation-section navigation-section--secondary flex w-full flex-wrap justify-center overflow-hidden bg-gradient-to-b from-profinit-blue to-darkblue-100 after:absolute after:bottom-[-10px] after:left-0 after:h-[10px] after:w-full after:bg-gradient-to-b after:from-black/10 after:to-transparent md:relative navigation-section__fixed"
                        id="navigation-section"
                    >
                        <picture>
                            <source srcSet={pattern_mobile} media="(max-width: 390px)" />
                            <img
                                src={pattern}
                                alt=""
                                aria-hidden="true"
                                className="absolute inset-0 h-full w-full object-cover mix-blend-luminosity"
                            />
                        </picture>
                        <nav className="z-10 flex h-[60px] w-full max-w-desktop flex-wrap items-center justify-between">
                            <a href="/" className="mr-auto block min-h-[20px] min-w-[96px] pl-6">
                                <img
                                    src={logo_inverse}
                                    alt="EvalMy.AI logo"
                                    width="100%"
                                    height="100%"
                                    className="md:h-[26px]"
                                    loading="eager"
                                />
                            </a>
                            <div className="main-navigation active flex w-full font-bold max-md:flex-wrap md:w-auto md:items-center">
                                <ul className="md:gap-4 md:pr-4 flex flex-col flex-wrap items-center max-md:w-full max-md:pb-[98px] md:flex-row">
                                    <li className="max-md:w-full">
                                        <a
                                            href="https://datascience.profinitservices.cz/evalmyai/evalmyai-client/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-white md:hover:underline text-[14px] navigation group/link max-md:block max-md:w-full max-md:border-b max-md:border-solid max-md:border-blue-40 max-md:bg-white max-md:px-6 max-md:py-4 transition-colors duration-250 motion-reduce:transition-none cursor-pointer hover:max-md:bg-darkblue-10 max-md:hover:text-darkblue-100 active:max-md:bg-darkblue-10 max-md:active:text-darkblue-100"
                                        >
                                            Tutorial
                                        </a>
                                    </li>
                                    <li className="max-md:w-full">
                                        <a
                                            onClick={e => handleNavigation(e, "pricing")}
                                            className="text-white md:hover:underline text-[14px] navigation group/link max-md:block max-md:w-full max-md:border-b max-md:border-solid max-md:border-blue-40 max-md:bg-white max-md:px-6 max-md:py-4 transition-colors duration-250 motion-reduce:transition-none cursor-pointer hover:max-md:bg-darkblue-10 max-md:hover:text-darkblue-100 active:max-md:bg-darkblue-10 max-md:active:text-darkblue-100"
                                        >
                                            Pricing
                                        </a>
                                    </li>
                                    <li className="max-md:w-full">
                                        <a
                                            href="/playground"
                                            className="text-white md:hover:underline text-[14px] navigation group/link max-md:block max-md:w-full max-md:border-b max-md:border-solid max-md:border-blue-40 max-md:bg-white max-md:px-6 max-md:py-4 transition-colors duration-250 motion-reduce:transition-none cursor-pointer hover:max-md:bg-darkblue-10 max-md:hover:text-darkblue-100 active:max-md:bg-darkblue-10 max-md:active:text-darkblue-100"
                                        >
                                            Playground
                                        </a>
                                    </li>
                                    <li className="max-md:w-full">
                                        <a
                                            href="/profile/overview"
                                            className="text-white md:hover:underline text-[14px] navigation group/link max-md:block max-md:w-full max-md:border-b max-md:border-solid max-md:border-blue-40 max-md:bg-white max-md:px-6 max-md:py-4 transition-colors duration-250 motion-reduce:transition-none cursor-pointer hover:max-md:bg-darkblue-10 max-md:hover:text-darkblue-100 active:max-md:bg-darkblue-10 max-md:active:text-darkblue-100"
                                        >
                                            {username ? username : "Profile"}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="hidden">
                                <span className="js-notification-link navigation-icon relative flex size-[40px] items-center justify-center rounded-full duration-250 after:absolute after:right-2 after:top-2 after:size-1.5 after:rounded-full after:bg-profinit-red motion-reduce:transition-none">
                                    <svg className="size-[18px]">
                                        <AlertIcon />
                                    </svg>
                                </span>
                            </div>
                            <div className="mr-4 max-md:hidden">
                                <button
                                    className="navigation-icon relative flex size-[40px] items-center justify-center rounded-full duration-250 motion-reduce:transition-none"
                                    onClick={handleLogout}
                                >
                                    <svg className="size-[18px]">
                                        <LogoutIcon />
                                    </svg>
                                </button>
                            </div>
                            {isProfile() ? (
                                <div className="mr-4 md:hidden flex font-bold flex-wrap items-center">
                                    <button
                                        className="navigation-controls navigation-controls--secondary relative flex size-[40px] items-center justify-center duration-250 motion-reduce:transition-none"
                                        id="main-navigation-hamburger"
                                        data-collapse-toggle="navigation"
                                        type="button"
                                        aria-controls="navbar-default"
                                        aria-expanded="false"
                                        aria-label="Toggle main navigation"
                                        onClick={() => setIsActiveBurger(!isActiveBurger)}
                                    >
                                        <svg className="size-[18px]">
                                            <MoreIcon />
                                        </svg>
                                    </button>
                                </div>
                            ) : (
                                <div className="mr-6 md:hidden flex font-bold flex-wrap items-center">
                                    <a
                                        href="/profile/overview"
                                        className="text-white md:hover:underline text-[14px]"
                                    >
                                        Profile
                                    </a>
                                </div>
                            )}
                        </nav>
                        <div className="navbar-spacer" />
                    </header>
                </>
            )}
            {!authed && (
                <header
                    className="navigation-section navigation-section__fixed navigation-section--primary relative flex w-full flex-wrap justify-center after:absolute after:bottom-[-10px] after:left-0 after:h-[10px] after:w-full after:bg-gradient-to-b after:from-black/10 after:to-transparent"
                    id="navigation-section"
                >
                    <nav className="flex h-[70px] w-full max-w-desktop flex-wrap items-center justify-between">
                        <a href="/" className="block min-h-[20px] min-w-[96px] pl-6">
                            <img
                                src={logo}
                                alt="EvalMy.AI logo"
                                width="100%"
                                height="100%"
                                className="md:h-[32px]"
                                loading="eager"
                            />
                        </a>
                        <div className="flex items-center justify-center pr-3 md:pr-6 md:hidden">
                            <a
                                href="/register"
                                className="border-0 border-r border-r-gray-20 px-4 text-base font-bold leading-6 text-darkblue-100 hover:underline"
                            >
                                Sign Up
                            </a>
                            <a
                                href="/login"
                                className="border-0 border-r border-r-gray-20 px-4 text-base font-bold leading-6 text-darkblue-100 hover:underline"
                            >
                                Login
                            </a>

                            <button
                                id="main-navigation-hamburger"
                                data-collapse-toggle="main-navigation"
                                type="button"
                                className="ml-4 inline-flex h-[28px] w-[28px] items-center justify-center text-sm text-gray-80 transition-colors duration-300 motion-reduce:transition-none desktop-menu:hidden"
                                aria-controls="navbar-default"
                                aria-expanded="false"
                                aria-label="Toggle main navigation"
                            >
                                <svg
                                    className="h-full w-full cursor-pointer"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 10 10"
                                    stroke="currentColor"
                                    strokeWidth=".6"
                                    fill="rgba(0,0,0,0)"
                                    strokeLinecap="round"
                                >
                                    <path d="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7">
                                        <animate
                                            dur="0.2s"
                                            attributeName="d"
                                            values="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7;M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7"
                                            fill="freeze"
                                            id="hamburgerInitAnimation"
                                        />
                                        <animate
                                            dur="0.2s"
                                            attributeName="d"
                                            values="M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7;M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7"
                                            fill="freeze"
                                            id="hamburgerReverseAnimation"
                                        />
                                    </path>
                                </svg>
                            </button>
                        </div>
                        <div
                            className="main-navigation md:pr-6 active flex w-full font-bold max-md:flex-wrap md:w-auto md:items-center"
                            id="navigation"
                        >
                            <ul className="md:gap-6 md:pr-6 flex flex-col flex-wrap items-center max-md:w-full max-md:pb-[98px] md:flex-row">
                                <NavScrollLink
                                    text={"RAG assessment"}
                                    handleClick={e => handleNavigation(e, "gpt-evaluation")}
                                />
                                <NavScrollLink
                                    text={"How it works"}
                                    handleClick={e => handleNavigation(e, "rest-api")}
                                />
                                <NavScrollLink
                                    text={"Tutorial"}
                                    handleClick={e => handleNavigation(e, "tutorial")}
                                />
                                <NavScrollLink
                                    text={"Pricing"}
                                    handleClick={e => handleNavigation(e, "pricing")}
                                />
                                <li className="max-md:w-full">
                                    <a
                                        href="/playground"
                                        className="js-navigation-item relative text-gray-80 md:hover:text-profinit-gray md:hover:underline md:text-base text-[18px] navigation group/link max-md:block max-md:w-full max-md:border-b max-md:border-solid max-md:border-blue-40 max-md:bg-white max-md:px-6 max-md:py-4 transition-colors duration-250 motion-reduce:transition-none cursor-pointer hover:max-md:bg-darkblue-10 max-md:hover:text-darkblue-100 active:max-md:bg-darkblue-10 max-md:active:text-darkblue-100"
                                    >
                                        Try it for free
                                    </a>
                                </li>
                                <NavLink to="/register">
                                    <div className="hidden md:inline">
                                        <div className="text-profinit-red hover:text-profinit-darkred md:hover:underline">
                                            Sign up
                                        </div>
                                    </div>
                                </NavLink>
                            </ul>
                            <NavLink to="/login">
                                <div className="hidden md:inline">
                                    <div className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-auto text-white bg-darkblue-100 hover:bg-profinit-darkblue text-[16px] py-4 px-4 md:py-3">
                                        Login
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </nav>
                </header>
            )}
        </>
    );
};

export default NavBar;

const NavScrollLink = ({ text, handleClick }) => {
    return (
        <>
            <li className="max-md:w-full">
                <a
                    onClick={handleClick}
                    className="js-navigation-item relative text-gray-80 md:hover:text-profinit-gray md:hover:underline md:text-base text-[18px] navigation group/link max-md:block max-md:w-full max-md:border-b max-md:border-solid max-md:border-blue-40 max-md:bg-white max-md:px-6 max-md:py-4 transition-colors duration-250 motion-reduce:transition-none cursor-pointer hover:max-md:bg-darkblue-10 max-md:hover:text-darkblue-100 active:max-md:bg-darkblue-10 max-md:active:text-darkblue-100"
                >
                    {text}
                </a>
            </li>
        </>
    );
};
