const ProfileComponentsEnum = {
    OVERVIEW: "/profile/overview",
    ACCOUNT_SETTINGS: "/profile/settings",
    ACCOUNT_SETTINGS_EDIT: "/profile/settings/edit",
    BILLING_INFO: "/profile/billing",
    BILLING_INFO_EDIT: "/profile/billing/edit",
    PLAYGROUND: "/profile/playground",
};

export default ProfileComponentsEnum;
