import logoInverse from "assets/images/logo-inverse.svg";

const Footer = () => {
    return (
        <div className="relative text-xl text-white">
            <div className="bg-profinit-gray">
                <div className="container relative z-10 py-10 md:py-[3.5rem]">
                    <div className="grid gap-12 md:grid-cols-12 md:gap-x-8 md:gap-y-[3.5rem]">
                        <div className="md:col-span-6 lg:col-span-3">
                            <img
                                src={logoInverse}
                                alt="EvalMy.AI logo"
                                width="124"
                                height="26"
                                loading="lazy"
                                className="mb-6 block"
                            />
                            <p className="max-w-80 text-base">
                                EvalMy.AI is automated testing
                                <br />
                                tool for your own AI solution.
                            </p>
                        </div>
                        <div className="md:col-span-6 lg:col-span-3">
                            <p className="mb-7 font-bold">Get to know us</p>
                            <ul className="space-y-2 text-base">
                                <li>
                                    <a
                                        href="https://profinit.eu/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-20 hover:text-white hover:underline"
                                    >
                                        About us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="mailto:evalmyai@profinit.eu"
                                        className="text-gray-20 hover:text-white hover:underline"
                                    >
                                        Contact us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="md:col-span-6 lg:col-span-3">
                            <p className="mb-7 font-bold">For customers</p>
                            <ul className="space-y-2 text-base">
                                <li>
                                    <a
                                        href="/login"
                                        className="text-gray-20 hover:text-white hover:underline"
                                    >
                                        Login
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/register"
                                        className="text-gray-20 hover:text-white hover:underline"
                                    >
                                        Sign up
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="md:col-span-6 lg:col-span-3">
                            <p className="mb-7 font-bold">AI by Profinit</p>
                            <ul className="space-y-2 text-base">
                                <li>
                                    <a
                                        href="https://jsmeprofinit.eu/projekty/ai-asistent-znalostnich-bazi/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-20 hover:text-white hover:underline"
                                    >
                                        AI assistants & integrations
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://profinit.eu/sluzby/data-science-umela-inteligence/"
                                        className="text-gray-20 hover:text-white hover:underline"
                                    >
                                        Data science & ML
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="md:col-span-12">
                            <p className="text-base">
                                © 2024 EvalMy.AI
                                <br className="md:hidden" />
                                <a
                                    href="/privacy-policy"
                                    className="mt-5 text-gray-20 hover:text-white hover:underline md:ml-6 md:mt-0"
                                >
                                    Privacy policy
                                </a>
                                <br className="md:hidden" />
                                <a
                                    href="/cookies-management"
                                    className="mt-5 text-gray-20 hover:text-white hover:underline md:ml-6 md:mt-0"
                                >
                                    Cookies management
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
