import overview from "assets/images/homepage-overview.png";
import pattern from "assets/images/pattern-min.png";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { AuthConsumer } from "frontend/context/AuthContext";

const useDotButton = emblaApi => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);

    const onDotButtonClick = useCallback(
        index => {
            if (!emblaApi) return;
            emblaApi.scrollTo(index);
        },
        [emblaApi],
    );

    const onInit = useCallback(emblaApi => {
        setScrollSnaps(emblaApi.scrollSnapList());
    }, []);

    const onSelect = useCallback(emblaApi => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onInit(emblaApi);
        onSelect(emblaApi);
        emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
    }, [emblaApi, onInit, onSelect]);

    return {
        selectedIndex,
        scrollSnaps,
        onDotButtonClick,
    };
};

const DotButton = props => {
    const { children, ...restProps } = props;

    return (
        <button type="button" {...restProps}>
            {children}
        </button>
    );
};

const Evaluation = () => {
    const { authed } = AuthConsumer();
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, duration: 30 }, [
        Autoplay({ delay: 11000, stopOnInteraction: false, stopOnMouseEnter: true }),
    ]);

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

    return (
        <>
            <section
                className={`relative mb-14 ${authed ? "max-md:pt-4" : "mt-[70px]"} overflow-hidden text-xl text-white md:mt-0`}
            >
                <img
                    src={pattern}
                    alt=""
                    aria-hidden="hidden"
                    loading="eager"
                    className="absolute inset-0 h-full w-full object-cover opacity-5 mix-blend-luminosity"
                />
                <div className="bg-darkblue-100">
                    <div className="container relative z-10 md:grid md:grid-cols-12 md:gap-6 md:pt-16">
                        <div className="pb-20 pt-10 md:col-span-6">
                            <div className="relative">
                                <p className="text-8xl font-bold text-white">
                                    Automated AI-answer verification
                                </p>
                                <p className="text-6xl text-white">... just one API call away!</p>
                            </div>
                        </div>
                        <div className="px-6 md:relative md:col-span-5 md:col-start-8 md:self-end md:px-0">
                            <span className="mb-24 hidden md:block md:aspect-ao"></span>
                            <div className="md:absolute md:top-0 md:before:pointer-events-none md:before:absolute md:before:right-0 md:before:top-0 md:before:h-full md:before:w-8 md:before:rounded md:before:shadow">
                                <img
                                    src={overview}
                                    alt="Account overview desktop"
                                    height="auto"
                                    loading="eager"
                                    className="z-10 w-100 md:aomask aspect-2/1 rounded-t object-cover object-top shadow md:aspect-auto md:rounded-b"
                                />
                            </div>
                            <span className="block h-24 md:hidden"></span>
                        </div>
                    </div>
                </div>
                <div className="flex w-full flex-wrap justify-center">
                    <div className="w-full max-w-desktop flex-wrap px-6 md:-ml-6 md:mr-6 md:grid md:grid-cols-12">
                        <div className="-mt-24 rounded-xl bg-profinit-gray md:col-span-5 md:col-start-8">
                            <div className="embla js-embla relative p-6 pb-4 pt-10 duration-500 before:absolute before:left-[-100vw] before:top-24 before:h-[calc(100%-96px)] before:w-svw before:bg-white after:absolute after:right-[-100vw] after:top-24 after:h-[calc(100%-96px)] after:w-svw after:bg-white after:z-0 md:h-full md:p-8">
                                <div
                                    className="embla__viewport overflow-hidden js-embla_viewport dots-container z-20"
                                    ref={emblaRef}
                                >
                                    <div className="embla__container flex">
                                        <div
                                            className="embla__slide"
                                            style={{ flex: "0 0 100%" }}
                                            key={1}
                                        >
                                            <SlideOne />
                                        </div>
                                        <div
                                            className="embla__slide "
                                            style={{ flex: "0 0 100%" }}
                                            key={2}
                                        >
                                            <SlideTwo />
                                        </div>
                                        <div
                                            className="embla__slide"
                                            style={{ flex: "0 0 100%" }}
                                            key={3}
                                        >
                                            <SlideThree />
                                        </div>
                                    </div>
                                    <div className="embla__dots dots">
                                        {scrollSnaps.map((_, index) => (
                                            <DotButton
                                                key={index}
                                                onClick={() => onDotButtonClick(index)}
                                                className={"embla__dot".concat(
                                                    index === selectedIndex
                                                        ? " embla__dot--selected"
                                                        : "",
                                                )}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="gpt-evaluation"
                            className="bg-white -mx-6 p-6 pt-14 m md:p-1 md:col-span-6 z-20 relative md:text-3xl text-profinit-gray md:ml-6 md:mt-[96px] md:col-start-1 md:row-start-1 scroll-m-22 desktop-menu:scroll-m-3"
                        >
                            <h3 className="text-4xl font-bold text-darkblue-100 md:text-6xl">
                                Automated RAG assessment
                            </h3>
                            <p className="my-8">
                                We understand the challenges of manually testing RAG applications.
                                That&apos;s why we created{" "}
                                <span className="text-profinit-darkblue font-bold">evalmy.ai</span>{" "}
                                — a tool to streamline AI answer verification, allowing you to focus
                                on more important tasks.
                            </p>
                            <a
                                href="#how-it-works"
                                className="text-center w-full leading-none rounded-md font-bold inline-block md:w-auto text-white bg-profinit-red hover:bg-profinit-darkred text-lg py-4 px-5"
                            >
                                Get started
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const SlideOne = () => (
    <div className="pb-6">
        <p className="mb-2 text-base">Sample question</p>
        <p className="text-2xl font-bold text-azure-100">Is UK a democratic country?</p>
        <div className="lg:flex lg:items-start lg:gap-[34px]">
            <div className="relative mt-11 rounded-xl border border-dashed border-gray-60 bg-[#3C3C3C] p-[18px] text-base first-of-type:mt-12 lg:w-40 lg:shrink-0">
                <svg className="absolute -top-4 h-8 w-8">
                    <ArrowDown />
                </svg>
                <p className="mb-2 text-azure-100">Correct answer</p>
                <p className="font-bold lg:mb-4">Yes, UK became democracy in 1832.</p>
            </div>
            <div className="relative mt-11 rounded-xl border border-dashed border-gray-60 bg-[#3C3C3C] p-[18px] text-base first-of-type:mt-12">
                <svg className="absolute -top-4 h-8 w-8">
                    <ArrowDown />
                </svg>
                <p className="mb-2 text-azure-100">AI answer</p>
                <p className="font-bold lg:mb-4 mb-4">
                    Yes, the United Kingdom (UK) is a democratic country. It operates as a
                    constitutional monarchy with a parliamentary system.
                </p>
                <p className="absolute -bottom-[13px] inline-block rounded-full bg-white px-3 text-sm leading-[26px] text-profinit-gray">
                    Score: <span className="font-bold text-darkgreen-100">100%</span>
                </p>
            </div>
        </div>
    </div>
);

const SlideTwo = () => (
    <div className="pb-6">
        <p className="mb-2 text-base">Sample question</p>
        <p className="text-2xl font-bold text-azure-100">What is the capital of Paraguay?</p>
        <div className="lg:flex lg:items-start lg:gap-[34px]">
            <div className="relative mt-11 rounded-xl border border-dashed border-gray-60 bg-[#3C3C3C] p-[18px] text-base first-of-type:mt-12 lg:w-40 lg:shrink-0">
                <svg className="absolute -top-4 h-8 w-8">
                    <ArrowDown />
                </svg>
                <p className="mb-2 text-azure-100">Correct answer</p>
                <p className="font-bold lg:mb-4">
                    The capital of Paraguay is Asunción. It is the largest city in the country and
                    serves as its political, economic, and cultural center.
                </p>
            </div>
            <div className="relative mt-11 rounded-xl border border-dashed border-gray-60 bg-[#3C3C3C] p-[18px] text-base first-of-type:mt-12">
                <svg className="absolute -top-4 h-8 w-8">
                    <ArrowDown />
                </svg>
                <p className="mb-2 text-azure-100">AI answer</p>
                <p className="font-bold lg:mb-4 mb-4">
                    The capital of Paraguay is Montevideo, a city with a population of approximately
                    1.8 million people.
                </p>
                <p className="absolute -bottom-[13px] inline-block rounded-full bg-white px-3 text-sm leading-[26px] text-profinit-gray">
                    Score: <span className="font-bold text-profinit-red">0%</span>
                </p>
            </div>
        </div>
    </div>
);
const SlideThree = () => (
    <div className="pb-6">
        <p className="mb-2 text-base">Sample question</p>
        <p className="text-2xl font-bold text-azure-100">
            What are the names of the three musketeers?
        </p>
        <div className="lg:flex lg:items-start lg:gap-[34px]">
            <div className="relative mt-11 rounded-xl border border-dashed border-gray-60 bg-[#3C3C3C] p-[18px] text-base first-of-type:mt-12 lg:w-40 lg:shrink-0">
                <svg className="absolute -top-4 h-8 w-8">
                    <ArrowDown />
                </svg>
                <p className="mb-2 text-azure-100">Correct answer</p>
                <p className="font-bold lg:mb-4">
                    The three musketeers were actually four: Athos, Porthos, Aramis and
                    d&apos;Artagnan.
                </p>
            </div>
            <div className="relative mt-11 rounded-xl border border-dashed border-gray-60 bg-[#3C3C3C] p-[18px] text-base first-of-type:mt-12">
                <svg className="absolute -top-4 h-8 w-8">
                    <ArrowDown />
                </svg>
                <p className="mb-2 text-azure-100">AI answer</p>
                <p className="font-bold lg:mb-4">
                    The three musketeers are Athos, Porthos, and Aramis.
                </p>
                <p className="absolute -bottom-[13px] inline-block rounded-full bg-white px-3 text-sm leading-[26px] text-profinit-gray">
                    Score: <span className="font-bold text-darkblue-80">80%</span>
                </p>
            </div>
        </div>
    </div>
);

export default Evaluation;
