import {
    COMPLEX_CONTRADICTIONS_EXAMPLE,
    COMPLEX_MISSING_FACTS_EXAMPLE,
    INTERMEDIATE_CONTRADICTIONS_EXAMPLE,
    INTERMEDIATE_MISSING_FACTS_EXAMPLE,
    SIMPLE_CONTRADICTIONS_EXAMPLE,
    SIMPLE_MISSING_FACTS_EXAMPLE,
} from "frontend/pages/Playground/components/ExamplesData";
import SymbolEnum from "frontend/pages/Playground/enums/SymbolEnum";

/**
 * Component that renders example button
 */
const ExampleButton = ({ onClick, label }) => {
    return (
        <div className="w-full md:flex-1 p-5">
            <button
                className="bg-profinit-darkblue text-white py-2 px-4 w-full rounded shadow font-semibold"
                onClick={onClick}
            >
                {label}
            </button>
        </div>
    );
};

/**
 * Component that renders box with example buttons.
 * @param {Function} reset Function to ensure consistent state before loading an example
 * @param {Function} setInputData  Setter function for input data state
 * @param {Function} setResult  Setter function for result data state
 * @param {Function} setShowResult  Setter function to toggle if result is shown
 * @param {Function} setIsLoading  Setter function to toggle loading animation
 * @param {string} symbol The selected symbol to show examples for
 */
const Examples = ({ reset, setInputData, setResult, setShowResult, setIsLoading, symbol }) => {
    const loadExample = ({ expected, actual, context, statements, score }) => {
        reset();

        setInputData({
            expected,
            actual,
            context,
            symbol,
        });
        setShowResult(false);
    };

    const handleEasyContradiction = () => {
        switch (symbol) {
            case SymbolEnum.CONTRADICTIONS:
                loadExample(SIMPLE_CONTRADICTIONS_EXAMPLE);
                break;
            case SymbolEnum.MISSING_FACTS:
                loadExample(SIMPLE_MISSING_FACTS_EXAMPLE);
                break;
            default:
                loadExample(SIMPLE_CONTRADICTIONS_EXAMPLE);
                break;
        }
    };

    const handleIntermediateContradiction = () => {
        switch (symbol) {
            case SymbolEnum.CONTRADICTIONS:
                loadExample(INTERMEDIATE_CONTRADICTIONS_EXAMPLE);
                break;
            case SymbolEnum.MISSING_FACTS:
                loadExample(INTERMEDIATE_MISSING_FACTS_EXAMPLE);
                break;
            default:
                loadExample(INTERMEDIATE_CONTRADICTIONS_EXAMPLE);
                break;
        }
    };

    const handleDifficultContradiction = () => {
        switch (symbol) {
            case SymbolEnum.CONTRADICTIONS:
                loadExample(COMPLEX_CONTRADICTIONS_EXAMPLE);
                break;
            case SymbolEnum.MISSING_FACTS:
                loadExample(COMPLEX_MISSING_FACTS_EXAMPLE);
                break;
            default:
                loadExample(COMPLEX_CONTRADICTIONS_EXAMPLE);
                break;
        }
    };
    return (
        <div className="px-4 rounded-2xl shadow bg-white">
            <div className="flex items-center flex-wrap flex-col sm:flex-nowrap sm:flex-row">
                <div className="w-auto mt-4 sm:mt-0">
                    <label className="text-lg font-medium mr-4">Choose examples</label>
                </div>
                <ExampleButton onClick={handleEasyContradiction} label="Simple" />
                <ExampleButton onClick={handleIntermediateContradiction} label="Intermediate" />
                <ExampleButton onClick={handleDifficultContradiction} label="Complex" />
            </div>
        </div>
    );
};

export default Examples;
