import Examples from "frontend/pages/Playground/components/Examples";
import InputForm from "frontend/pages/Playground/components/Input";
import Result from "frontend/pages/Playground/components/Result";
import useForm from "frontend/pages/Playground/hooks/Form";
import React, { useState } from "react";
import pattern from "assets/images/pattern-min.png";
import SymbolEnum from "frontend/pages/Playground/enums/SymbolEnum";

/**
 * Component that renders whole playground
 */
const Playground = ({ isBackground }) => {
    const initialState = {
        expected: "",
        actual: "",
        context: "",
        symbol: SymbolEnum.CONTRADICTIONS,
    };
    const [isLoading, setIsLoading] = useState(false);
    const form = useForm(initialState);

    return (
        <div className={`playground ${isBackground ? "mt-16" : "mt-10"} md:mt-0 relative`}>
            {isBackground && (
                <img
                    src={pattern}
                    alt=""
                    aria-hidden="hidden"
                    loading="eager"
                    className="absolute inset-0 h-full w-full object-cover opacity-5 mix-blend-luminosity pointer-events-none"
                />
            )}
            <div className="container mx-auto px-4 py-6">
                <h1 className="text-7xl font-bold text-center mb-5">Playground</h1>
                <Examples
                    reset={form.resetForm}
                    setInputData={form.setInputData}
                    setResult={form.setResult}
                    setShowResult={form.setShowResult}
                    setIsLoading={setIsLoading}
                    symbol={form.inputData.symbol}
                />

                <div className="mt-4 pt-2 bg-white rounded-2xl shadow">
                    <InputForm form={form} isLoading={isLoading} setIsLoading={setIsLoading} />
                    <Result
                        result={form.result}
                        showResult={form.showResult}
                        jsonIn={form.inputData}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default Playground;
