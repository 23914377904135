import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import NavBar from "frontend/components/NavBar";
import { AuthProvider } from "frontend/context/AuthContext";
import AppRoutes from "frontend/Routes";
import { ScrollProvider } from "frontend/context/ScrollContext";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import cookieConfig from "assets/cookies/config.json";

/**
 * Main component.
 */
function App() {
    useEffect(() => {
        CookieConsent.run(cookieConfig);
    }, []);

    return (
        <>
            <Router>
                <ScrollProvider>
                    <AuthProvider>
                        <NavBar />
                        <AppRoutes />
                    </AuthProvider>
                </ScrollProvider>
            </Router>
        </>
    );
}

export default App;
