import React, { useState, useEffect, useRef } from "react";
import { AuthConsumer, axiosInstance } from "frontend/context/AuthContext";
import { Link, redirect, useNavigate } from "react-router-dom";
import useAuth from "frontend/hooks/Auth";
import Overview from "frontend/pages/Profile/components/Overview/Index";
import AccountSettings from "frontend/pages/Profile/components/AccountSettings/Index";
import BillingInfo from "frontend/pages/Profile/components/BillingInfo/Index";

import { ReactComponent as OverviewIcon } from "assets/icons/menu-overview.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/menu-settings.svg";
import { ReactComponent as BillingIcon } from "assets/icons/menu-billing.svg";
import { ReactComponent as PlaygroundIcon } from "assets/icons/menu-playground.svg";

import ProfileComponentsEnum from "frontend/ComponentsEnum";
import Playground from "frontend/pages/Playground/Index";

/**
 * Asynchronously retrieves information about user from the server (such as its e-mail,...).
 * @returns Information about user.
 */
async function getUserInfo() {
    // make the request
    const response = axiosInstance.get(`/web/users/me`, {
        headers: { "Content-Type": "application/json" },
    });

    try {
        const { data } = await response;
        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Profile page component, that shows detailed information about the user etc.
 * @returns Profile information component.
 */
const Profile = ({ activeComponentProp, isEditMode }) => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [apiToken, setApiToken] = useState("");
    const [accountBalance, setAccountBalance] = useState(null);
    const [activeComponent, setActiveComponent] = useState(activeComponentProp);
    const [isRecharge, SetIsRecharge] = useState(false);
    const { SetUsername, logout } = AuthConsumer();
    const navigate = useNavigate();

    const toggleRecharge = () => {
        SetIsRecharge(!isRecharge);
    };

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    useEffect(() => {
        // Selectors
        const customSelects = document.querySelectorAll(".custom-select");
        const selectButtons = document.querySelectorAll(".select-button");
        const selectedValues = document.querySelectorAll(".js-selected-value");
        const filters = document.querySelectorAll(".icon-filter");
        const filterHeadings = document.querySelectorAll(".js-filter-heading");
        const boxes = document.querySelectorAll(".js-filter-box");
        const buttons = document.querySelectorAll(".js-filter-button");
        const closeButtons = document.querySelectorAll('[data-action="close"]');
        const toggles = document.querySelectorAll("[data-toggle]");

        // Functions
        const handleSelectButtonClick = (selectBtn, customSelect, selectedValue) => {
            selectBtn.addEventListener("click", toggleDropdown);
        };

        const handleOptionClickOrKeyup = (option, selectBtn, customSelect, selectedValue) => {
            option.addEventListener("click", handleEvent);
            option.addEventListener("keyup", handleEvent);
        };

        const handleFilterClick = filter => {
            filter.addEventListener("click", toggleFilter);
        };

        const handleCloseButtonClick = closeBtn => {
            closeBtn.addEventListener("click", closeBox);
        };

        const handleToggleChange = toggle => {
            toggle.addEventListener("change", toggleElements);
        };

        // Event Handlers
        const toggleDropdown = event => {
            const selectBtn = event.currentTarget;
            const customSelect = selectBtn.closest(".custom-select");
            const isActive = customSelect.classList.toggle("active");
            selectBtn.setAttribute("aria-expanded", isActive ? "true" : "false");
        };

        const handleEvent = e => {
            const option = e.currentTarget;
            const customSelect = option.closest(".custom-select");
            const selectedValue = customSelect.querySelector(".js-selected-value");
            const selectBtn = customSelect.querySelector(".select-button");
            const customList = customSelect.querySelector(".select-dropdown-item-custom-list");
            const customButton = customSelect.querySelector(".select-dropdown-item-custom-button");

            if (option.classList.contains("js-custom")) {
                if (customList && customButton) {
                    customList.classList.add("active");
                    customButton.classList.add("active");
                }
                return;
            }

            if (e.type === "click" || e.key === "Enter") {
                if (customList && customButton) {
                    customList.classList.remove("active");
                    customButton.classList.remove("active");
                }
                selectedValue.innerHTML = option.children[1].innerHTML;
                customSelect.classList.remove("active");
            }
        };

        const toggleFilter = event => {
            const filter = event.currentTarget;
            const targetSelector = filter.getAttribute("data-target");
            filter.classList.toggle("active");
            const box = document.querySelector(`[data-box="${targetSelector}"]`);
            box.classList.toggle("max-lg:hidden");

            if (targetSelector === "js-filter-calendar") {
                filterHeadings.forEach(filterHeading => {
                    const isActive = filter.classList.contains("active");
                    const newText = isActive
                        ? filterHeading.getAttribute("data-text-calendar")
                        : filterHeading.getAttribute("data-text-original");

                    filterHeading.innerHTML = newText;
                    toggleFilterVisibility(isActive, filter, box);
                });
            } else {
                handleDefaultFilterToggle(filter);
            }
        };

        const toggleFilterVisibility = (isActive, filter, box) => {
            filters.forEach(f => {
                if (f !== filter) {
                    f.classList.toggle("max-lg:hidden", isActive);
                }
            });

            boxes.forEach(b => {
                if (b !== box) {
                    b.classList.add("max-lg:hidden");
                }
            });

            buttons.forEach(b => {
                b.classList.toggle("hidden", isActive);
            });
        };

        const handleDefaultFilterToggle = filter => {
            filterHeadings.forEach(filterHeading => {
                filterHeading.innerHTML = filterHeading.getAttribute("data-text-original");
            });

            filters.forEach(f => {
                if (f !== filter) {
                    if (f.style?.visibility === "hidden") {
                        f.style.visibility = "visible";
                    } else {
                        f.style.visibility = "hidden";
                    }
                }
            });

            const isActive = Array.from(filters).some(f => f.classList.contains("active"));
            buttons.forEach(b => {
                b.classList.toggle("hidden", !isActive);
            });
        };

        const closeBox = event => {
            const closeBtn = event.currentTarget;
            const target = closeBtn.getAttribute("data-target");
            const box = document.querySelector(`[data-box="${target}"]`);
            if (box) {
                box.remove();
            }
        };

        const toggleElements = event => {
            const toggle = event.currentTarget;
            const targets = document.querySelectorAll(
                `[data-toggle-target="${toggle.getAttribute("data-toggle")}"]`,
            );
            const targetsSecondary = document.querySelectorAll(
                `[data-toggle-target="${toggle.getAttribute("data-toggle")}-secondary"]`,
            );

            targets.forEach(target => {
                target.classList.toggle("max-xl:hidden", !toggle.checked);
            });

            targetsSecondary.forEach(targetSecondary => {
                targetSecondary.classList.toggle("active", toggle.checked);
            });
        };

        // Initialization
        selectButtons.forEach((selectBtn, index) => {
            const customSelect = customSelects[index];
            const selectedValue = selectedValues[index];
            const optionsList = customSelect.querySelectorAll(
                ".select-dropdown .select-dropdown-item",
            );

            // Pre-select the option if already checked
            const preSelectedOption = customSelect.querySelector(
                ".select-dropdown .select-dropdown-item input:checked",
            );
            if (preSelectedOption) {
                selectedValue.innerHTML = preSelectedOption.nextElementSibling.innerHTML;
            }

            handleSelectButtonClick(selectBtn, customSelect, selectedValue);
            optionsList.forEach(option =>
                handleOptionClickOrKeyup(option, selectBtn, customSelect, selectedValue),
            );
        });

        filters.forEach(handleFilterClick);
        closeButtons.forEach(handleCloseButtonClick);
        toggles.forEach(handleToggleChange);

        getUserInfo().then(result => {
            if (result !== null) {
                const { full_name, email, api_token, balance_info } = result;
                setFullName(full_name);
                setEmail(email);
                setApiToken(api_token);
                setAccountBalance(balance_info);
                SetUsername(full_name);
            }
        });

        // Cleanup
        return () => {
            selectButtons.forEach((selectBtn, index) => {
                const customSelect = customSelects[index];
                const optionsList = customSelect.querySelectorAll(
                    ".select-dropdown .select-dropdown-item",
                );

                selectBtn.removeEventListener("click", toggleDropdown);
                optionsList.forEach(option => {
                    option.removeEventListener("click", handleEvent);
                    option.removeEventListener("keyup", handleEvent);
                });
            });

            filters.forEach(filter => {
                filter.removeEventListener("click", toggleFilter);
            });

            closeButtons.forEach(closeBtn => {
                closeBtn.removeEventListener("click", closeBox);
            });

            toggles.forEach(toggle => {
                toggle.removeEventListener("change", toggleElements);
            });
        };
    }, [activeComponentProp, isRecharge]);

    const renderComponent = () => {
        switch (location.pathname) {
            case ProfileComponentsEnum.OVERVIEW:
                return (
                    <Overview balance={accountBalance} toggleRecharge={() => toggleRecharge()} />
                );
            case ProfileComponentsEnum.ACCOUNT_SETTINGS:
            case ProfileComponentsEnum.ACCOUNT_SETTINGS_EDIT:
                return (
                    <AccountSettings
                        curr_api_token={apiToken}
                        isEditMode={isEditMode}
                        getUserInfo={getUserInfo}
                    />
                );
            case ProfileComponentsEnum.BILLING_INFO:
            case ProfileComponentsEnum.BILLING_INFO_EDIT:
                return (
                    <BillingInfo
                        full_name={fullName}
                        vat_number={"GB999 9999 73"}
                        isEditMode={isEditMode}
                    />
                );
            case ProfileComponentsEnum.PLAYGROUND:
                return <Playground isBackground={false} />;
            default:
                return (
                    <Overview balance={accountBalance} toggleRecharge={() => toggleRecharge()} />
                );
        }
    };

    return (
        <>
            <div id="profile-page">
                <div className="flex w-full flex-wrap justify-center bg-white md:py-[50px]">
                    <div className="flex w-full max-w-desktop flex-wrap md:px-6">
                        <div className="menu-column menu-column__fixed menu-column--primary w-full md:w-[225px]">
                            <div
                                className="sub-navigation active flex w-full font-bold max-md:flex-wrap md:w-auto md:items-center"
                                id="navigation"
                            >
                                <ul className="md:gap-y-2 flex flex-col flex-wrap items-center max-md:w-full max-md:pb-[98px] md:flex-row">
                                    <MenuItem
                                        icon={OverviewIcon}
                                        label="Overview"
                                        isActive={
                                            location.pathname === ProfileComponentsEnum.OVERVIEW
                                        }
                                        onClick={() => navigate("/profile/overview")}
                                    />
                                    <MenuItem
                                        icon={SettingsIcon}
                                        label="Account settings"
                                        isActive={
                                            location.pathname ===
                                                ProfileComponentsEnum.ACCOUNT_SETTINGS ||
                                            location.pathname ===
                                                ProfileComponentsEnum.ACCOUNT_SETTINGS_EDIT
                                        }
                                        onClick={() => navigate("/profile/settings")}
                                    />
                                    <MenuItem
                                        icon={BillingIcon}
                                        label="Billing info"
                                        isActive={
                                            location.pathname ===
                                                ProfileComponentsEnum.BILLING_INFO ||
                                            location.pathname ===
                                                ProfileComponentsEnum.BILLING_INFO_EDIT
                                        }
                                        onClick={() => navigate("/profile/billing")}
                                    />
                                    <div className="md:hidden w-full">
                                        <MenuItem
                                            icon={PlaygroundIcon}
                                            label="Playground"
                                            isActive={
                                                location.pathname ===
                                                ProfileComponentsEnum.PLAYGROUND
                                            }
                                            onClick={() => navigate("/profile/playground")}
                                        />
                                    </div>
                                    <li className="mt-auto w-full px-6 pt-6 md:hidden">
                                        <button
                                            onClick={handleLogout}
                                            className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-full md:w-auto text-white bg-profinit-red hover:bg-profinit-darkred text-[18px] py-[15px] px-4.5"
                                        >
                                            Logout from account
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full md:ml-[48px] md:w-[calc(100%-225px-48px)]">
                            {renderComponent()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;

const MenuItem = ({ icon: Icon, label, isActive, onClick }) => (
    <li className="w-full">
        <a
            onClick={onClick}
            className={`js-navigation-item sub-navigation-item block relative text-profinit-gray rounded-md md:p-2.5 hover:text-darkblue-100 hover:bg-darkblue-10 text-[18px] md:text-[16px] navigation group/link max-md:block max-md:w-full max-md:border-b max-md:border-solid max-md:border-blue-40 max-md:bg-white max-md:px-6 max-md:py-4 transition-colors duration-250 motion-reduce:transition-none max-md:pl-[58px] md:pl-[44px] cursor-pointer hover:max-md:bg-darkblue-10 max-md:hover:text-darkblue-100 ${isActive ? "active" : ""}`}
        >
            <svg className="hover:text-profinit-red absolute left-6 md:left-2.5 top-1/2 size-[20px] -translate-y-1/2 transition-colors duration-250 motion-reduce:transition-none">
                <Icon />
            </svg>{" "}
            <span className="text-profinit-darkblue">{label}</span>
        </a>
    </li>
);
