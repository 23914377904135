import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthConsumer } from "frontend/context/AuthContext";
import ErrorMessage from "frontend/components/ErrorMessage";

import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as PasswordIcon } from "assets/icons/password.svg";
import AnimateSpin from "frontend/shared/AnimateSpin";

/**
 * Component that handles logging in.
 * @returns Login component.
 */
const Login = () => {
    const navigate = useNavigate();
    const { login } = AuthConsumer();
    const [errorMessage, setErrorMessage] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async e => {
        e.preventDefault();
        setIsLoading(true);
        const succ = await login(email, password);
        setIsLoading(false);
        if (succ) {
            navigate("/profile/overview");
        } else {
            setErrorMessage("Invalid credentials");
        }
    };

    return (
        <div id="tab-item-login-form" className="js-tab-item tab-item flex flex-wrap active">
            <form className="flex w-full flex-wrap items-center" onSubmit={handleLogin}>
                <div className="w-full">
                    <h3 className="text-center text-xl font-bold text-darkblue-100">
                        Welcome back
                    </h3>
                    <p className="relative mb-10 mt-2 text-center text-profinit-gray md:col-span-6">
                        Enter your credentials to login
                    </p>
                    <div className="relative text-lg mt-4 first-of-type:mt-0">
                        <svg className="absolute left-[12px] top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                            <UserIcon />
                        </svg>
                        <input
                            type="email"
                            id="login-email"
                            name="email"
                            placeholder="Account email"
                            className="w-full rounded-lg bg-white pr-4 py-3 ring-0 ring-blue-40 hover:ring-1 hover:ring-white focus:ring-1 focus:ring-white placeholder:text-darkblue-60 pl-[44px]"
                            maxLength="255"
                            autoComplete="email"
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="relative text-lg mt-4 first-of-type:mt-0">
                        <svg className="absolute left-[12px] top-1/2 h-[18px] w-[18px] -translate-y-1/2">
                            <PasswordIcon />
                        </svg>{" "}
                        <input
                            type="password"
                            id="login-password"
                            name="password"
                            placeholder="Password"
                            className="w-full rounded-lg bg-white pr-4 py-3 ring-0 ring-blue-40 hover:ring-1 hover:ring-white focus:ring-1 focus:ring-white placeholder:text-darkblue-60 pl-[44px]"
                            maxLength="255"
                            autoComplete="current-password"
                            required=""
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <ErrorMessage message={errorMessage} />
                    <div className="mt-6 w-full">
                        <button
                            type="submit"
                            className="text-center w-full leading-none rounded-md font-bold inline-block text-white bg-profinit-red hover:bg-profinit-darkred text-lg py-4 px-5"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <div className="w-full flex justify-center">
                                    <AnimateSpin />
                                </div>
                            ) : (
                                <div className="py-[1px]">Login</div>
                            )}
                        </button>
                    </div>
                </div>
            </form>
            <p className="hidden relative mt-10 w-full text-center text-darkblue-100 md:mt-auto">
                <a href="/" className="hover:underline">
                    Forgot password?
                </a>
            </p>
        </div>
    );
};

export default Login;
