import { useCookies } from "react-cookie";
import { useState } from "react";
import { axiosInstance } from "frontend/context/AuthContext";

/**
 * Tries to asynchronously log in using email and password of the user.
 * @param {string} email Email of the user
 * @param {string} password Password of the user.
 * @returns Array [logged in, response data], where response data contains data of the response, namely the tokens (if the log in was successful).
 */
const tryLogin = async (email, password) => {
    // make the request
    const response = axiosInstance.post(
        "/web/token",
        {
            grant_type: "",
            username: email,
            password: password,
            scope: "",
            client_id: "",
            client_secret: "",
        },
        {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        },
    );

    try {
        const { data } = await response;
        return [true, data];
    } catch (e) {
        console.error(e);
        return [false, null];
    }
};

/**
 * Hook that performs authentication. Shouldn't be used directly, but instead through `AuthConsumer`.
 * @returns Object consistintg of {is authenticated (bool), login function, logout function}.
 */
const useAuth = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["token", "token-type"]);
    const [authed, setAuthed] = useState(() => "token" in cookies && "token-type" in cookies);
    const [username, SetUsername] = useState("");

    // initialize authorization header if authenticated
    if (authed && "token" in cookies && "token-type" in cookies) {
        axiosInstance.defaults.headers.common["Authorization"] =
            `${cookies["token-type"]} ${cookies["token"]}`;
    }

    return {
        authed,
        async login(email, password) {
            const [succ, data] = await tryLogin(email, password);

            setAuthed(succ);
            // if successful => add authorization header and store cookies
            if (succ) {
                axiosInstance.defaults.headers.common["Authorization"] =
                    `${data.auth_token_type} ${data.access_token}`;
                setCookie("token", data.access_token, { path: "/" });
                setCookie("token-type", data.auth_token_type, { path: "/" });
            }

            return succ;
        },
        logout() {
            // remove authorization header and cookies
            setAuthed(false);
            delete axiosInstance.defaults.headers.common["Authorization"];
            removeCookie("token", { path: "/" });
            removeCookie("token-type", { path: "/" });
            return true;
        },
        username,
        SetUsername,
    };
};

export default useAuth;
