import { REACT_APP_BACKEND_URL } from "frontend/config";
import { useState } from "react";
import { axiosInstance, AuthConsumer } from "frontend/context/AuthContext";
import ErrorMessage from "frontend/components/ErrorMessage";
import { ReactComponent as SettingsIcon } from "assets/icons/menu-settings.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import SymbolEnum from "frontend/pages/Playground/enums/SymbolEnum";
import {
    getExampleResult,
    tryGetExampleType,
} from "frontend/pages/Playground/components/ExamplesData";

/**
 * Component that renders playground form
 * @param {object} form  To manipulate shared form states
 * @param {boolean} isLoading State variable to indicate loading animation
 * @param {Function} setIsLoading  Setter function to toggle loading animation
 */
const InputForm = ({ form, isLoading, setIsLoading }) => {
    const { authed } = AuthConsumer();
    const [inputErrors, setInputErrors] = useState({});
    const [isActiveSymbolDropdown, setIsActiveSymbolDropdown] = useState(false);

    const INPUT_LIMIT_CHARS = 1000;

    const handleSubmit = async e => {
        form.setErrorMessage("");
        e.preventDefault();
        setIsLoading(true);
        form.setShowResult(false);

        const inputData = {
            expected: form.inputData.expected,
            actual: form.inputData.actual,
            context: form.inputData.context,
        };

        const exampleType = tryGetExampleType(inputData);

        if (exampleType !== null) {
            // Input is one of the examples
            // Simulate 3s call
            setTimeout(() => {
                form.setResult(getExampleResult(form.inputData.symbol, exampleType));
                form.setShowResult(true);
                setIsLoading(false);
            }, 3000);
        } else {
            try {
                // if user is authenticated => call authenticated endpoint
                let endpointUrl;
                if (authed) {
                    // The paid sandbox will probably be disabled in the beginning.
                    //endpointUrl = `/web/symbol/evaluate/contradictions/v1`;
                    endpointUrl = `/web/symbol/evaluate_public/${form.inputData.symbol}/v1`;
                } else {
                    endpointUrl = `/web/symbol/evaluate_public/${form.inputData.symbol}/v1`;
                }
                let name_mappings;
                const { data } = await axiosInstance.post(
                    endpointUrl,
                    {
                        input_data: inputData,
                        scoring: {
                            name: "linear",
                            params: {
                                weights: { critical: 1, large: 0.5, small: 0.1, negligible: 0 },
                            },
                        },
                        aggregation: {
                            n_calls: 1,
                            agg_method: "mean",
                        },
                    },
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                let statements = JSON.parse(data.reasoning).statements;
                form.setResult({ statements: statements, scores: data.scores });
                form.setShowResult(true);
                setIsLoading(false);
            } catch (error) {
                form.setResult(null);
                form.setShowResult(true);
                if (error.response?.status === 402) {
                    form.setErrorMessage("Insufficient balance to complete request!");
                } else if (error.response?.status === 429) {
                    form.setErrorMessage(
                        "An error occurred while processing the request. Either because the LLM failed to oblige, or because you've reached the limit of free Playground use.",
                    );
                } else if (error.response?.status === 400) {
                    setInputErrors(error.response?.data?.detail?.messages);
                    form.setShowResult(false);
                    setIsLoading(false);
                } else {
                    console.error(error);
                }
            }
        }
    };

    const handleInputChange = (fieldName, value) => {
        form.setErrorMessage("");
        form.setInputData(prevData => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const toggleIsActiveSymbolDropdown = () => {
        setIsActiveSymbolDropdown(!isActiveSymbolDropdown);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="flex flex-col md:flex-row md:justify-center space-y-4 md:space-y-0 pt-4">
                    <div className="w-full md:w-1/2 px-4">
                        <label className="block text-lg font-medium mb-1">Expected answer</label>
                        <textarea
                            className={`w-full h-32 border border-gray-300 rounded-md p-2 ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                            placeholder="Enter expected answer"
                            value={form.inputData.expected}
                            onChange={e => handleInputChange("expected", e.target.value)}
                            required
                            disabled={isLoading}
                            maxLength={INPUT_LIMIT_CHARS}
                        />
                        {inputErrors?.expected && <ErrorMessage message={inputErrors.expected} />}
                    </div>
                    <div className="w-full md:w-1/2 px-4">
                        <label className="block text-lg font-medium mb-1">GPT answer</label>
                        <textarea
                            className={`w-full h-32 border border-gray-300 rounded-md p-2 ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                            placeholder="Enter GPT answer"
                            value={form.inputData.actual}
                            onChange={e => handleInputChange("actual", e.target.value)}
                            required
                            disabled={isLoading}
                            maxLength={INPUT_LIMIT_CHARS}
                        />
                        {inputErrors?.actual && <ErrorMessage message={inputErrors.actual} />}
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
                    <div className="w-full sm:w-fit px-4">
                        <div
                            className="js-filter-box w-full lg:w-[186px] xl:w-[206px] text-profinit-gray flex flex-nowrap justify-between gap-x-1.5 items-center"
                            data-box="js-filter-filter"
                        >
                            <div
                                className={`${isActiveSymbolDropdown ? "active" : ""} group custom-select relative w-full`}
                            >
                                <span
                                    role="combobox"
                                    aria-label="select button"
                                    aria-haspopup="listbox"
                                    aria-expanded="false"
                                    aria-controls="select-dropdown"
                                    data-default="Usage"
                                    className="select-button cursor-pointer form-input w-full flex justify-between items-center text-[14px] py-[11px] ring-0 border-[1.5px] border-solid placeholder:text-darkblue-60 focus:ring-0 focus:border-darkblue-40 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none group-[.active]:shadow-dropdown group-[.active.custom-select--reverted]:relative group-[.active.custom-select--reverted]:z-10 px-3"
                                    onClick={toggleIsActiveSymbolDropdown}
                                >
                                    <span className="selected-value text-left flex items-center">
                                        <svg className="size-[19px] mr-3">
                                            <SettingsIcon />
                                        </svg>
                                        <span className="js-selected-value mr-3 whitespace-nowrap font-bold">
                                            {form.inputData.symbol}
                                        </span>
                                    </span>
                                    <svg className="arrow size-[12px] duration-250 transition-all motion-reduce:transition-none">
                                        <ArrowIcon />
                                    </svg>
                                </span>
                                <div
                                    className="select-dropdown absolute z-10 w-full bg-darkblue-10 border border-solid border-darkblue-60 max-h-[200px] overflow-y-auto duration-250 transition-all motion-reduce:transition-none translate-y-0 opacity-0 invisible group-[.active]:shadow-dropdown group-[.active.custom-select--reverted]:z-0"
                                    role="listbox"
                                >
                                    <div
                                        role="option"
                                        className="select-dropdown-item relative cursor-pointer flex items-center border-t border-t-solid border-t-blue-40 first-of-type:border-t-0"
                                    >
                                        <input
                                            type="radio"
                                            id="symbol-filter-type-option-01"
                                            name="symbolFilterType"
                                            className="peer absolute left-0 opacity-0"
                                            checked={
                                                form.inputData.symbol === SymbolEnum.CONTRADICTIONS
                                                    ? "checked"
                                                    : ""
                                            }
                                            onChange={() => {
                                                handleInputChange(
                                                    "symbol",
                                                    SymbolEnum.CONTRADICTIONS,
                                                );
                                                toggleIsActiveSymbolDropdown();
                                            }}
                                        />{" "}
                                        <label
                                            htmlFor="symbol-filter-type-option-01"
                                            className="w-full font-bold px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                        >
                                            {SymbolEnum.CONTRADICTIONS}
                                        </label>
                                    </div>
                                    <div
                                        role="option"
                                        className="select-dropdown-item relative cursor-pointer flex items-center border-t border-t-solid border-t-blue-40 first-of-type:border-t-0"
                                    >
                                        <input
                                            type="radio"
                                            id="symbol-filter-type-option-02"
                                            name="symbolFilterType"
                                            className="peer absolute left-0 opacity-0"
                                            checked={
                                                form.inputData.symbol === SymbolEnum.MISSING_FACTS
                                                    ? "checked"
                                                    : ""
                                            }
                                            onChange={() => {
                                                handleInputChange(
                                                    "symbol",
                                                    SymbolEnum.MISSING_FACTS,
                                                );
                                                toggleIsActiveSymbolDropdown();
                                            }}
                                        />
                                        <label
                                            htmlFor="symbol-filter-type-option-02"
                                            className="w-full font-bold px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                        >
                                            {SymbolEnum.MISSING_FACTS}
                                        </label>
                                    </div>
                                    <div
                                        role="option"
                                        className="select-dropdown-item relative cursor-pointer flex items-center border-t border-t-solid border-t-blue-40 first-of-type:border-t-0"
                                    >
                                        <input
                                            type="radio"
                                            id="symbol-filter-type-option-03"
                                            name="symbolFilterType"
                                            className="peer absolute left-0 opacity-0"
                                            checked={
                                                form.inputData.symbol === SymbolEnum.F1
                                                    ? "checked"
                                                    : ""
                                            }
                                            onChange={() => {
                                                handleInputChange("symbol", SymbolEnum.F1);
                                                toggleIsActiveSymbolDropdown();
                                            }}
                                        />{" "}
                                        <label
                                            htmlFor="symbol-filter-type-option-03"
                                            className="w-full font-bold px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                        >
                                            {SymbolEnum.F1}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-fit px-4">
                        <button
                            className={`bg-profinit-red w-full sm:w-fit py-2 px-4 font-bold rounded shadow ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                            type="submit"
                            disabled={isLoading}
                        >
                            Evaluate!
                        </button>
                    </div>
                    <div className="w-full sm:w-fit px-4">
                        <button
                            className="bg-profinit-darkblue w-full sm:w-fit text-white font-semibold shadow py-2 px-4 rounded whitespace-nowrap"
                            onClick={() => {
                                form.resetForm();
                                setInputErrors({});
                            }}
                            type="button"
                            disabled={isLoading}
                        >
                            Clear all
                        </button>
                    </div>
                    <div className="w-full sm:w-full px-4">
                        <input
                            type="text"
                            placeholder="Enter context (optional)"
                            value={form.inputData.context}
                            onChange={e => handleInputChange("context", e.target.value)}
                            className={`w-full border border-gray-300 rounded-md p-2 ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
                            maxLength={INPUT_LIMIT_CHARS}
                        />
                        {inputErrors?.context && <ErrorMessage message={inputErrors.context} />}
                    </div>
                </div>
                {form.errorMessage && (
                    <div className="text-profinit-red font-bold w-full text-center">
                        <ErrorMessage message={form.errorMessage} />
                    </div>
                )}
            </form>
        </div>
    );
};

export default InputForm;
