import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import semScore from "assets/images/C3_score_formula.svg";
import animation from "assets/images/howitworks.svg";
import { useEffect, useState } from "react";

const Animation = () => {
    const [animationKey, setAnimationKey] = useState(0);

    useEffect(() => {
        const img = document.querySelector("#howItWorksAnimation");

        if (!img) {
            return;
        }

        const imgSrc = img.src;

        // Duration: layer2 step11 delay + duration + 2 seconds
        const duration = 14.8 + 0.75 + 2;

        let blob = null;
        let blobUrl = null;
        let reloadTimeout = null;

        function imageLoaded() {
            setReload();
            fetch(imgSrc + "?fetch") // Add `?fetch` to avoid Safari bug
                .then(response => response.blob())
                .then(b => {
                    blob = b;
                });
        }

        function setReload() {
            reloadTimeout = setTimeout(() => {
                img.removeEventListener("load", imageLoaded);

                if (blobUrl) {
                    URL.revokeObjectURL(blobUrl);
                }

                blobUrl = URL.createObjectURL(blob);
                img.src = blobUrl;

                setReload();
            }, duration * 1000);
        }

        img.addEventListener("load", imageLoaded);

        return () => {
            img.removeEventListener("load", imageLoaded);
            clearTimeout(reloadTimeout);
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, []);

    return (
        <>
            <img
                className="mx-auto"
                src={animation}
                width="498"
                height="750"
                id="howItWorksAnimation"
                loading="lazy"
                key={animationKey}
                alt="Animated SVG"
            />
        </>
    );
};

const HowItWorks = () => {
    const code = `
from evalmyai import Evaluator

data = {
    "expected": "Jane is twelve.",
    "actual": "Jane is 12 yrs and 7 mths old."
} 
    
evaluator = Evaluator(auth, token) 

result = evaluator.evaluate(data)
    `;
    return (
        <>
            <div className="md:grid md:grid-cols-12 md:gap-x-6 md:gap-y-22">
                <div id="how-it-works" className="md:col-span-6 scroll-animate-delay">
                    <h3 className="text-4xl font-bold text-darkblue-100 md:text-6xl">
                        What is C3-Score?
                    </h3>
                    <p className="mt-8 md:text-3xl">
                        C3-score is a unique and balanced qualitative metric designed for evaluating
                        AI answers. The C3-score consists of three key components:
                    </p>
                    <ol className="font-bold score-list">
                        <li className="pl-1">
                            <span className="text-profinit-darkblue md:text-3xl">
                                Completeness:
                            </span>
                            <br />
                            <p className="font-normal">
                                No facts are missing from the AI&apos;s answer.
                            </p>
                        </li>
                        <li className="mt-5 pl-1">
                            <span className="text-profinit-darkblue md:text-3xl">Correctness:</span>
                            <br />
                            <p className="font-normal">
                                The answer contains no extra or fabricated information
                                (no&nbsp;hallucinations).
                            </p>
                        </li>
                        <li className="mt-5 pl-1">
                            <span className="text-profinit-darkblue md:text-3xl">
                                Contradiction:
                            </span>
                            <br />
                            <p className="font-normal">
                                There is no logical inconsistency within the answers.
                            </p>
                        </li>
                    </ol>
                    <img width={450} height={64} src={semScore} className="mb-8" />
                    <a
                        href="/playground"
                        className="text-center w-full leading-none rounded-md font-bold inline-block md:w-auto text-white bg-profinit-red hover:bg-profinit-darkred text-lg py-4 px-5"
                    >
                        Try it for free
                    </a>
                </div>
                <div className="my-18 mt-16 md:col-span-5 md:col-start-8 md:row-span-2 md:mt-10 md:text-lg">
                    <Animation />
                </div>
                <div
                    className="scroll-m-22 md:col-span-5 desktop-menu:scroll-m-3 scroll-animate-delay"
                    id="rest-api"
                >
                    <h4 className="text-2xl font-bold pb-2">Rest API integration</h4>
                    <p className="mt-2">
                        REST API integration allows you to easily incorporate the{" "}
                        <span className="text-profinit-darkblue font-bold">evalmy.ai</span> service
                        into your development and CI/CD processes.
                    </p>
                    <h4 className="text-2xl font-bold mt-8 pb-2">Python library</h4>
                    <p className="mt-2">
                        If you&apos;re developing in Python, you can simplify the process by
                        importing our Python client library and calling the service directly within
                        your code.
                    </p>
                    <div className="mt-7">
                        <SyntaxHighlighter
                            className="python-lib-code"
                            customStyle={{ fontSize: 14, borderRadius: "10px", padding: 28 }}
                            language="python"
                        >
                            {code}
                        </SyntaxHighlighter>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HowItWorks;
