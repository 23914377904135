import Footer from "frontend/pages/Home/components/Footer";

const PrivacyPolicy = () => {
    return (
        <>
            <div className="p-6 bg-gray-50 max-md:pt-18">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-5xl font-bold mb-4 text-center">Privacy Policy</h1>
                    <h2 className="text-xl font-semibold mb-2">Privacy Statement</h2>
                    <p className="mb-2">
                        Since the protection of your personal data is important to us, Profinit EU,
                        s.r.o., with registered office at Tychonova 270/2, Hradčany, 160 00 Prague
                        6, ID No.: 04434081, registered in the Commercial Register maintained by the
                        Municipal Court in Prague under the Commercial Register No. C 247646
                        (hereinafter also referred to as the “Controller”), we will follow the
                        following principles to ensure the highest level of protection of your
                        personal data.
                    </p>
                    <p className="mb-2">
                        This declaration and the subsequent processing of personal data are governed
                        in particular by Regulation (EU) 2016/679 of the European Parliament and of
                        the European Council dated on 27th April 2016 on the protection of natural
                        persons with regard to the processing of personal data and on the free
                        movement of such data (General Data Protection Regulation), (hereinafter
                        referred to as “GDPR”). Cases that are not regulated by the GDPR or
                        expressly allow their regulation to be delegated to the national level are
                        governed by Act No. 110/2019 Coll. on the processing of personal data, as
                        amended.
                    </p>
                    <p className="mb-2">
                        This statement may be amended by the Controller at any time, in particular
                        in the event of changes in the relevant legislation relating to the
                        protection of personal data, so in your own interest, we recommend that you
                        monitor our website for any updates to this statement.
                    </p>
                    <p className="mb-4">
                        First, we would like to explain some basic concepts to help you better
                        understand the following text of this declaration.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Personal data</h2>
                    <p className="mb-4">
                        Personal data is any information about an identified or identifiable natural
                        person (data subject). An identifiable natural person is a natural person
                        who can be identified, directly or indirectly, in particular by reference to
                        an identifier (name, surname, number, network identifier) or to one or more
                        specific elements of the physical, physiological, genetic, mental, economic,
                        cultural or social identity of that natural person.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Data subject</h2>
                    <p className="mb-4">
                        The Data subject is the natural person to whom the personal data relate and
                        who is identifiable or identifiable from the personal data.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Data controller</h2>
                    <p className="mb-4">
                        The Controller is the natural or legal person who determines the purposes
                        and means of the processing of personal data and is primarily responsible
                        for the processing. Unless otherwise specified in this policy or in the
                        terms of a particular service, the controller of personal data is Profinit
                        EU, s.r.o.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Personal data processor</h2>
                    <p className="mb-4">
                        A processor is a natural or legal person who, on the basis of instructions
                        from the controller, processes personal data for the controller.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Processing of personal data</h2>
                    <p className="mb-4">
                        Processing is any operation or set of operations which is performed upon
                        personal data or sets of personal data, whether or not by automated
                        processes, such as collection, recording, organisation, structuring,
                        storage, adaptation or alteration, retrieval, consultation, use, disclosure
                        by transmission, dissemination or any other disclosure, alignment or
                        combination, restriction, erasure or destruction.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">How do we process data?</h2>
                    <p className="mb-2">
                        We process the personal data provided by you on the basis of a lawful reason
                        according to Article 6 GDPR paragraph 1 (b), namely if the processing is
                        necessary for the performance of a contract or according to (c) to comply
                        with a legal obligation or according to (f) if it is necessary for the
                        purposes of the legitimate interests of the Controller. In addition, also
                        according to point a) on the basis of your consent to the processing of such
                        data where the legitimate interests according to the aforementioned
                        provision are not sufficient as a lawful ground for processing.
                    </p>
                    <p className="mb-2">
                        Personal data is processed for the purposes of performance under existing
                        contractual relationships and contractual relationships under negotiation.
                    </p>
                    <p className="mb-4">
                        We only ever use the personal data you provide for the purpose for which the
                        data was disclosed or otherwise provided to us and we always transparently
                        inform the user of this purpose.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">What data do we process?</h2>
                    <p className="mb-2">
                        As part of our communications and business relationships, we collect the
                        following personal data:
                    </p>
                    <ul className="policy-list">
                        <li>salutation/title</li>
                        <li>name</li>
                        <li>surname</li>
                        <li>contact address (for employment)</li>
                        <li>e-mail address</li>
                        <li>phone number (or numbers, if you give them to us)</li>
                    </ul>
                    <h2 className="text-xl font-semibold mb-2">
                        Retention period of personal data
                    </h2>
                    <p className="mb-4">
                        We retain the data for the period necessary for the purposes of performance
                        under the contract and for the period necessary to comply with legal
                        obligations or to protect our rights. Data provided on the basis of the data
                        subject’s consent will be retained for the period specified in the consent.
                        If you exercise your right to be forgotten or withdraw the consent given,
                        the data will of course be retained for a shorter period.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Data transmission</h2>
                    <p className="mb-4">
                        The Controller is entitled to process personal data both automatically and
                        manually, also together with other controllers or through designated
                        processors. Personal data are/will only be made available to authorised
                        employees of the Controller or employees of the processor, and only to the
                        extent necessary for the purposes of processing. Transfers of personal data
                        to other controllers or processors are kept to a minimum and all contractual
                        relationships are based on processing agreements in which the other
                        controllers or our processors have guaranteed to take measures to ensure the
                        same level of security of personal data as provided by our company.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Cookies</h2>
                    <p className="mb-4">
                        To make our offer relevant to you, the evalmy.ai website uses text cookies.
                        You can learn more about them
                        <a href="#"> here</a>, where you can also manage them.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Data security</h2>
                    <p className="mb-4">
                        To achieve the highest possible level of protection for the personal
                        information you provide, we use certain technologies and security features.
                        While we do our best to protect your personal information from loss, misuse,
                        unauthorized access, alteration or disclosure, it is never possible to
                        ensure 100% control. Therefore, in the event of any incidents that reach a
                        certain level of risk, we will notify you immediately.
                    </p>

                    <h2 className="text-xl font-semibold mb-2">Personal data subjects’ rights</h2>
                    <p className="mb-2">
                        In order to respect the principle of transparency in the processing of
                        personal data, we are happy to provide you with information on what personal
                        data we process at the contact details below.
                    </p>
                    <p className="mb-2">
                        Where legally possible, you have the right to amend or correct the personal
                        data we hold about you.
                    </p>
                    <p className="mb-2">
                        If the processing of your personal data is based on your consent, you have
                        the right to restrict this processing, withdraw your consent or even have
                        any data we hold based on your consent erased. The exceptions to this are
                        where the processing is carried out because of a legal obligation or because
                        of our legitimate interest.
                    </p>
                    <p className="mb-2">
                        You also have the right to a copy of the personal data we hold about you,
                        including its sources, purposes and methods of processing, electronic
                        decision-making and the parties with whom we share your data. We will
                        endeavour to make the information you request available to you within a
                        reasonable time. Depending on what information you request, we may charge
                        you a small fee to the extent permitted by applicable law.
                    </p>
                    <p className="mb-4">
                        If you believe that the Controller’s processing of your personal data
                        violates the GDPR, you have the right to object to the processing and also
                        the right to file a complaint with the Office for Personal Data Protection,
                        located at Pplk. Sochor 27, 170 00 Prague 7.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Contact details</h2>
                    <p className="mb-4">
                        In case of questions, complaints, comments or exercising the rights of data
                        subjects, please contact us at the following email address: gdpr@profinit.eu
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
