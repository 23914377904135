const Tutorial = () => {
    return (
        <>
            <div className="md:grid md:grid-cols-12 md:items-end md:gap-6">
                <div
                    id="tutorial"
                    className="md:col-span-6 scroll-m-22 desktop-menu:scroll-m-3 scroll-animate-delay"
                >
                    <h3 className="text-4xl font-bold text-darkblue-100 md:text-6xl">Tutorial</h3>
                    <p className="my-8 md:text-3xl">
                        Explore our step-by-step tutorial and documentation on GitHub. Whether
                        you&apos;re a beginner embarking on your first AI project or a professional
                        AI studio seeking process automation and cost reduction, our resources will
                        assist you in achieving your goals.
                    </p>
                    <a
                        href="https://datascience.profinitservices.cz/evalmyai/evalmyai-client/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center w-full leading-none rounded-md font-bold inline-block md:w-auto text-white bg-darkblue-100 hover:bg-profinit-darkblue text-lg py-4 px-5"
                    >
                        See tutorial
                    </a>
                </div>
                <div className="mt-12 md:col-span-5 md:col-start-8 md:mt-0 scroll-animate-delay">
                    <div className="rounded-lg bg-blue-10 p-7">
                        <h4 className="text-2xl font-bold">Technical support</h4>
                        <p className="mt-6">
                            Experience seamless support with our dedicated technical customer
                            service team. Whether you&apos;re a developer in need of guidance or
                            encountering a technical challenge, we&apos;re here to help. We&apos;re
                            just one call or email away!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tutorial;
