import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as ArrowLinearIcon } from "assets/icons/arrow-linear.svg";
import {
    formatDateToDDMMYYYY,
    formatTimeStampToDDMMYYY_HHMMSS,
} from "frontend/shared/DateFormatHelper";
import PaginationEnum from "frontend/pages/Profile/components/Overview/enums/PaginationEnum";
import React, { useEffect, useState } from "react";
import { formatTokens } from "frontend/shared/TokenFormatHelper";
import GraphTypeEnum from "frontend/pages/Profile/components/Overview/enums/GraphTypeEnum";

const BillingAndUsageList = ({ listData, dateRange, isCustomDate }) => {
    const [pageSize, setPageSize] = useState(PaginationEnum.P_10);
    const [currentPage, setCurrentPage] = useState(1);

    const [paginationObject, setPaginationObject] = useState({
        totalItems: listData.length,
        totalPages: Math.ceil(listData.length / pageSize),
        pageSize: pageSize,
        data: listData,
    });

    useEffect(() => {
        setPaginationObject({
            totalItems: listData.length,
            totalPages: Math.ceil(listData.length / pageSize),
            pageSize: pageSize,
            data: listData,
        });
        setCurrentPage(1);
    }, [listData, pageSize]);

    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * paginationObject.pageSize;
        const endIndex = startIndex + paginationObject.pageSize;
        return listData.slice(startIndex, endIndex);
    };

    const goToPage = page => {
        setCurrentPage(page);
    };

    const goToNextPage = e => {
        e.preventDefault();
        if (currentPage < paginationObject.totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const goToPrevPage = e => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handlePageSizeChange = newPageSize => {
        setPageSize(newPageSize);
        setPaginationObject(prevState => {
            const newState = {
                ...prevState,
                totalPages: Math.ceil(prevState.totalItems / newPageSize),
                pageSize: newPageSize,
            };

            return newState;
        });
        setCurrentPage(1);
    };
    return (
        <>
            <DateRangeDisplay dateRange={dateRange} isCustomDate={isCustomDate} />
            <div className="max-md:px-6 flex flex-wrap w-full">
                <div className="overflow-y-hidden overflow-x-auto w-full">
                    <div className="max-md:hidden mb-1 relative flex flex-wrap max-md:justify-between md:items-center w-full max-w-full after:absolute after:rounded-full after:left-0 after:top-0 after:w-full after:bg-blue-40 after:h-0.5 first-of-type:pt-6 md:mt-4 md:pt-5">
                        <div className="flex flex-nowrap items-center w-full text-profinit-gray font-bold">
                            <div className="min-w-[132px] max-w-[132px] whitespace-nowrap text-darkblue-100 font-bold text-[14px] text-left md:mr-4">
                                Date &amp; time
                            </div>
                            <div className="min-w-[70px] max-w-[70px] whitespace-nowrap text-darkblue-100 font-bold text-[14px] text-left ml-auto md:mr-4">
                                Type
                            </div>
                            <div className="min-w-[76px] max-w-[76px] whitespace-nowrap text-darkblue-100 font-bold text-[14px] text-right ml-auto md:mr-4">
                                Amount ($)
                            </div>
                            <div className="min-w-[52px] max-w-[52px] whitespace-nowrap text-darkblue-100 font-bold text-[14px] text-right ml-auto md:mr-4">
                                Tokens
                            </div>
                            <div className="min-w-[126px] max-w-[126px] whitespace-nowrap text-darkblue-100 font-bold text-[14px] text-right ml-auto">
                                Running balance
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        {getCurrentPageData().map((item, index) => (
                            <ListItem key={index} item={item} />
                        ))}
                    </div>
                </div>
                <div className="w-full flex flex-wrap justify-between mt-6 max-md:my-5 max-md:gap-y-5">
                    <div
                        className="w-[70px] sm:w-[180px] flex flex-nowrap justify-between gap-x-1.5 items-center"
                        data-box="js-filter-"
                    >
                        <div className="group custom-select relative w-full custom-select--reverted">
                            <span
                                role="combobox"
                                aria-label="select button"
                                aria-haspopup="listbox"
                                aria-expanded="false"
                                aria-controls="select-dropdown"
                                data-default=""
                                className="select-button cursor-pointer form-input w-full flex justify-between items-center text-[14px] py-[11px] ring-0 border-[1.5px] border-solid placeholder:text-darkblue-60 focus:ring-0 focus:border-darkblue-40 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none group-[.active]:shadow-dropdown group-[.active.custom-select--reverted]:relative group-[.active.custom-select--reverted]:z-10 pl-[14px] pr-[12px]"
                            >
                                <span className="selected-value text-left flex items-center">
                                    <span className="js-selected-value mr-3 whitespace-nowrap">
                                        {paginationObject.pageSize}
                                        <span className="max-sm:hidden pl-1">items per page</span>
                                    </span>
                                </span>
                                <svg className="arrow size-[12px] duration-250 transition-all motion-reduce:transition-none">
                                    <ArrowIcon />
                                </svg>
                            </span>
                            <div
                                className="select-dropdown absolute z-10 w-full bg-darkblue-10 border border-solid border-darkblue-60 max-h-[200px] overflow-y-auto duration-250 transition-all motion-reduce:transition-none translate-y-0 opacity-0 invisible group-[.active]:shadow-dropdown group-[.active.custom-select--reverted]:z-0"
                                role="listbox"
                            >
                                <div
                                    role="option"
                                    className="select-dropdown-item relative cursor-pointer flex items-center border-b border-b-solid border-b-blue-40 last-of-type:border-b-0"
                                >
                                    <input
                                        type="radio"
                                        id="item-per-page-option-01"
                                        name="itemsPerPage"
                                        className="peer absolute left-0 opacity-0"
                                        onClick={() => handlePageSizeChange(PaginationEnum.P_10)}
                                    />{" "}
                                    <label
                                        htmlFor="item-per-page-option-01"
                                        className="w-full px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                    >
                                        {PaginationEnum.P_10}
                                        <span className="max-sm:hidden pl-1">items per page</span>
                                    </label>
                                </div>
                                <div
                                    role="option"
                                    className="select-dropdown-item relative cursor-pointer flex items-center border-b border-b-solid border-b-blue-40 last-of-type:border-b-0"
                                >
                                    <input
                                        type="radio"
                                        id="item-per-page-option-02"
                                        name="itemsPerPage"
                                        className="peer absolute left-0 opacity-0"
                                        onClick={() => handlePageSizeChange(PaginationEnum.P_20)}
                                    />{" "}
                                    <label
                                        htmlFor="item-per-page-option-02"
                                        className="w-full px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                    >
                                        {PaginationEnum.P_20}
                                        <span className="max-sm:hidden pl-1">items per page</span>
                                    </label>
                                </div>
                                <div
                                    role="option"
                                    className="select-dropdown-item relative cursor-pointer flex items-center border-b border-b-solid border-b-blue-40 last-of-type:border-b-0"
                                >
                                    <input
                                        type="radio"
                                        id="item-per-page-option-03"
                                        name="itemsPerPage"
                                        className="peer absolute left-0 opacity-0"
                                        onClick={() => handlePageSizeChange(PaginationEnum.P_50)}
                                    />{" "}
                                    <label
                                        htmlFor="item-per-page-option-03"
                                        className="w-full px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                    >
                                        {PaginationEnum.P_50}
                                        <span className="max-sm:hidden pl-1">items per page</span>
                                    </label>
                                </div>
                                <div
                                    role="option"
                                    className="select-dropdown-item relative cursor-pointer flex items-center border-b border-b-solid border-b-blue-40 last-of-type:border-b-0"
                                >
                                    <input
                                        type="radio"
                                        id="item-per-page-option-04"
                                        name="itemsPerPage"
                                        className="peer absolute left-0 opacity-0"
                                        onClick={() => handlePageSizeChange(PaginationEnum.P_100)}
                                    />{" "}
                                    <label
                                        htmlFor="item-per-page-option-04"
                                        className="w-full px-[14px] py-3 cursor-pointer flex gap-y-4 bg-[#F6F7F9] hover:bg-darkblue-10 items-center text-profinit-gray text-[14px] peer-checked:bg-white peer-checked:font-bold peer-focus:bg-darkblue-10 transition-colors duration-250 motion-reduce:transition-none"
                                    >
                                        {PaginationEnum.P_100}
                                        <span className="max-sm:hidden pl-1">items per page</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-none gap-2">
                        <button
                            onClick={goToPrevPage}
                            className="h-12 md:h-10 rounded-md flex items-center justify-center text-[16px] w-11 md:w-10 border text-darkblue-80 border-blue-40 hover:text-darkblue-100 hover:border-darkblue-100 transition-colors duration-250 motion-reduce:transition-none"
                        >
                            <svg className="size-3 duration-250 transition-all motion-reduce:transition-none rotate-90">
                                <ArrowIcon />
                            </svg>
                        </button>
                        {paginationObject.totalPages >= 2 && currentPage !== 1 && (
                            <button
                                onClick={() => goToPage(1)}
                                className="h-12 md:h-10 rounded-md flex items-center justify-center text-[16px] w-11 md:w-10 border text-darkblue-80 border-blue-40 hover:text-darkblue-100 hover:border-darkblue-100 transition-colors duration-250 motion-reduce:transition-none"
                            >
                                <span>1</span>
                            </button>
                        )}

                        {currentPage > 2 && paginationObject.totalPages > 3 && (
                            <div className="h-12 md:h-10 rounded-md flex items-center justify-center text-[16px] w-4 text-darkblue-80 transition-colors duration-250 motion-reduce:transition-none">
                                <span>...</span>
                            </div>
                        )}

                        <div className="h-12 md:h-10 rounded-md flex items-center justify-center text-[16px] w-11 md:w-10 border text-darkblue-100 border-darkblue-100 font-bold">
                            <span>{currentPage}</span>
                        </div>

                        {currentPage < paginationObject.totalPages - 1 &&
                            paginationObject.totalPages > 3 && (
                                <div className="h-12 md:h-10 rounded-md flex items-center justify-center text-[16px] w-4 text-darkblue-80 transition-colors duration-250 motion-reduce:transition-none">
                                    <span>...</span>
                                </div>
                            )}

                        {paginationObject.totalPages >= 2 &&
                            currentPage !== paginationObject.totalPages && (
                                <button
                                    onClick={() => goToPage(paginationObject.totalPages)}
                                    className="h-12 md:h-10 rounded-md flex items-center justify-center text-[16px] w-11 md:w-10 border text-darkblue-80 border-blue-40 hover:text-darkblue-100 hover:border-darkblue-100 transition-colors duration-250 motion-reduce:transition-none"
                                >
                                    <span>{paginationObject.totalPages}</span>
                                </button>
                            )}
                        <button
                            onClick={goToNextPage}
                            className="h-12 md:h-10 rounded-md flex items-center justify-center text-[16px] w-11 md:w-10 border text-darkblue-80 border-blue-40 hover:text-darkblue-100 hover:border-darkblue-100 transition-colors duration-250 motion-reduce:transition-none"
                        >
                            <svg className="size-3 duration-250 transition-all motion-reduce:transition-none -rotate-90">
                                <ArrowIcon />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const DateRangeDisplay = React.memo(
    function DateRange({ dateRange, isCustomDate }) {
        return (
            <div className="max-md:px-6 relative flex w-full items-center justify-between">
                <h2 className="text-[18px] font-normal text-darkblue-100">
                    <span className="">Billing &amp; usage</span>
                </h2>
                <p className="flex text-[16px] text-profinit-gray ml-4">
                    <svg className="size-[16px] text-blue-60 mr-1.5 mt-1">
                        <ArrowLinearIcon />
                    </svg>
                    {formatDateToDDMMYYYY(dateRange.date_from)} –{" "}
                    {formatDateToDDMMYYYY(dateRange.date_to)}
                </p>
            </div>
        );
    },
    (prevProps, nextProps) => {
        if (prevProps.isCustomDate && !nextProps.isCustomDate) {
            return false;
        }

        if (!nextProps.isCustomDate) {
            const dateFromChanged = prevProps.dateRange.date_from !== nextProps.dateRange.date_from;
            const dateToChanged = prevProps.dateRange.date_to !== nextProps.dateRange.date_to;

            if (dateFromChanged || dateToChanged) {
                return false;
            }
        }

        return true;
    },
);

const ListItem = ({ item }) => {
    const { timestamp, type, amount, tokens_difference, running_balance } = item;
    const formattedDate = formatTimeStampToDDMMYYY_HHMMSS(timestamp);

    return (
        <div className="relative flex max-md:flex-wrap max-md:justify-between md:items-center w-full max-md:mt-3 max-md:pt-3.5 after:absolute after:rounded-full after:left-0 after:top-0 after:h-px after:w-full after:bg-blue-20 before:hidden before:absolute before:rounded-full before:left-0 before:bottom-0 before:h-px before:w-full before:bg-blue-20 first-of-type:max-md:after:bg-blue-40 first-of-type:max-md:after:h-0.5 first-of-type:max-md:pt-4 last-of-type:before:block last-of-type:pb-3 md:mt-2 md:pt-2 md:flex-nowrap">
            <div className="max-md:w-full md:min-w-[166px] md:max-w-[166px] text-profinit-gray text-[14px] md:mr-4 md:ml-auto max-md:font-bold max-md:mb-1.5 md:order-2 md:text-left">
                {type}
            </div>
            <div className="max-md:w-2/3 md:min-w-[132px] md:max-w-[132px] text-profinit-gray text-[14px] whitespace-nowrap md:mr-4 max-md:mb-1.5 md:order-1 md:text-left">
                {formattedDate}
            </div>
            <div className="max-md:w-1/3 md:min-w-[76px] md:max-w-[76px] text-profinit-gray text-[14px] md:mr-4 md:ml-4 md:text-left md:order-4 text-right justify-end">
                <span className="md:hidden">{amount == null ? "" : amount + " USD"}</span>
                <span className="max-md:hidden">{amount ?? "-"}</span>
            </div>
            <div className="max-md:w-2/3 md:min-w-[126px] md:max-w-[126px] text-profinit-gray text-[14px] whitespace-nowrap md:ml-auto md:order-6 text-left md:text-right">
                <span className="md:hidden">Balance: </span>
                <span className="max-md:font-bold font-bold">
                    {formatTokens(running_balance)}
                </span>{" "}
                tokens
            </div>
            <div className="max-md:w-1/3 md:min-w-[52px] md:max-w-[52px] flex justify-end text-[14px] whitespace-nowrap md:mr-4 md:ml-auto max-md:mt-auto md:order-5 text-darkgreen-100 md:text-profinit-gray">
                <span className="md:hidden pr-1">{type == GraphTypeEnum.USAGE ? "-" : "+"}</span>{" "}
                {formatTokens(tokens_difference)}
            </div>
        </div>
    );
};

export default BillingAndUsageList;
