import Login from "frontend/pages/Authentication/components/Login";
import Register from "frontend/pages/Authentication/components/Register";
import { useEffect, useState } from "react";

import pattern from "assets/images/pattern-2-min.png";
import logo from "assets/images/logo-inverse.svg";

const Authentication = ({ isRegisterProp }) => {
    const [isRegister, setIsRegister] = useState(isRegisterProp);

    const switchToRegister = () => {
        setIsRegister(true);
    };

    const switchToLogin = () => {
        setIsRegister(false);
    };

    useEffect(() => {
        setIsRegister(isRegisterProp);
    }, [isRegisterProp]);

    return (
        <div
            className="relative flex min-h-dvh w-full justify-center bg-white md:items-center"
            key={isRegister}
        >
            <img
                src={pattern}
                alt=""
                aria-hidden="hidden"
                loading="eager"
                className="absolute inset-0 h-full w-full object-cover mix-blend-luminosity"
            />
            <div className="w-full overflow-hidden md:flex md:h-[558px] md:w-[810px] md:flex-wrap md:items-center md:rounded-lg md:shadow-login">
                <div className="flex h-[120px] w-full items-center justify-center overflow-hidden md:h-full md:max-w-[50%]">
                    <div className="flex size-full items-center bg-gradient-to-b from-profinit-blue to-darkblue-100 mt-15 md:mt-0">
                        <div className="relative z-10 flex w-full flex-wrap items-center justify-center">
                            <div className="flex w-full justify-center">
                                <img
                                    src={logo}
                                    alt="EvalMy.AI logo"
                                    width="152"
                                    height="32"
                                    loading="eager"
                                    className="block"
                                />
                            </div>
                            <p className="mt-10 hidden max-w-[220px] text-center text-[22px]/[26px] font-bold text-white md:block">
                                Automated testing tool for your own AI solution.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative w-full overflow-hidden bg-white h-[calc(100%-120px)] md:h-full md:max-w-[50%]">
                    <div className="flex">
                        <button
                            data-section="tab-item-sign-up-form"
                            className={`js-tab-nav-item tab-nav-item w-1/2 py-4 text-center text-[18px]/[22px] font-bold text-darkblue-100 focus:outline-none ${isRegister ? "active" : ""}`}
                            onClick={switchToRegister}
                        >
                            Sign up
                        </button>
                        <button
                            data-section="tab-item-login-form"
                            className={`js-tab-nav-item tab-nav-item w-1/2 py-4 text-center text-[18px]/[22px] font-bold text-darkblue-100 focus:outline-none ${!isRegister ? "active" : ""}`}
                            onClick={switchToLogin}
                        >
                            Login
                        </button>
                    </div>
                    <div className="p-4">{isRegister ? <Register /> : <Login />}</div>
                </div>
            </div>
        </div>
    );
};

export default Authentication;
