import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import useAuth from "frontend/hooks/Auth";
import axios from "axios";
import { REACT_APP_BACKEND_URL } from "frontend/config";

const authContext = createContext();
/**
 * Axios instance that contains authentication.
 */
const axiosInstance = axios.create({
    baseURL: `${REACT_APP_BACKEND_URL}`,
});

/**
 * Provides Authentication globally. Accessed via `AuthConsumer`.
 * @returns Authentication provider that can be used to provide authentication globally.
 */
const AuthProvider = ({ children }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isInterceptorReady, setIsInterceptorReady] = useState(false);

    useEffect(() => {
        const resInterceptor = response => {
            return response;
        };

        const errInterceptor = error => {
            if (error.response.status === 401) {
                auth.logout();
                navigate("/login");
            }
            return Promise.reject(error);
        };

        const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

        setIsInterceptorReady(true);

        return () => axiosInstance.interceptors.response.eject(interceptor);
    }, [navigate]);

    return (
        <CookiesProvider>
            <authContext.Provider value={auth}>
                {isInterceptorReady && children}
            </authContext.Provider>
        </CookiesProvider>
    );
};

/**
 * Function that accesses authentication.
 * @returns Returns authentication context.
 */
const AuthConsumer = () => {
    return useContext(authContext);
};

export { AuthProvider, AuthConsumer, axiosInstance };
