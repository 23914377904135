const expectedSimpleExample = "Today is a sunny monday.";
const actualSimpleExample = "It is not sunny today.";
const contextSimpleExample = "";

const expectedIntermediateExample =
    "In my basket there are five apples, three pears and seven plums.";
const actualIntermediateExample = "The content of my basket:\n- 3 pears\n- 5 apples\n- 6 oranges";
const contextIntermediateExample = "";

const expectedComplexExample = `In a recent expedition to the Amazon Rainforest, scientists have made an astonishing discovery: a previously unknown species of butterfly. \
This breakthrough finding sheds light on the incredible biodiversity of the Amazon and highlights the importance of preserving this unique ecosystem. \
The newly discovered butterfly boasts vibrant blue wings, making it a stunning addition to the world of entomology. \
Researchers are eager to study this newfound species further to understand its behavior, ecology, and evolutionary history. \
This discovery underscores the need for continued exploration and conservation efforts in the Amazon Rainforest.`;
const actualComplexExample = `In a recent expedition to the Amazon Rainforest, scientists have made an astonishing discovery: a previously unknown species of butterfly. \
This breakthrough finding sheds light on the incredible biodiversity of the Amazon and highlights the importance of preserving this unique ecosystem. \
The newly discovered butterfly boasts vibrant red wings, making it a stunning addition to the world of entomology. \
Researchers are eager to study this newfound species further to understand its evolutionary history. \
This discovery underscores the need for continued exploration and conservation efforts in the Amazon Rainforest.`;
const contextComplexExample = "Any different information about butterflies has a critical impact.";

const examplesResultObject = {
    contradictions: {
        simple: {
            statements: [
                {
                    reasoning:
                        "The statement from <TEXT 1> claims that today is sunny, while <TEXT 2> contradicts this by stating that it is not sunny. These two statements present a contradiction in the reported weather conditions for the same day. The contradiction is considered large because it is a significant factual discrepancy.",
                    severity: "large",
                    summary: "Difference in the weather description.",
                },
            ],
            scores: { score: 0.5 },
        },
        intermediate: {
            statements: [
                {
                    reasoning:
                        "According to <TEXT 1>, the basket contains five apples, three pears, and seven plums. However, <TEXT 2> lists the basket's contents as three pears, five apples, and six oranges. There is a direct contradiction regarding the presence of plums and oranges and their quantities. <TEXT 1> does not mention oranges at all, while <TEXT 2> does not mention plums but includes oranges instead. This is a large contradiction because it involves a significant difference in the contents of the basket, which changes the overall context or story of what is in the basket.",
                    severity: "large",
                    summary: "Different fruit types and quantities in the basket.",
                },
            ],
            scores: { score: 0.5 },
        },
        complex: {
            statements: [
                {
                    reasoning:
                        "The statement from <TEXT 1> describes the newly discovered butterfly as having vibrant blue wings, while <TEXT 2> describes the same butterfly as having vibrant red wings. This is a critical contradiction because the color of the wings is a fundamental characteristic of the butterfly and a significant detail in the discovery. The contradiction is critical because it implies a completely different species of butterfly, which has a substantial impact on the overall meaning of the texts.",
                    severity: "critical",
                    summary: "Difference in the color of the butterfly's wings.",
                },
            ],
            scores: { score: 0 },
        },
    },
    missing_facts: {
        simple: {
            statements: [
                {
                    reasoning:
                        "Both texts provide information about the weather, but only the first text mentions the day of the week. This is a small missing fact, as it does not significantly alter the primary understanding but contributes to a more complete picture.",
                    severity: "small",
                    summary: "Missing information about the day of the week",
                },
            ],
            scores: { score: 0.9 },
        },
        intermediate: {
            statements: [
                {
                    reasoning:
                        "In <TEXT 1>, it is mentioned that there are seven plums in the basket, which is missing in <TEXT 2>. This is a small missing fact as it does not significantly alter the primary understanding but contributes to a more complete picture.",
                    severity: "small",
                    summary: "Missing information about the number of plums in the basket",
                },
            ],
            scores: { score: 0.9 },
        },
        complex: {
            statements: [
                {
                    reasoning:
                        "The first text mentions that the newly discovered butterfly has vibrant blue wings, while the second text states that the butterfly has vibrant red wings. This is a fundamental difference in the description of the butterfly and has a critical impact, as it completely changes the understanding of the discovery.",
                    severity: "critical",
                    summary: "The color of the butterfly's wings",
                },
            ],
            scores: { score: 0 },
        },
    },
    f1: {
        simple: {
            scores: {
                f1: 0.8,
                correctness: 1,
                completeness: 0.6666666666666666,
            },
            statements: [
                {
                    reasoning:
                        "TEXT_1 states that the weather is sunny, while TEXT_2 directly contradicts this by stating it is not sunny. This is a critical piece of information because the weather condition (sunny or not) is a primary fact about the day.",
                    summary: "Weather condition (sunny or not)",
                    severity: "critical",
                    occurrence: "both",
                },
                {
                    reasoning:
                        "TEXT_1 specifies that today is Monday, which is not mentioned in TEXT_2. This is a large piece of information because it provides a specific detail about the day of the week, which is important but not as crucial as the weather condition.",
                    summary: "Today is Monday",
                    severity: "large",
                    occurrence: "1",
                },
            ],
        },
        intermediate: {
            scores: {
                f1: 0.8333333333333334,
                correctness: 0.8333333333333334,
                completeness: 0.8333333333333334,
            },
            statements: [
                {
                    reasoning: "Both texts mention that there are apples and pears in the basket.",
                    summary: "The basket contains apples and pears.",
                    severity: "large",
                    occurrence: "both",
                },
                {
                    reasoning:
                        "TEXT 1 states that there are seven plums in the basket, which is not mentioned in TEXT 2.",
                    summary: "There are seven plums in the basket.",
                    severity: "large",
                    occurrence: "1",
                },
                {
                    reasoning:
                        "TEXT 2 mentions six oranges in the basket, which is not mentioned in TEXT 1.",
                    summary: "There are six oranges in the basket.",
                    severity: "large",
                    occurrence: "2",
                },
                {
                    reasoning: "Both texts specify the number of apples as five.",
                    summary: "There are five apples in the basket.",
                    severity: "critical",
                    occurrence: "both",
                },
                {
                    reasoning: "Both texts specify the number of pears as three.",
                    summary: "There are three pears in the basket.",
                    severity: "critical",
                    occurrence: "both",
                },
            ],
        },
        complex: {
            scores: {
                f1: 0.6153846153846154,
                correctness: 0.6666666666666666,
                completeness: 0.5714285714285714,
            },
            statements: [
                {
                    reasoning:
                        "Both texts mention the discovery of a new butterfly species in the Amazon Rainforest, which is a significant event that contributes to the understanding of biodiversity and the need for conservation.",
                    summary: "Discovery of a new butterfly species in the Amazon Rainforest.",
                    severity: "critical",
                    occurrence: "both",
                },
                {
                    reasoning:
                        "Both texts emphasize the discovery's role in highlighting the biodiversity of the Amazon and the importance of preserving the ecosystem, which is a major point made to stress the significance of the finding.",
                    summary:
                        "The discovery highlights the biodiversity of the Amazon and the importance of preserving the ecosystem.",
                    severity: "large",
                    occurrence: "both",
                },
                {
                    reasoning:
                        "TEXT_1 states that the butterfly has vibrant blue wings, which is a critical piece of information given the context that any different information about butterflies has a critical impact.",
                    summary: "The new butterfly has vibrant blue wings.",
                    severity: "critical",
                    occurrence: "1",
                },
                {
                    reasoning:
                        "TEXT_2 states that the butterfly has vibrant red wings, which is a critical piece of information given the context that any different information about butterflies has a critical impact.",
                    summary: "The new butterfly has vibrant red wings.",
                    severity: "critical",
                    occurrence: "2",
                },
                {
                    reasoning:
                        "TEXT_1 mentions that researchers are eager to study the butterfly's behavior and ecology, which is a detail missing from TEXT_2. This information is considered large because it pertains to the scientific interest in the butterfly's characteristics beyond its evolutionary history.",
                    summary: "Researchers are eager to study the butterfly's behavior and ecology.",
                    severity: "large",
                    occurrence: "1",
                },
                {
                    reasoning:
                        "Both texts conclude with the statement that the discovery underscores the need for continued exploration and conservation in the Amazon, which is a significant point reinforcing the overall message of the importance of the Amazon Rainforest.",
                    summary:
                        "The discovery underscores the need for continued exploration and conservation in the Amazon.",
                    severity: "large",
                    occurrence: "both",
                },
            ],
        },
    },
};

// -----------------------
// SYMBOL - contradictions
// -----------------------
export const SIMPLE_CONTRADICTIONS_EXAMPLE = {
    expected: expectedSimpleExample,
    actual: actualSimpleExample,
    context: contextSimpleExample,
    statements: examplesResultObject.contradictions.simple.statements,
    score: examplesResultObject.contradictions.simple.score,
};

export const INTERMEDIATE_CONTRADICTIONS_EXAMPLE = {
    expected: expectedIntermediateExample,
    actual: actualIntermediateExample,
    context: contextIntermediateExample,
    statements: examplesResultObject.contradictions.intermediate.statements,
    score: examplesResultObject.contradictions.intermediate.score,
};

export const COMPLEX_CONTRADICTIONS_EXAMPLE = {
    expected: expectedComplexExample,
    actual: actualComplexExample,
    context: contextComplexExample,
    statements: examplesResultObject.contradictions.complex.statements,
    score: examplesResultObject.contradictions.complex.score,
};

// ----------------------
// SYMBOL - missing_facts
// ----------------------
export const SIMPLE_MISSING_FACTS_EXAMPLE = {
    expected: expectedSimpleExample,
    actual: actualSimpleExample,
    context: contextSimpleExample,
    statements: examplesResultObject.missing_facts.simple.statements,
    score: examplesResultObject.missing_facts.simple.score,
};

export const INTERMEDIATE_MISSING_FACTS_EXAMPLE = {
    expected: expectedIntermediateExample,
    actual: actualIntermediateExample,
    context: contextIntermediateExample,
    statements: examplesResultObject.missing_facts.intermediate.statements,
    score: examplesResultObject.missing_facts.intermediate.score,
};

export const COMPLEX_MISSING_FACTS_EXAMPLE = {
    expected: expectedComplexExample,
    actual: actualComplexExample,
    context: contextComplexExample,
    statements: examplesResultObject.missing_facts.complex.statements,
    score: examplesResultObject.missing_facts.complex.score,
};

const ExampleType = {
    SIMPLE: "simple",
    INTERMEDIATE: "intermediate",
    COMPLEX: "complex",
};

const exampleGroups = {
    simple: {
        expected: expectedSimpleExample,
        actual: actualSimpleExample,
        context: contextSimpleExample,
    },
    intermediate: {
        expected: expectedIntermediateExample,
        actual: actualIntermediateExample,
        context: contextIntermediateExample,
    },
    complex: {
        expected: expectedComplexExample,
        actual: actualComplexExample,
        context: contextComplexExample,
    },
};

/**
 *
 * @param {Object} input The object to compare against the example groups. Object consisting of {expected (string), actual (string), context (string)}
 * @returns {string|null} Returns an example type (e.g., ExampleType.SIMPLE) if a match is found, otherwise returns `null`.
 */
export function tryGetExampleType(input) {
    for (const [key, example] of Object.entries(exampleGroups)) {
        if (
            input.expected === example.expected &&
            input.actual === example.actual &&
            input.context === example.context
        ) {
            // Return the corresponding ExampleType
            switch (key) {
                case "simple":
                    return ExampleType.SIMPLE;
                case "intermediate":
                    return ExampleType.INTERMEDIATE;
                case "complex":
                    return ExampleType.COMPLEX;
                default:
                    return null;
            }
        }
    }

    // Return null if no match is found
    return null;
}

/**
 * Retrieves the cached result.
 * @param {string} symbol - Name of the supported symbol for evaluation.
 * @param {string} exampleType - Should be one of the predefined example types from `ExampleType` enum.
 * @returns
 */
export function getExampleResult(symbol, exampleType) {
    return examplesResultObject[symbol][exampleType];
}
