import { AuthConsumer } from "frontend/context/AuthContext";
import { useScroll } from "frontend/context/ScrollContext";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
    const { authed, logout } = AuthConsumer();
    const { scrollToId } = useScroll();
    const navigate = useNavigate();

    const handleRechargePack = () => {
        if (authed) {
            navigate("/profile/overview");
            scrollToId("profile-page");
        } else {
            navigate("login");
        }
    };

    return (
        <>
            <div
                className="grid scroll-m-22 gap-8 md:grid-cols-2 md:items-end md:gap-6 desktop-menu:scroll-m-3 lg:grid-cols-12"
                id="pricing"
            >
                <div className="md:col-span-2 lg:col-span-4 lg:mr-9 scroll-animate-delay">
                    <h3 className="text-4xl font-bold text-darkblue-100 md:text-5xl">Pricing</h3>
                    <p className="my-7 md:mt-10">
                        As an exclusive offer for early adopters, we&apos;re providing 10 million
                        free tokens to experience our advanced solution for automated testing of
                        LLM-based software applications.
                    </p>
                    <p className="mb-4 lg:mb-0">
                        This generous offer allows you to fully explore our cutting-edge technology
                        and revolutionize your testing processes.
                    </p>
                </div>
                {!authed && (
                    <div className="rounded-xl border-2 lg:col-span-4 border-blue-40 p-6 scroll-animate-delay">
                        <div className="mb-8 flex justify-between">
                            <div>
                                <h4 className="text-4xl text-darkblue-100 font-bold">
                                    Early Adopters
                                </h4>
                                <p className="mt-4 text-xl">10 million tokens</p>
                            </div>
                            <p className="text-xl">FREE</p>
                        </div>
                        <a
                            className="text-center w-full leading-none rounded-md font-bold inline-block text-white bg-darkblue-100 hover:bg-profinit-darkblue text-lg p-4"
                            href="/register"
                        >
                            Start with Early Adopters package
                        </a>
                    </div>
                )}
                <div className="rounded-xl border-2 lg:col-span-4 border-darkblue-10 p-6 scroll-animate-delay">
                    <div className="mb-8 flex justify-between">
                        <div>
                            <h4 className="text-4xl text-profinit-gray font-bold">Recharge pack</h4>
                            <p className="mt-4 text-xl">1 million tokens</p>
                        </div>
                        <p className="text-xl">
                            <span className="text-4xl font-bold text-profinit-gray">5</span>
                            USD
                        </p>
                    </div>
                    <a
                        className="text-center w-full leading-none rounded-md font-bold inline-block text-blue-80 bg-darkblue-10 hover:bg-darkblue-80 hover:text-white text-lg p-4 hover:cursor-pointer"
                        onClick={handleRechargePack}
                    >
                        Recharge 1 million tokens
                    </a>
                </div>
            </div>
        </>
    );
};

export default Pricing;
