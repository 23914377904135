/**
 * URL of the backend.
 */
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const STRIPE_API_PUBLIC_KEY = process.env.REACT_APP_STRIPE_API_PUBLIC_KEY;

const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export {
    REACT_APP_BACKEND_URL,
    STRIPE_API_PUBLIC_KEY,
    EMAILJS_SERVICE_ID,
    EMAILJS_TEMPLATE_ID,
    EMAILJS_PUBLIC_KEY,
    CAPTCHA_SITE_KEY,
};
