import React from "react";
import ReactDOM from "react-dom/client";
import App from "frontend/App";
import "assets/css/tailwind.css";

let root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </>,
);
