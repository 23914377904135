import layer from "assets/images/layer.svg";
import verify from "assets/images/verify.svg";
import scalability from "assets/images/scalability.svg";
import pluggability from "assets/images/pluggability.svg";
import { ReactComponent as Link } from "assets/icons/link.svg";

const Description = () => {
    return (
        <>
            <div className="mt-12 grid gap-12 md:mt-23 md:grid-cols-12 md:gap-fluid">
                <div className="md:col-span-3 scroll-animate-delay">
                    <img
                        src={verify}
                        alt=""
                        loading="lazy"
                        aria-hidden="hidden"
                        width="54px"
                        height="54px"
                    />
                    <h4 className="my-6 text-2xl font-bold">Accuracy</h4>
                    <div>
                        <p>
                            AI validation is tricky. Small details can flip meanings (e.g.,
                            &quot;legal&quot; vs. &quot;illegal&quot;).{" "}
                            <span className="text-profinit-darkblue font-bold">evalmy.ai</span>{" "}
                            prioritizes accuracy to address this challenge.
                        </p>
                    </div>
                </div>
                <div className="md:col-span-3 scroll-animate-delay">
                    <img
                        src={layer}
                        alt=""
                        loading="lazy"
                        aria-hidden="hidden"
                        width="54px"
                        height="54px"
                    />
                    <h4 className="my-6 text-2xl font-bold">Configurability</h4>
                    <div>
                        <p>
                            <span className="text-profinit-darkblue font-bold">evalmy.ai</span>{" "}
                            offers out-of-the-box validation and customizable Sem-Score parameters,
                            allowing testers to adjust context based on risk profiles.
                        </p>
                    </div>
                </div>
                <div className="md:col-span-3 scroll-animate-delay">
                    <img
                        src={scalability}
                        alt=""
                        loading="lazy"
                        aria-hidden="hidden"
                        width="54px"
                        height="54px"
                    />
                    <h4 className="my-6 text-2xl font-bold">Scalability</h4>
                    <div>
                        <p>
                            A cloud-based SaaS,{" "}
                            <span className="text-profinit-darkblue font-bold">evalmy.ai</span>{" "}
                            scales up or down as needed, depending on the number of models, test
                            frequency, and question set size.
                        </p>
                    </div>
                </div>
                <div className="md:col-span-3 scroll-animate-delay">
                    <img
                        src={pluggability}
                        alt=""
                        loading="lazy"
                        aria-hidden="hidden"
                        width="54px"
                        height="54px"
                    />
                    <h4 className="my-6 text-2xl font-bold">Pluggability</h4>
                    <div>
                        <p>
                            <span className="text-profinit-darkblue font-bold">evalmy.ai</span>{" "}
                            provides a user-friendly API that seamlessly integrates into CI/CD
                            pipelines and supports popular ML tools like LangChain.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Description;
