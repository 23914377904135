import { AuthConsumer } from "frontend/context/AuthContext";
import { Navigate } from "react-router-dom";

/**
 * Components inside of this are protected by authentication. Redirects to /login page if user is not authenticated.
 * @returns Component that forces user to authenticate.
 */
function RequireAuth({ children }) {
    const { authed } = AuthConsumer();

    return authed === true ? children : <Navigate to="/login" replace />;
}

export default RequireAuth;
