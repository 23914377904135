import { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as ShowMoreIcon } from "assets/icons/arrow-down-secondary.svg";
import { axiosInstance } from "frontend/context/AuthContext";
import Select from "react-dropdown-select";
import ErrorMessage from "frontend/components/ErrorMessage";
import AnimateSpin from "frontend/shared/AnimateSpin";

const formatDate = date => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

async function getCountries() {
    const response = axiosInstance.get(`/web/data/countries`, {
        headers: { "Content-Type": "application/json" },
    });

    try {
        const { data } = await response;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function getBillingInfo() {
    const response = axiosInstance.get(`/web/users/my/billing_info`, {
        headers: { "Content-Type": "application/json" },
    });

    try {
        const { data } = await response;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const BillingInfo = ({ full_name, isEditMode }) => {
    const newInvoicesBatchSize = 5;
    const [isEdit, setIsEdit] = useState(isEditMode);

    const [billingInfo, setBillingInfo] = useState({
        fullName: full_name,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [countries, setCountries] = useState(null);

    const [invoices, setInvoices] = useState([]);
    const [canLoadMoreInvoices, setCanLoadMoreInvoices] = useState(true);

    const fetchMoreInvoices = async () => {
        const newInvoices = await getInvoicesList();

        if (newInvoices?.data == null || newInvoices?.data.length == 0) {
            setCanLoadMoreInvoices(false);
        }

        setInvoices(prevInvoices => [...prevInvoices, ...newInvoices.data]);
    };

    async function getInvoicesList() {
        const response = axiosInstance.get(
            `/web/users/my/invoice/list/${invoices.length}/${newInvoicesBatchSize}`,
        );

        try {
            const { data } = await response;
            return data;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    useEffect(() => {
        getCountries().then(result => {
            if (result === null) {
                return;
            }

            const { data } = result;
            setCountries(data);
        });

        getBillingInfo().then(result => {
            if (result === null) {
                return;
            }

            const { company_name, vat_code, street, city, postal_code, country_index } = result;
            setBillingInfo({
                company: company_name,
                vatNumber: vat_code,
                street: street,
                city: city,
                postcode: postal_code,
                country: country_index,
            });
        });

        getInvoicesList().then(result => {
            if (result === null) {
                return;
            }

            const { data } = result;
            setInvoices(data);
        });
    }, [isEdit]);

    const handleChange = e => {
        const { name, value } = e.target;
        setBillingInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const toggleEdit = () => {
        setIsEdit(!isEdit);
        setErrors({});
    };

    const validateForm = () => {
        const newErrors = {};
        if (!billingInfo.city) newErrors.city = "City is required";
        if (!billingInfo.postcode) newErrors.postcode = "Postcode is required";
        if (!billingInfo.street) newErrors.street = "Street is required";
        if (!billingInfo.country) newErrors.country = "Country is required";
        return newErrors;
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        setIsLoading(true);

        const newBillingInfo = {
            company_name: billingInfo.company,
            vat_code: billingInfo.vatNumber,
            street: billingInfo.street,
            city: billingInfo.city,
            postal_code: billingInfo.postcode,
            country_index: billingInfo.country,
        };

        try {
            await axiosInstance.post(`/web/users/my/billing_info/set`, newBillingInfo, {
                headers: { "Content-Type": "application/json" },
            });
            setErrors({});
            toggleEdit();
        } catch (error) {
            if (error.response?.status === 400) {
                setErrors(error.response?.data?.detail?.messages);
            } else if (error.response?.status === 422) {
                setErrors(e => ({ ...e, country: "Invalid country" }));
            } else {
                console.error(error);
            }
        }
        setIsLoading(false);
    };
    return (
        <>
            <form className="flex w-full flex-wrap justify-start" onSubmit={handleSubmit}>
                {!isEdit && (
                    <>
                        <div className="max-md:sticky max-md:top-[60px] max-md:bg-white max-md:z-20 md:relative flex h-fit w-full items-center justify-start after:absolute after:rounded-full after:bottom-0 after:left-0 after:h-px after:w-full after:bg-blue-40 max-md:px-6 max-md:py-3 mb-5 pb-5 md:mb-8 md:min-h-[60px] md:pb-[28px]">
                            <h1 className="text-[20px] font-bold text-darkblue-100 md:text-[22px]">
                                Billing information
                            </h1>
                            <div className="ml-auto xl:hidden">
                                <label
                                    htmlFor="toggle3"
                                    className="flex cursor-pointer select-none items-center"
                                >
                                    <div className="relative flex items-center">
                                        <span className="max-w-[calc(100%-40px)] whitespace-nowrap text-[16px] font-normal text-gray-80 transition-colors duration-250 peer-checked:text-profinit-gray motion-reduce:transition-none">
                                            Show details
                                        </span>
                                        <div className="relative">
                                            <input
                                                type="checkbox"
                                                id="toggle3"
                                                className="peer sr-only"
                                                data-toggle="details-toggle"
                                                defaultChecked={true}
                                            />
                                            <div className="ml-4 block h-4 w-6 rounded-full border-2 border-solid border-blue-40 bg-white peer-checked:border-0 peer-checked:bg-gradient-to-b peer-checked:from-[#41C34B] peer-checked:to-darkgreen-100"></div>
                                            <div className="absolute left-5 top-1/2 size-2 -translate-y-1/2 rounded-full bg-blue-60 transition peer-checked:translate-x-full peer-checked:bg-white"></div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div
                            className="details-section w-full max-md:px-6 xl:w-1/3 xl:pr-8"
                            data-toggle-target="details-toggle"
                        >
                            <div className="flex items-center">
                                <h2 className="text-[18px] font-normal text-darkblue-100">
                                    Billing information
                                </h2>
                                <a
                                    href="#"
                                    className="relative ml-auto block cursor-pointer pr-6 text-[14px] text-gray-80 hover:underline"
                                    onClick={toggleEdit}
                                >
                                    Edit
                                    <svg className="absolute right-0 top-1/2 size-[14px] -translate-y-1/2 text-gray-80">
                                        <EditIcon />
                                    </svg>
                                </a>
                            </div>
                            <div className="mt-6 md:mt-5">
                                <h3 className="mb-1.5 text-[16px] font-normal text-gray-80">
                                    Full name
                                </h3>
                                <p className="mb-1.5 text-[16px] font-normal text-darkblue-100">
                                    {full_name}
                                </p>
                            </div>
                            <div className="mt-6 md:mt-5">
                                <h3 className="mb-1.5 text-[16px] font-normal text-gray-80">
                                    Billing address
                                </h3>
                                <p className="mb-1.5 text-[16px] font-normal text-darkblue-100">
                                    {billingInfo.street}
                                    <br />
                                    {billingInfo.city}
                                    <br />
                                    {billingInfo.postcode}
                                    <br />
                                    {countries ? countries[billingInfo?.country - 1]?.country : ""}
                                </p>
                            </div>
                            <div className="relative mt-7 pt-7 after:absolute after:rounded-full after:left-0 after:top-0 after:h-px after:w-full after:bg-blue-20 md:mt-8 md:pt-8">
                                <div>
                                    <h3 className="mb-1.5 text-[16px] font-normal text-gray-80">
                                        VAT number
                                    </h3>
                                    <p className="mb-1.5 text-[16px] font-normal text-darkblue-100">
                                        {billingInfo.vatNumber}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="relative w-full max-md:px-6 xl:w-2/3 xl:pl-8 xl:after:absolute xl:after:rounded-full xl:after:left-0 xl:after:top-0 xl:after:h-full xl:after:w-px xl:after:bg-blue-20">
                            <div
                                className="details-toggle-secondary active relative max-xl:after:absolute max-xl:after:rounded-full max-xl:after:left-0 max-xl:after:top-0 max-xl:after:h-px max-xl:after:w-full max-xl:after:bg-blue-20 max-md:mb-[122px]"
                                data-toggle-target="details-toggle-secondary"
                            >
                                <h2 className="text-[18px] font-normal text-darkblue-100">
                                    Recharge invoice history
                                </h2>
                                <div className="flex flex-wrap">
                                    <div className="mb-1 mt-7 w-full max-md:hidden">
                                        <div className="flex w-full items-center font-bold text-profinit-gray">
                                            <div className="w-[76px] whitespace-nowrap text-left text-[14px] font-bold text-darkblue-100">
                                                Date
                                            </div>
                                            <div className="ml-auto w-[116px] whitespace-nowrap text-left text-[14px] font-bold text-darkblue-100">
                                                Invoice number
                                            </div>
                                            <div className="ml-auto w-[70px] whitespace-nowrap text-right text-[14px] font-bold text-darkblue-100">
                                                Amount
                                            </div>
                                            <div className="ml-auto w-[126px] whitespace-nowrap text-right text-[14px] font-bold text-darkblue-100">
                                                Download files
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        {invoices.map(invoice => (
                                            <Invoice
                                                key={invoice.number}
                                                invoiceNumber={invoice.number}
                                                status={invoice.status}
                                                date={invoice.date}
                                                amount={invoice.amount}
                                                invoiceLink={invoice.invoiceLink}
                                                receiptLink={invoice.receiptLink}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div
                                    onClick={fetchMoreInvoices}
                                    className={`mt-6 ${canLoadMoreInvoices ? "flex" : "hidden"} w-full justify-center rounded-md border border-solid border-blue-40 transition-colors duration-250 hover:border-blue-60 motion-reduce:transition-none hover:cursor-pointer`}
                                >
                                    <span className="flex cursor-pointer items-center px-6 py-3 text-[14px] text-profinit-gray">
                                        <svg className="mr-3 size-[12px]">
                                            <ShowMoreIcon />
                                        </svg>
                                        Show older
                                    </span>
                                </div>
                                <div
                                    className={`mt-6 ${canLoadMoreInvoices && invoices?.length > 0 ? "hidden" : "flex"} w-full justify-center text-profinit-gray`}
                                >
                                    Nothing more to load.
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {isEdit && (
                    <>
                        <div className="max-md:sticky max-md:top-0 max-md:bg-white max-md:z-20 md:relative flex h-fit w-full items-center justify-start after:absolute after:rounded-full after:bottom-0 after:left-0 after:h-px after:w-full after:bg-blue-40 max-md:px-6 max-md:pt-5 mb-5 pb-5 md:mb-8 md:min-h-[60px] md:pb-[28px]">
                            <h1 className="text-[20px] font-bold text-darkblue-100 md:text-[22px]">
                                Billing information
                            </h1>
                            <div className="max-md:fixed max-md:bottom-0 max-md:left-0 max-md:z-10 max-md:w-full max-md:bg-gradient-to-r max-md:from-profinit-blue max-md:to-darkblue-100 max-md:p-6 md:ml-auto">
                                <div className="flex flex-wrap max-md:w-full max-md:justify-between gap-x-4 max-md:gap-y-6">
                                    <button
                                        type="submit"
                                        className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-[135px] max-md:w-[calc(100%/3*2-16px)] text-white bg-profinit-red hover:bg-profinit-darkred text-[16px] py-4 px-4 md:py-3"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <div className="w-full flex justify-center">
                                                <AnimateSpin />
                                            </div>
                                        ) : (
                                            <div className="py-[2px]">Save changes</div>
                                        )}
                                    </button>
                                    <button
                                        onClick={toggleEdit}
                                        className="text-center leading-none rounded-md font-bold inline-block transition-colors duration-250 motion-reduce:transition-none w-auto max-md:w-1/3 max-md:min-w-fit text-darkblue-100 bg-darkblue-10 hover:bg-darkblue-20 text-[16px] py-4 px-4 md:py-3"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="relative w-full max-md:px-6 lg:w-3/5 xl:w-2/3">
                            <div className="relative max-md:mb-[122px]">
                                <h2 className="text-[18px] font-normal text-darkblue-100">
                                    Billing information
                                </h2>
                                <div className="flex flex-wrap justify-between">
                                    <div className="w-full mt-5 md:w-[calc((100%-24px)/2)] md:max-w-[50%] md:even:ml-6">
                                        <label
                                            htmlFor="Full name"
                                            className="mb-2.5 block text-gray-80 font-normal text-[16px] cursor-not-allowed"
                                        >
                                            Full name
                                        </label>
                                        <input
                                            id="fullName"
                                            name="fullName"
                                            placeholder="Full name"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            defaultValue={billingInfo.fullName}
                                            readOnly
                                        />
                                    </div>
                                    <div className="w-full mt-5 md:w-[calc((100%-24px)/2)] md:max-w-[50%] md:even:ml-6">
                                        <label
                                            htmlFor="City"
                                            className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                        >
                                            City
                                        </label>
                                        <input
                                            id="city"
                                            name="city"
                                            placeholder="City"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            value={billingInfo.city}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.city && <ErrorMessage message={errors.city} />}
                                    </div>
                                    <div className="w-full mt-5 md:w-[calc((100%-24px)/2)] md:max-w-[50%] md:even:ml-6">
                                        <label
                                            htmlFor="Company (optional)"
                                            className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                        >
                                            Company (optional)
                                        </label>
                                        <input
                                            id="company"
                                            name="company"
                                            placeholder="Company"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            value={billingInfo.company}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="w-full mt-5 md:w-[calc((100%-24px)/2)] md:max-w-[50%] md:even:ml-6">
                                        <label
                                            htmlFor="Postcode"
                                            className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                        >
                                            Postcode
                                        </label>
                                        <input
                                            id="postcode"
                                            name="postcode"
                                            placeholder="Postcode"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            value={billingInfo.postcode}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.postcode && (
                                            <ErrorMessage message={errors.postcode} />
                                        )}
                                    </div>
                                    <div className="w-full mt-5 md:w-[calc((100%-24px)/2)] md:max-w-[50%] md:even:ml-6">
                                        <label
                                            htmlFor="Street"
                                            className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                        >
                                            Street
                                        </label>
                                        <input
                                            id="street"
                                            name="street"
                                            placeholder="Street"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            value={billingInfo.street}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.street && <ErrorMessage message={errors.street} />}
                                    </div>
                                    <div className="w-full mt-5 md:w-[calc((100%-24px)/2)] md:max-w-[50%] md:even:ml-6">
                                        <label
                                            htmlFor="Country"
                                            className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                        >
                                            Country
                                        </label>
                                        {countries == null && <Select />}
                                        {countries !== null && billingInfo?.country == null && (
                                            <Select
                                                className="read-only:form-input read-only:w-full read-only:rounded-md bg-white read-only:px-[14px] read-only:py-[7px] read-only:placeholder:text-darkblue-60 read-only:focus:ring-0 read-only:border-blue-40 read-only:hover:border-profinit-blue read-only:focus:border-darkblue-10"
                                                options={countries}
                                                onChange={values => {
                                                    setBillingInfo({
                                                        ...billingInfo,
                                                        country: values[0]["index"],
                                                    });
                                                }}
                                                labelField="country"
                                                valueField="country"
                                                required
                                            />
                                        )}
                                        {countries !== null && billingInfo?.country && (
                                            <Select
                                                className="read-only:form-input read-only:w-full read-only:rounded-md bg-white read-only:px-[14px] read-only:py-[7px] read-only:placeholder:text-darkblue-60 read-only:focus:ring-0 read-only:border-blue-40 read-only:hover:border-profinit-blue read-only:focus:border-darkblue-10"
                                                options={countries}
                                                onChange={values => {
                                                    setBillingInfo({
                                                        ...billingInfo,
                                                        country: values[0]?.index,
                                                    });
                                                }}
                                                labelField="country"
                                                valueField="country"
                                                values={[countries[billingInfo.country - 1]]}
                                                required
                                            />
                                        )}
                                        {errors.country && (
                                            <ErrorMessage message={errors.country} />
                                        )}
                                    </div>
                                </div>
                                <div className="relative mt-6 after:absolute after:rounded-full after:top-0 after:left-0 after:w-full after:h-px after:bg-blue-20 flex flex-wrap justify-between">
                                    <div className="w-full mt-5 md:w-[calc((100%-24px)/2)] md:max-w-[50%] md:even:ml-6">
                                        <label
                                            htmlFor="VAT number (optional)"
                                            className="mb-2.5 block text-gray-80 font-normal text-[16px]"
                                        >
                                            VAT number (optional)
                                        </label>
                                        <input
                                            id="vatNumber"
                                            name="vatNumber"
                                            placeholder="VAT number"
                                            className="form-input w-full rounded-md bg-white px-[14px] py-[11px] ring-0 border border-solid border-blue-40 placeholder:text-darkblue-60 hover:border-profinit-blue focus:ring-0 focus:border-profinit-blue read-only:bg-darkblue-10 read-only:border-darkblue-10 read-only:hover:border-darkblue-10 read-only:focus:border-darkblue-10 transition-colors duration-250 md:py-[7px] motion-reduce:transition-none"
                                            value={billingInfo.vatNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </form>
        </>
    );
};

const Invoice = ({ invoiceNumber, status, date, amount, currency, invoiceLink, receiptLink }) => {
    const getInvoice = async number => {
        const response = axiosInstance.get(`/web/users/my/invoice/${number}`, {
            headers: { "Content-Type": "application/pdf" },
            responseType: "blob",
        });
        try {
            const { data } = await response;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", String(number) + ".pdf");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="relative flex w-full flex-wrap before:absolute before:rounded-full before:bottom-0 before:left-0 before:hidden before:h-px before:w-full before:bg-blue-20 after:absolute after:rounded-full after:left-0 after:top-0 after:h-px after:w-full after:bg-blue-20 last-of-type:pb-3 last-of-type:before:block max-md:mt-4 max-md:justify-between max-md:pt-5 first-of-type:max-md:pt-6 first-of-type:max-md:after:h-0.5 first-of-type:max-md:after:bg-blue-40 md:mt-2 md:items-center md:pt-2">
            <div className="text-[14px] text-profinit-gray max-md:mb-1.5 max-md:w-1/2 max-md:font-bold md:order-2 md:ml-auto md:w-[116px] md:text-left">
                {invoiceNumber}
            </div>
            <div className="text-[14px] text-profinit-gray max-md:mb-1.5 max-md:w-full md:order-1 md:w-[76px] md:text-left">
                {date}
            </div>
            <div className="text-[14px] text-profinit-gray max-md:w-1/2 md:order-3 md:ml-auto md:w-[70px] md:text-right">
                <span className="md:hidden">Amount:</span>
                <span className="max-md:font-bold">{amount} USD</span>
            </div>
            <div className="flex justify-end text-[14px] max-md:mt-auto max-md:w-1/2 md:order-5 md:ml-auto md:w-[126px]">
                <a
                    onClick={() => getInvoice(invoiceNumber)}
                    href="#"
                    className="relative ml-4 block pl-4 text-darkblue-80 before:absolute before:left-0 before:top-1/2 before:h-[14px] before:w-px before:-translate-y-1/2 before:bg-blue-60 first-of-type:ml-0 first-of-type:pl-0 first-of-type:before:hidden hover:underline md:ml-3 md:pl-3"
                >
                    Invoice
                </a>
            </div>
        </div>
    );
};

export default BillingInfo;
