import { REACT_APP_BACKEND_URL } from "frontend/config";
import AnimateSpin from "frontend/shared/AnimateSpin";
import { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

const RESULT_FONT_SIZE = 16;

function sortResultStatementsBySeverity(result) {
    if (!result || !Array.isArray(result.statements)) {
        return result;
    }

    const severityOrder = ["critical", "large", "small", "negligible"];

    result.statements.sort((a, b) => {
        const indexA = severityOrder.indexOf(a.severity.toLowerCase());
        const indexB = severityOrder.indexOf(b.severity.toLowerCase());

        return indexA - indexB;
    });

    return result;
}

function roundToTwoDecimals(score) {
    let num = parseFloat(score);
    if (isNaN(num)) {
        return score;
    }
    return num.toFixed(2);
}

const ScoreBlock = ({ widthClass, title, score, statements }) => {
    const addOccurrenceDescription = occurrence => {
        if (occurrence == 1) {
            return " (Expected answer)";
        } else if (occurrence == 2) {
            return " (GPT answer)";
        } else {
            return "";
        }
    };

    return (
        <div className={`${widthClass} p-4`}>
            <div className="border border-orange-10 rounded-lg shadow-sm bg-white p-4">
                <h2 className="text-xl font-bold mb-4 text-profinit-darkblue">
                    {title}: {roundToTwoDecimals(score)}
                </h2>
                {statements.map((statement, index) => (
                    <div
                        key={index}
                        className={`${index !== statements.length - 1 ? "border-b border-orange-10" : ""} py-2`}
                    >
                        <div className="mb-1">
                            <span className="font-bold text-profinit-darkblue mr-2">Severity:</span>
                            {statement.severity}
                        </div>
                        <div className="mb-1">
                            <span className="font-bold text-profinit-darkblue mr-2">Summary:</span>
                            {statement.summary}
                        </div>
                        <div className="mb-1">
                            <span className="font-bold text-profinit-darkblue mr-2">
                                Reasoning:
                            </span>
                            {statement.reasoning}
                        </div>
                        {statement?.occurrence && (
                            <div className="mt-1">
                                <span className="font-bold text-profinit-darkblue mr-2">
                                    Occurrence:
                                </span>
                                {statement.occurrence}{" "}
                                {addOccurrenceDescription(statement.occurrence)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

/**
 * Component that renders result summary
 * @param {object} result Object {statements: [ {reasoning: string, severity: string, summary: string} ], score: int}
 */
const SummaryWindow = ({ result }) => {
    if (result?.scores) {
        if ("f1" in result.scores) {
            const f1Statements = result.statements.filter(
                statement => statement.occurrence === "both",
            );
            const completenessStatements = result.statements.filter(
                statement => statement.occurrence === "1",
            );
            const correctnessStatements = result.statements.filter(
                statement => statement.occurrence === "2",
            );
            return (
                <div className="flex flex-wrap">
                    <ScoreBlock
                        widthClass="w-full"
                        title="F1"
                        score={result.scores.f1}
                        statements={f1Statements}
                    />

                    <div className="flex w-full mt-4 flex-wrap md:flex-nowrap">
                        <ScoreBlock
                            widthClass="w-full md:w-1/2"
                            title="Completeness"
                            score={result.scores.completeness}
                            statements={completenessStatements}
                        />

                        <ScoreBlock
                            widthClass="w-full md:w-1/2"
                            title="Correctness"
                            score={result.scores.correctness}
                            statements={correctnessStatements}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <ScoreBlock
                        widthClass="w-full"
                        title="Score"
                        score={result.scores.score}
                        statements={result.statements}
                    />
                </>
            );
        }
    }
};

/**
 * Component that renders python call
 * @param {string} expected Expected input prompt
 * @param {string} actual GPT input prompt
 * @param {string} context Context given
 * @param {string} symbol Symbol used for evaluation
 */
const PythonCallWindow = ({ expected, actual, context, symbol }) => {
    const pythonCall = `# First, install python package
# python -m pip install evalmyai

from evalmyai import Evaluator

data = {
    "expected": "${expected.replaceAll(/\n/g, "\\n")}",
    "actual": "${actual.replaceAll(/\n/g, "\\n")}",
    "context": "${context.replaceAll(/\n/g, "\\n")}"
}

api_token = "YOUR_EVALMYAI_TOKEN"

# The service runs on your own instance of GPT, either in Azure or directly on an OpenAI endpoint you provide.
auth_azure = {
    "api_key": "YOUR_AZURE_API_KEY",
    "azure_endpoint": "https://...azure.com/",
    "api_version": "2023-07-01-preview",
    "azure_deployment": "...", # e.g. gpt-35
}

# Alternative direct open-ai endpoint
auth_open_ai = {
    "api_key": "YOUR_OPEN_AI_API_KEY",
    "model": "..." # e.g. gpt-4o
}

# Create evaluator
evaluator = Evaluator(YOUR_AUTH, api_token)

result = evaluator.evaluate(data)

print(result['${symbol}'])`;
    return (
        <div className="m-4 pb-5">
            <SyntaxHighlighter
                customStyle={{ fontSize: RESULT_FONT_SIZE, borderRadius: "15px" }}
                language="python"
                showLineNumbers={true}
                showInlineLineNumbers={true}
            >
                {pythonCall}
            </SyntaxHighlighter>
        </div>
    );
};

/**
 * Component that renders JSON in result
 * @param {object} jsonIn Object {expected: string, actual: string, context: string}
 */
const JsonInWindow = ({ jsonIn }) => (
    <div className="m-4 pb-5">
        <SyntaxHighlighter
            customStyle={{ fontSize: RESULT_FONT_SIZE, borderRadius: "15px" }}
            language="json"
            showLineNumbers={true}
            showInlineLineNumbers={true}
        >
            {`{
    "input_data": {
        "expected": "${jsonIn.expected.replaceAll(/\n/g, "\\n")}",
        "actual": "${jsonIn.actual.replaceAll(/\n/g, "\\n")}",
        "context": "${jsonIn.context.replaceAll(/\n/g, "\\n")}"
    },
    "scoring": {
        "name": "linear",
        "params": { 
            "weights": { 
                "critical": 1, 
                "large": 0.5, 
                "small": 0.1, 
                "negligible": 0 
            }, 
        },
    },
    "aggregation": {
        "n_calls": 1,
        "agg_method": "mean",
    },
    "auth": {
        YOUR_AUTH: "Same as in python call (Azure or OpenAI)",
    },
    "api_token": "YOUR_EVALMYAI_API_TOKEN",
}            `}
        </SyntaxHighlighter>
    </div>
);

/**
 * Component that renders JSON out result
 *@param {object} result Object {statements: [ {reasoning: string, severity: string, summary: string} ], score: int}
 */
const JsonOutWindow = ({ result }) => (
    <>
        {result && (
            <div className="m-4 pb-5">
                <SyntaxHighlighter
                    customStyle={{ fontSize: RESULT_FONT_SIZE, borderRadius: "15px" }}
                    language="json"
                    showLineNumbers={true}
                    showInlineLineNumbers={true}
                >
                    {JSON.stringify(result, undefined, 4)}
                </SyntaxHighlighter>
            </div>
        )}
    </>
);

/**
 * Component that renders button for custom result
 */
const ResultButton = ({ isActive, onClick, label }) => {
    return (
        <div className="flex-1 text-center p-4 md:p-5">
            <button
                className={`bg-profinit-darkblue py-2 px-4 w-full rounded shadow font-semibold ${
                    isActive
                        ? "ring-profinit-darkblue ring-2 ring-inset bg-white text-profinit-darkblue"
                        : "text-white"
                }`}
                onClick={onClick}
            >
                {label}
            </button>
        </div>
    );
};

/**
 * Component that is responsible to render and switch between result views
 * @param {object} result Object {statements: [ {reasoning: string, severity: string, summary: string} ], score: int}
 * @param {boolean} showResult State variable, if result can be shown
 * @param {object} jsonIn Object {expected: string, actual: string, context: string}
 * @param {boolean} isLoading State variable to indicate loading animation
 */
const Result = ({ result, showResult, jsonIn, isLoading }) => {
    const ResultWindows = {
        SUMMARY: "summary",
        PYTHON_CALL: "pythonCall",
        JSON_IN: "jsonIn",
        JSON_OUT: "jsonOut",
    };

    const replacePlaceholders = res => {
        if (res?.statements) {
            res.statements = res.statements.map(statement => ({
                ...statement,
                reasoning: statement.reasoning
                    .replaceAll("<TEXT 1>", "<Expected answer>")
                    .replaceAll("<TEXT 2>", "<GPT answer>"),
                summary: statement.summary
                    .replaceAll("<TEXT 1>", "<Expected answer>")
                    .replaceAll("<TEXT 2>", "<GPT answer>"),
            }));
        }
        return res;
    };

    result = sortResultStatementsBySeverity(result);

    const [selectedWindow, setSelectedWindow] = useState(ResultWindows.SUMMARY);
    const renderWindowContent = () => {
        switch (selectedWindow) {
            case ResultWindows.SUMMARY:
                return <SummaryWindow result={replacePlaceholders(result)} />;
            case ResultWindows.PYTHON_CALL:
                return (
                    <PythonCallWindow
                        expected={jsonIn.expected}
                        actual={jsonIn.actual}
                        context={jsonIn.context}
                        symbol={jsonIn.symbol}
                    />
                );
            case ResultWindows.JSON_IN:
                return <JsonInWindow jsonIn={jsonIn} />;
            case ResultWindows.JSON_OUT:
                return <JsonOutWindow result={result} />;
        }
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row md:justify-center">
                <ResultButton
                    isActive={showResult && selectedWindow === ResultWindows.SUMMARY}
                    onClick={() => setSelectedWindow(ResultWindows.SUMMARY)}
                    label="Result summary"
                />
                <ResultButton
                    isActive={showResult && selectedWindow === ResultWindows.PYTHON_CALL}
                    onClick={() => setSelectedWindow(ResultWindows.PYTHON_CALL)}
                    label="Python call"
                />
                <ResultButton
                    isActive={showResult && selectedWindow === ResultWindows.JSON_IN}
                    onClick={() => setSelectedWindow(ResultWindows.JSON_IN)}
                    label="JSON in"
                />
                <ResultButton
                    isActive={showResult && selectedWindow === ResultWindows.JSON_OUT}
                    onClick={() => setSelectedWindow(ResultWindows.JSON_OUT)}
                    label="JSON out"
                />
            </div>
            {showResult && renderWindowContent()}
            {isLoading && (
                <div className="w-full flex justify-center pb-3">
                    <AnimateSpin />
                </div>
            )}
        </>
    );
};

export default Result;
