import Footer from "frontend/pages/Home/components/Footer";

const CookiesManagement = () => {
    return (
        <>
            <div className="p-6 bg-gray-50 max-md:pt-18">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-5xl font-bold mb-4 text-center">Cookie policy</h1>
                    <h2 className="text-xl font-semibold mb-2">Introduction</h2>
                    <p className="mb-4">
                        Our website,{" "}
                        <a href="/" className="underline hover:no-underline">
                            https://evalmy.ai
                        </a>{" "}
                        (hereinafter: “the website”) uses cookies and other related technologies
                        (for convenience all technologies are referred to as “cookies”). Cookies are
                        also placed by third parties we have engaged. In the document below we
                        inform you about the use of cookies on our website.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">What are...?</h2>
                    <h3 className="text-lg font-semibold mb-2">Cookies</h3>
                    <p className="mb-2">
                        A cookie is a small simple file that is sent along with pages of this
                        website and stored by your browser on the hard drive of your computer or
                        another device. The information stored therein may be returned to our
                        servers or to the servers of the relevant third parties during a subsequent
                        visit.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">Scripts</h3>
                    <p className="mb-2">
                        A script is a piece of program code that is used to make our website
                        function properly and interactively. This code is executed on our server or
                        on your device.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">Web beacon</h3>
                    <p className="mb-2">
                        A web beacon (or a pixel tag) is a small, invisible piece of text or image
                        on a website that is used to monitor traffic on a website. In order to do
                        this, various data about you is stored using web beacons.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Cookies</h2>
                    <h3 className="text-lg font-semibold mb-2">Types of cookies</h3>
                    <h4 className="text-base font-semibold mb-2">Necessary cookies</h4>
                    <p className="mb-2">
                        These cookies are essential for the proper functioning of our website.
                        Without these cookies, the website would not work properly or speak your
                        language.
                    </p>
                    <h4 className="text-base font-semibold mb-2">Statistics cookies</h4>
                    <p className="mb-2">
                        Statistics cookies enable us to optimize the website experience for our
                        users. With these statistics cookies, we get insights into the usage of our
                        website.
                    </p>
                    <h4 className="text-base font-semibold mb-2">Marketing cookies</h4>
                    <p className="mb-4">
                        Marketing cookies are cookies or any other form of local storage, used to
                        create user profiles to display advertising or to track the user on this
                        website or across several websites for similar marketing purposes.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">Placed cookies</h3>
                    <ul className="policy-list mb-4">
                        <li>
                            <span className="font-bold">Cookie Consent</span> - Necessary cookies
                        </li>
                        <li>
                            <span className="font-bold">Google Analytics</span> - Statistics cookies
                        </li>
                        <li>
                            <span className="font-bold">Google Ads</span> - Marketing cookies
                        </li>
                    </ul>
                    <h3 className="text-lg font-semibold mb-2">
                        Enabling/disabling and deleting cookies
                    </h3>
                    <p className="mb-2">
                        You can use your internet browser to automatically or manually delete
                        cookies. You can also specify that certain cookies may not be placed.
                        Another option is to change the settings of your internet browser so that
                        you receive a message each time a cookie is placed. For more information
                        about these options, please refer to the instructions in the Help section of
                        your browser.
                    </p>
                    <p className="mb-4">
                        Please note that our website may not work properly if all cookies are
                        disabled. If you do delete the cookies in your browser, they will be placed
                        again after your consent when you visit our websites again.
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Consent</h2>
                    <p className="mb-2">
                        When you visit our website for the first time, we will show you a pop-up
                        with an explanation about cookies. As soon as you click on “Accept all” or
                        “Save preferences”, you consent to us using the categories of cookies and
                        plug-ins you selected in the pop-up, as described in this Cookie Policy.
                        Please note that, upon acceptance, the cookies will expire after a period of
                        182 days.
                    </p>
                    <p className="mb-2">
                        You can disable the use of cookies via your browser, but please note that
                        our website may no longer work properly.
                    </p>
                    <p className="mb-4">
                        <button
                            type="button"
                            data-cc="show-preferencesModal"
                            className="underline hover:no-underline"
                        >
                            Manage preferences
                        </button>
                    </p>
                    <h2 className="text-xl font-semibold mb-2">Contact details</h2>
                    <p className="mb-2">
                        For questions or comments about our Cookie Policy and this statement, please
                        contact us by using the following contact details:
                    </p>
                    <ul>
                        <li>Profinit EU, s.r.o.</li>
                        <li>Tychonova 2, 160 00 Prague 6</li>
                        <li>Czech Republic</li>
                        <li>
                            Website: <a href="/">https://evalmy.ai</a>
                        </li>
                        <li>Email: info@profinit.eu</li>
                        <li>Phone number: +420 224 316 016</li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CookiesManagement;
