import { ResponsiveBar } from "@nivo/bar";
import { formatTokens } from "frontend/shared/TokenFormatHelper";

const Graph = ({ graphData, color }) => {
    const ShadowLayer = ({ bars }) =>
        bars.map(({ x, y, width, height }) => (
            <rect
                key={`shadow-${x}`}
                x={x - 4}
                y={y + 3}
                width={width}
                height={height}
                fill="rgba(0, 0, 0, 0.2)"
                rx="5"
                ry="5"
            />
        ));

    const calculateDateSkip = dataLength => {
        const screenWidth = window.innerWidth;
        let maxTicks;

        if (screenWidth >= 1440) {
            maxTicks = 40;
        } else if (screenWidth >= 768) {
            maxTicks = 30;
        } else if (screenWidth > 425) {
            maxTicks = 20;
        } else {
            maxTicks = 10;
        }

        const modulo = Math.round(dataLength / maxTicks);

        return modulo < 1 ? 1 : modulo;
    };

    const getTickValues = graphData => {
        const skipDates = calculateDateSkip(graphData.length);
        return graphData.filter((_, index) => index % skipDates === 0).map(d => d.date);
    };

    return (
        <div className="h-80 w-full mb-10 pb-5">
            {graphData && (
                <ResponsiveBar
                    data={graphData}
                    keys={["token_count"]}
                    indexBy="date"
                    margin={{ top: 20, right: 50, bottom: 70, left: 70 }}
                    padding={0.4}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={color}
                    enableGridX={false}
                    enableGridY={true}
                    borderRadius={3}
                    borderWidth={1}
                    borderColor={{
                        from: "color",
                        modifiers: [["darker", 1.2]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 2,
                        tickRotation: -45,
                        legend: "Date",
                        legendPosition: "middle",
                        legendOffset: 60,
                        tickValues: getTickValues(graphData),
                    }}
                    axisLeft={{
                        tickSize: 2,
                        format: x => formatTokens(x),
                        legend: "Tokens",
                        legendPosition: "middle",
                        legendOffset: -55,
                    }}
                    enableLabel={false}
                    tooltip={({ indexValue, value, color }) => (
                        <div
                            style={{
                                padding: "8px 12px",
                                color: "#ffffff",
                                background: color,
                                boxShadow: "5px -6px 10px rgba(0, 0, 0, 0.15)",
                                borderRadius: "5px",
                            }}
                        >
                            {indexValue} - {value}
                        </div>
                    )}
                    labelTextColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                    }}
                    role="application"
                    layers={[ShadowLayer, "grid", "axes", "bars", "markers", "legends"]}
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fill: "#555",
                                    fontSize: 12,
                                    fontWeight: 500,
                                },
                            },
                        },
                        tooltip: {
                            container: {
                                background: "#333",
                                color: "#fff",
                                fontSize: 12,
                                borderRadius: "4px",
                                boxShadow: "0 3px 9px rgba(0, 0, 0, 0.25)",
                            },
                        },
                        grid: {
                            line: {
                                stroke: "#e0e0e0",
                                strokeWidth: 1,
                            },
                        },
                    }}
                />
            )}
        </div>
    );
};

export default Graph;
