import pattern from "assets/images/pattern-min.png";
import { useState } from "react";
import { send } from "@emailjs/browser";
import { EMAILJS_PUBLIC_KEY, EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID } from "frontend/config";
import ErrorMessage from "frontend/components/ErrorMessage";
import Footer from "frontend/pages/Home/components/Footer";

const ContactForm = () => {
    const [contactForm, setContactForm] = useState({
        name: "",
        surname: "",
        email: "",
        message: "",
        terms: false,
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!contactForm.name) newErrors.name = "Name is required";
        if (!contactForm.surname) newErrors.surname = "Surname is required";
        if (!contactForm.email) newErrors.email = "Email is required";
        if (!contactForm.message) newErrors.message = "Message is required";
        if (!contactForm.terms) newErrors.terms = "You must agree";
        return newErrors;
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        setContactForm({
            ...contactForm,
            [name]: value,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }
        setIsSubmitting(true);

        const templateParams = {
            ...contactForm,
            environment: process.env.NODE_ENV,
        };
        try {
            await send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, {
                publicKey: EMAILJS_PUBLIC_KEY,
            }).then(() => {
                setContactForm({
                    name: "",
                    surname: "",
                    email: "",
                    message: "",
                    terms: false,
                });
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <>
            <footer className="relative mt-14 text-xl text-white md:mt-18 lg:mt-22">
                <img
                    src={pattern}
                    alt=""
                    aria-hidden="hidden"
                    loading="lazy"
                    className="absolute inset-0 h-full w-full object-cover opacity-5 mix-blend-luminosity"
                />
                <div className="bg-darkblue-100">
                    <div className="container relative z-10">
                        <div className="py-12 md:grid md:grid-cols-12 md:gap-6 md:py-18 lg:pb-[6.625rem] lg:pt-22">
                            <div className="md:col-span-12 md:-mb-[4.13%] lg:col-span-6">
                                <h2 className="mb-10 mt-0 text-[2rem] font-bold">
                                    <span className="text-azure-100 pr-2">Interested?</span>
                                    Let&apos;s talk!
                                </h2>
                                <p className="text-3xl">
                                    We will reach out to you if additional information is needed to
                                    get started with EvalMy.AI tool.
                                </p>
                            </div>
                            <form
                                className="mt-14 grid gap-8 md:col-span-12 md:grid-cols-subgrid md:gap-x-6 lg:col-start-1 lg:mt-18"
                                onSubmit={handleSubmit}
                            >
                                <div className="text-lg md:col-span-6 lg:col-span-4">
                                    <label htmlFor="Name" className="mb-4 block">
                                        Name<span className="font-bold text-profinit-red">*</span>
                                    </label>
                                    <input
                                        id="name"
                                        name="name"
                                        placeholder="John"
                                        className="form-input w-full rounded-lg bg-transparent px-4 py-3 ring-1 ring-blue-40 placeholder:text-blue-40 hover:ring-2 hover:ring-white focus:ring-2 focus:ring-white"
                                        autoComplete="given-name"
                                        required
                                        onChange={handleInputChange}
                                        value={contactForm.name}
                                    />
                                    {errors.name && <ErrorMessage message={errors.name} />}
                                </div>
                                <div className="text-lg md:col-span-6 lg:col-span-4">
                                    <label htmlFor="Surname" className="mb-4 block">
                                        Surname
                                        <span className="font-bold text-profinit-red">*</span>
                                    </label>
                                    <input
                                        id="surname"
                                        name="surname"
                                        placeholder="Doe"
                                        className="form-input w-full rounded-lg bg-transparent px-4 py-3 ring-1 ring-blue-40 placeholder:text-blue-40 hover:ring-2 hover:ring-white focus:ring-2 focus:ring-white"
                                        autoComplete="family-name"
                                        required
                                        onChange={handleInputChange}
                                        value={contactForm.surname}
                                    />
                                    {errors.surname && <ErrorMessage message={errors.surname} />}
                                </div>
                                <div className="text-lg md:col-span-6 lg:col-span-4">
                                    <label htmlFor="Email" className="mb-4 block">
                                        Email<span className="font-bold text-profinit-red">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="john@doe.com"
                                        className="form-input w-full rounded-lg bg-transparent px-4 py-3 ring-1 ring-blue-40 placeholder:text-blue-40 hover:ring-2 hover:ring-white focus:ring-2 focus:ring-white"
                                        autoComplete="email"
                                        required
                                        onChange={handleInputChange}
                                        value={contactForm.email}
                                    />
                                    {errors.email && <ErrorMessage message={errors.email} />}
                                </div>
                                <div className="text-lg md:col-span-6 lg:col-span-12 flex-col">
                                    <label htmlFor="Message" className="mb-4 block">
                                        Message
                                        <span className="font-bold text-profinit-red">*</span>
                                    </label>
                                    <input
                                        id="message"
                                        name="message"
                                        placeholder="Message"
                                        className="form-input w-full rounded-lg bg-transparent px-4 py-3 ring-1 ring-blue-40 placeholder:text-blue-40 hover:ring-2 hover:ring-white focus:ring-2 focus:ring-white"
                                        autoComplete="organization"
                                        required
                                        onChange={handleInputChange}
                                        value={contactForm.message}
                                    />
                                    {errors.message && <ErrorMessage message={errors.message} />}
                                </div>
                                <div className="flex md:col-span-12 flex-col">
                                    <div className="flex items-start text-lg md:col-span-12">
                                        <div className="flex h-6 items-center">
                                            <input
                                                id="terms"
                                                name="terms"
                                                type="checkbox"
                                                required
                                                className="form-checkbox h-4.5 w-4.5 rounded border-blue-60 bg-transparent checked:border-white hover:ring-1 hover:ring-white focus:ring-1"
                                                onChange={handleInputChange}
                                                checked={contactForm.terms}
                                            />
                                            <label
                                                htmlFor="terms"
                                                className="text-gray-900 ml-6 font-medium"
                                            >
                                                I agree to with processing of my personal
                                                information
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        {errors.terms && <ErrorMessage message={errors.terms} />}
                                    </div>
                                </div>
                                <hr className="my-4 border-blue-80 md:hidden" />
                                <div className="md:col-span-12">
                                    <button
                                        type="submit"
                                        className="text-center w-full leading-none rounded-md font-bold inline-block md:w-auto text-white bg-profinit-red hover:bg-profinit-darkred text-lg py-4 px-5"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Contact me"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>
            <Footer />
        </>
    );
};

export default ContactForm;
